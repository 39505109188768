import Carriers from "../../views/Carriers/Index.vue";

const CarriersRoute = [
  {
    path: "/carriers",
    name: "Carriers",
    component: Carriers,
  },
];

export default CarriersRoute;
