<template v-if="this.pickupAndDelivery.accessorials.length > 0">
  <div :state="sendDelivery" class="delivery">
    <div class="delivery__card">
      <h6 style="color: #e53935">
        <b><i class="pi pi-map-marker"></i> Delivery</b>
      </h6>
      <hr />
      <div class="delivery__cities">
        <b-form-input
          v-model="pickupAndDelivery.zip_to"
          placeholder="Zip to"
          size="sm"
          list="zip_to"
          class="delivery__cities--input"
          autocomplete="off"
        ></b-form-input>
        <div class="delivery__city-state">
          <b-form-input
            v-model="pickupAndDelivery.city_to"
            placeholder="City to"
            size="sm"
            list="city_to"
            class="delivery__city"
            autocomplete="off"
          ></b-form-input>
          <b-form-input
            v-model="pickupAndDelivery.state_to"
            placeholder="State to"
            size="sm"
            list="state_to"
            class="delivery__state"
            autocomplete="off"
          ></b-form-input>
        </div>
      </div>
      <hr />
      <div class="delivery__checkbox-container">
        <b-form-checkbox
          v-for="(accessorial, index) in accessorialsDelivery"
          :key="accessorial.id"
          v-model="acceptedAccessorials[index]"
          value="accepted"
          @input="selectAccessorial(accessorial, index)"
          unchecked-value="not_accepted"
          size="sm"
          class="delivery__checkbox"
        >
          {{ accessorial.accessorial_name }}
        </b-form-checkbox>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Delivery",
  props: {
    sendAccessorialsDelivery: Array,
  },
  data() {
    return {
      pickupAndDelivery: {
        zip_to: "",
        city_to: "",
        state_to: "",
        accessorials: [],
      },
      accessorialsDelivery: [],
      acceptedAccessorials: [],
    };
  },
  created() {
    this.accessorialsDelivery = this.sendAccessorialsDelivery;
  },
  computed: {
    sendDelivery() {
      return this.$emit("input", this.pickupAndDelivery);
    },
  },
  methods: {
    selectAccessorial(accessorial, index) {
      if (this.acceptedAccessorials[index] === "accepted") {
        this.pickupAndDelivery.accessorials.push(accessorial);
      }
      for (let i = 0; i < this.pickupAndDelivery.accessorials.length; i += 1) {
        if (
          this.pickupAndDelivery.accessorials[i].accessorial_name === accessorial.accessorial_name
          && this.acceptedAccessorials[index] === "not_accepted"
        ) {
          this.pickupAndDelivery.accessorials.splice(i, 1);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.delivery {
  @include card;
  &__city-state {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }
  &__city,
  &__state {
    width: 120px;
    margin: 6px;
  }
  &__checkbox-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  &__checkbox {
    padding: 0 1rem;
  }
}
hr {
  margin: 8px 0px;
}
</style>
