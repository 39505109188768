// Primevue
import PrimeVue from 'primevue/config';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import InputSwitch from 'primevue/inputswitch';
import TieredMenu from 'primevue/tieredmenu';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import Dropdown from 'primevue/dropdown';
import MultiSelect from 'primevue/multiselect';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import MegaMenu from 'primevue/panelmenu';
import Editor from 'primevue/editor';
import OverlayPanel from "primevue/overlaypanel";
import Checkbox from 'primevue/checkbox';
import Chips from 'primevue/chips';
import Tooltip from 'primevue/tooltip';
import Textarea from 'primevue/textarea';
import SplitButton from 'primevue/splitbutton';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import InputNumber from 'primevue/inputnumber';
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

import Vue from 'vue';

Vue.component('InputText', InputText);
Vue.component('Button', Button);
Vue.component('InputSwitch', InputSwitch);
Vue.component('TieredMenu', TieredMenu);
Vue.component('DataTable', DataTable);
Vue.component('Column', Column);
Vue.component('ColumnGroup', ColumnGroup);
Vue.component('Dropdown', Dropdown);
Vue.component('MultiSelect', MultiSelect);
Vue.component('TabView', TabView);
Vue.component('TabPanel', TabPanel);
Vue.component('MegaMenu', MegaMenu);
Vue.component('Editor', Editor);
Vue.component('OverlayPanel', OverlayPanel);
Vue.component('Checkbox', Checkbox);
Vue.component('Chips', Chips);
Vue.component('Textarea', Textarea);
Vue.component('SplitButton', SplitButton);
Vue.component('Accordion', Accordion);
Vue.component('AccordionTab', AccordionTab);
Vue.component('InputNumber', InputNumber);
Vue.directive('tooltip', Tooltip);

Vue.use(PrimeVue);
