<template>
  <div :state="sendItemsTable" class="items-table">
    <b-table
      show-empty
      hover
      small
      foot-clone
      class="line-items"
      head-variant="light"
      stacked="md"
      fixed
      :items="items"
      :fields="fields"
    >
      <template #thead-top>
        <b-tr>
          <b-th class="line-items__buttons-table" variant="dark" :colspan="fields.length">
            <b-button
              variant="info"
              class="line-items__buttons-table--delete"
              @click="deleteRows()"
              size="sm"
              >Delete</b-button
            >
            <b-button @click="addRow()" variant="info" size="sm">Add new row</b-button>
          </b-th>
        </b-tr>
      </template>
      <template #head(actions)>
        <span></span>
      </template>
      <template #head()="row">
        <b-tr>
          <span :key="row.label" class="line-items__header">{{
            row.label.split("_").join(" ")
          }}</span>
        </b-tr>
      </template>
      <template #cell(actions)="row">
        <b-form-checkbox
          v-model="items[row.index].isChecked"
          name="select-row"
          value="accepted"
          unchecked-value="not_accepted"
        >
        </b-form-checkbox>
      </template>
      <template #cell(hu_count)="row">
        <div class="line-items__field-container">
          <b-form-input
            v-model="row.item[0].hu_count"
            @change="totalsFormula()"
            size="sm"
            type="number"
            placeholder="hu count"
            autocomplete="off"
          ></b-form-input>
          <b-form-select
            v-model="row.item[0].hu_count_units"
            :options="huCountUnits"
            @change="totalsFormula()"
            size="sm"
          ></b-form-select>
        </div>
      </template>
      <template #cell(dimensions)="row">
        <div class="line-items__field-container">
          <b-form-input
            v-for="(dimensions, index) in row.item[1]"
            :key="index"
            v-show="index !== 'dimensions_units'"
            v-model="row.item[1][index]"
            size="sm"
            :type="index === 'dimensions_units' ? 'text' : 'number'"
            @change="totalsFormula()"
            :placeholder="index"
            autocomplete="off"
          ></b-form-input>
          <b-form-select
            v-model="row.item[1].dimensions_units"
            :options="dimensionsUnits"
            @change="totalsFormula()"
            size="sm"
          ></b-form-select>
        </div>
      </template>
      <template #cell(weight)="row">
        <div class="line-items__field-container">
          <b-form-input
            v-model="row.item[2].weight"
            size="sm"
            type="number"
            @change="totalsFormula()"
            placeholder="weight"
            autocomplete="off"
          ></b-form-input>
          <b-form-select
            v-model="row.item[2].weight_units"
            :options="weightUnits"
            @change="totalsFormula()"
            size="sm"
          ></b-form-select>
        </div>
      </template>
      <template #cell()="row">
        <b-form-input
          v-model="row.item[3][row.field.label]"
          @change="totalsFormula()"
          :type="row.field.label === 'description' ? 'text' : 'number'"
          size="sm"
          autocomplete="off"
        ></b-form-input>
      </template>
      <template #cell(linear_feet)="row">
        <b-form-checkbox v-model="row.item[3][row.field.label]" name="checkbox-requote"
          >stackable</b-form-checkbox
        >
      </template>
      <template #cell(expected_cost)="row">
        <b-form-checkbox v-model="row.item[3][row.field.label]" name="checkbox-requote"
          >hazmat</b-form-checkbox
        >
      </template>
      <template #foot(actions)>
        <b-form-input
          readonly
          class="line-items__footer--totals-title"
          v-model="totalsTitle"
          size="sm"
          autocomplete="off"
        ></b-form-input>
      </template>
      <template #foot(hu_count)>
        <b-form-input
          readonly
          class="line-items__footer--total-hu-count"
          v-model="totalHuCount"
          placeholder="Total hu count"
          size="sm"
          autocomplete="off"
        ></b-form-input>
      </template>
      <template #foot(dimensions)>
        <div class="d-flex">
          <b-form-input
            readonly
            class="line-items__footer--total-volume"
            v-model="totalDimensions"
            placeholder="Total volume"
            size="sm"
            autocomplete="off"
          ></b-form-input>
          <span>{{ totalDimensionsUnits }}</span>
        </div>
      </template>
      <template #foot(weight)>
        <b-form-input
          class="line-items__footer__total-hu--total"
          v-model="totalWeight"
          placeholder="Total weight"
          size="sm"
          autocomplete="off"
        ></b-form-input>
      </template>
      <template #foot(contribution)>
        <b-form-input
          class="line-items__footer__total-hu--total"
          v-model="markup"
          placeholder="Markup"
          type="number"
          size="sm"
          autocomplete="off"
        ></b-form-input>
      </template>
      <template #foot(nmfc_class)>
        <b-form-input
          class="line-items__footer__total-hu--total"
          v-model="markupPercentage"
          placeholder="Markup %"
          type="number"
          size="sm"
          autocomplete="off"
        ></b-form-input>
      </template>
      <template #foot(linear_feet)>
        <b-form-input
          title="Linear Feet"
          type="number"
          class="line-items__footer--linear-feet"
          v-model="linearFeet"
          placeholder="Linear feet"
          size="sm"
          autocomplete="off"
        ></b-form-input>
      </template>
      <template #foot(description)>
        <b-form-input
          title="Commercial Value"
          type="number"
          class="line-items__footer--commercial-value"
          v-model="commercialValue"
          placeholder="Commercial Value"
          size="sm"
          autocomplete="off"
        ></b-form-input>
      </template>
      <template #foot(expected_cost)>
        <b-form-input
          title="Expected Cost"
          type="number"
          class="line-items__footer--expected-cost"
          v-model="expectedCost"
          placeholder="Expected Cost"
          size="sm"
          autocomplete="off"
        ></b-form-input>
      </template>
    </b-table>
  </div>
</template>

<script>
import tableFields from "@/utils/itemsTableFields";

export default {
  name: "ItemsTable",
  data() {
    return {
      items: [],
      fields: [],
      areAllSelected: "not_accepted",
      totalsTitle: "Totals:",
      totalHuCount: 0,
      totalDimensions: 0,
      totalDimensionsUnits: "cbf",
      totalWeight: 0,
      markup: null,
      markupPercentage: null,
      linearFeet: null,
      commercialValue: null,
      expectedCost: null,
      huCountUnits: [
        "bag", "bags", "bale", "bales", "barrel", "barrels", "basket", "baskets", "bin", "bins", "bottle", "bottles", "box", "boxes", "bundle", "bundles", "canister", "canisters", "carboy", "carboys", "carton", "cartons", "case", "cases", "coil", "coils", "container", "containers", "crate", "crates", "cylinder", "cylinders", "drum", "drums", "item", "items", "keg", "kegs", "other", "others", "package", "packages", "pallet", "pallets", "piece", "pieces", "reel", "reels", "roll", "rolls", "skid", "skids", "spool", "spools", "tank", "tanks", "tray", "trays", "truckload", "truckloads", "tube", "tubes", "unit", "units", "vat", "vats", "pail", "pails",
      ],
      dimensionsUnits: ["inch"],
      weightUnits: ["lb"],
    };
  },
  computed: {
    sendItemsTable() {
      const itemsTable = {
        field_items: this.items,
        markup: this.markup,
        markup_percentage: this.markupPercentage,
        linear_feet: this.linearFeet,
        commercial_value: this.commercialValue,
        expected_cost: this.expectedCost,
      };
      return this.$emit("input", itemsTable);
    },
  },
  watch: {
    areAllSelected() {
      for (let i = 0; i < this.items.length; i += 1) {
        if (this.areAllSelected === "accepted") {
          this.items[i].isChecked = "accepted";
        } else if (this.areAllSelected === "not_accepted") {
          this.items[i].isChecked = "not_accepted";
        }
      }
    },
  },
  created() {
    this.fields = tableFields;
  },
  methods: {
    addRow() {
      this.items.push([
        { hu_count: null, hu_count_units: "pallets" },
        {
          length: null,
          width: null,
          height: null,
          dimensions_units: "inch",
        },
        { weight: null, weight_units: "lb" },
        {
          contribution: null,
          nmfc_class: null,
          description: null,
          stackable: true,
          hazmat: true,
        },
      ]);
    },
    deleteRows() {
      for (let i = 0; i < this.items.length; i += 1) {
        if (this.items[i].isChecked === "accepted") {
          this.items.splice(i, 1);
        }
      }
    },
    totalsFormula() {
      this.totalHuCount = 0;
      this.totalWeight = 0;
      this.totalDimensions = 0;
      for (let i = 0; i < this.items.length; i += 1) {
        this.totalHuCount += Number(this.items[i][0].hu_count);
        this.totalWeight += Number(this.items[i][2].weight);
        const formula = (Number(this.items[i][1].length)
        * Number(this.items[i][1].width)
        * Number(this.items[i][1].height)
        * Number(this.items[i][0].hu_count)) / 1728;
        this.totalDimensions += formula;
      }
      this.totalDimensions = Math.ceil(this.totalDimensions);
      this.items.line_item_volume = this.totalDimensions;
      this.items.line_item_volume_units = this.totalDimensionsUnits;
    },
  },
};
</script>

<style lang="scss" scoped>
.line-items {
  &__buttons-table {
    background-color: #283747 !important;
    text-align: start;
    &--delete {
      margin: 0.5rem;
    }
  }
  &__header {
    font-size: 12.5px;
  }
  &__field-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  &__footer {
    &--totals-title,
    &--total-hu-count,
    &--total-volume {
      border: none;
      background-color: #e9ecef;
      font-weight: bold;
      cursor: alias;
      &:focus {
        border-color: none;
        box-shadow: none;
      }
    }
  }
}
</style>
