 <template>
  <div class="if-rules">
    <ComplexConditions
      v-if="conditionsData" :editConditions="editConditions" :conditionsData="conditionsData"/>
    <!-- <div class="if-rules-header">
      <h5 v-if="conditionsData" class="if-rules-header__title">If...</h5>
      <b-button
        v-if="conditionsData"
        class="if-rules-header__button"
        @click="breakBlock()"
        :disabled="visibleBreakButton"
        ><b-icon v-if="!breakingBlock" icon="lightning-fill" aria-hidden="true">
        </b-icon>
        <b-icon v-else icon="check-circle" animation="throb"></b-icon> Break
        Block</b-button
      >
    </div> -->
    <!-- <template v-if="conditionsData">
      <ConditionsBlock
        v-for="(option, index) in conditionsBlocksArray"
        @pushNewConditionBlock="pushNewConditionBlock"
        @handleDeleteCondition="deleteCondition"
        :conditionsData="conditionsData"
        :blockNumber="index"
        :key="'condition' + option"
        :conditionEditInfo="conditionEditInfo"
        ref="conditionsBlock"
      >
      </ConditionsBlock>
    </template> -->
  </div>
</template>

<script>
// import { mapGetters } from "vuex";
// import ConditionsBlock from "./ConditionsBlock.vue";
import ComplexConditions from "./ComplexConditions.vue";

export default {
  components: {
    // ConditionsBlock,
    ComplexConditions,
  },
  props: {
    conditionsData: {
      type: Array,
    },
    editConditions: {
      type: Object,
    },
  },
  data() {
    return {
      // conditionsBlocksArray: [0],
      // visibleBreakButton: true,
      // breakingBlock: false,
      // conditionEditInfo: [],
    };
  },
  computed: {
    // ...mapGetters("rules", ["showConditionsSchema"]),
  },
  watch: {
    // showConditionsSchema: {
    //   handler() {
    //     const conditionsArray = this.showConditionsSchema.all;
    //     let indexLastCondition = conditionsArray.length - 1;
    //     if (conditionsArray[indexLastCondition].any
    //       && conditionsArray[indexLastCondition].any?.length < 1
    //     ) {
    //       indexLastCondition = indexLastCondition !== 0 ? indexLastCondition - 1 : 0;
    //     }
    //     this.visibleBreakButton = !conditionsArray[indexLastCondition].any
    //       || conditionsArray[indexLastCondition].any?.length < 1;
    //   },
    //   deep: true,
    // },
    // editConditions() {
    //   this.editCondition();
    // },
  },
  methods: {
    // editCondition() {
    //   for (let i = 0; i < this.editConditions.all.length; i += 1) {
    //     // eslint-disable-next-line no-prototype-builtins
    //     if (this.editConditions.all[i].hasOwnProperty('any')) {
    //       for (let j = 0; j < this.editConditions.all[i].any.length; j += 1) {
    //         const OrCondition = {};
    //         OrCondition.typeOfCondition = "Or";
    //         OrCondition.condition = this.editConditions.all[i].any[j];
    //         this.conditionEditInfo.push(OrCondition);
    //       }
    //     } else {
    //       const andCondition = {};
    //       andCondition.typeOfCondition = "And";
    //       andCondition.condition = this.editConditions.all[i];
    //       this.conditionEditInfo.push(andCondition);
    //     }
    //   }
    //   this.pushNewConditionBlockEdit();
    // },
    // pushNewConditionBlockEdit() {
    //   this.conditionEditInfo.forEach(() => {
    //     const flagNumber = this.conditionsBlocksArray[this.conditionsBlocksArray.length - 1];
    //     this.conditionsBlocksArray.push(flagNumber + 1);
    //   });
    //   setTimeout(() => {
    //     this.shareEditConditionInfo();
    //   }, 1000);
    // },
    // pushNewConditionBlock() {
    //   const flagNumber = this.conditionsBlocksArray[this.conditionsBlocksArray.length - 1];
    //   this.conditionsBlocksArray.push(flagNumber + 1);
    // },
    // shareEditConditionInfo() {
    //   this.conditionEditInfo.forEach((item, index) => {
    //     // eslint-disable-next-line max-len
    //     this.$refs.conditionsBlock[index].editConditionsContainer(item);
    //   });
    // },
    // deleteCondition() {
    //   this.conditionsBlocksArray.splice(this.conditionsBlocksArray.length - 1, 1);
    // },
    // breakBlock() {
    //   this.breakingBlock = true;
    //   setTimeout(() => {
    //     this.breakingBlock = false;
    //   }, 1500);
    //   this.$store.commit("rules/setBreakBlock");
    // },
  },
};
</script>
<style lang="scss" scoped>
.overlays {
  width: 60%;
}

.if-rules {
  margin-top: 20px;
   &__button {
    border: 2px solid $color-primary-button;
    color: $color-primary-button;
    &:hover {
      background-color: $color-primary-button;
      color: #fff;
    }
    &:active {
      background-color: rgba($color-primary-button, 0.8) !important;
    }
  }
  &__collapse {
    margin-top: 2rem;
  }
  &__tab {
    padding: 1rem 0.4rem;
    border: 2px solid rgba(128, 128, 128, 0.167);
    border-radius: 0.25rem;
    /* box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2),
      0 1px 8px 0 rgba(0, 0, 0, 0.12); */
  }
}
.if-rules-header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: baseline;
  &__title {
    display: flex;
  }
  &__button {
    @include secondary-button;
    margin: 5px 0;
  }
}
</style>
