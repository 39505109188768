<template>
  <div class="rules-view">
    <div class="rules-view__sidebar">
      <Sidebar />
    </div>
    <!-- SPINNER
    <div
      class="d-flex justify-content-center align-items-center spinner-container"
      v-if="isLoading"
    >
      <img src="@/assets/gif/spinner.gif" width="200" height="200" />
    </div> -->
    <div class="rules-view__content">
      <h1 class="rules-view__content__tittle">Rules</h1>
      <hr class="rules-view__content__separator" />
      <!-- RULES TABLE -->
      <Table
        @openModal="createRuleRedirect()"
        @editRule="editRuleRedirect"
        type="Rule"
        class="mt-5"
      />
    </div>
  </div>
</template>

<script>
// import NewCarrierForm from "@/components/Carriers/NewCarrierForm.vue";
import Table from "@/components/Table.vue";
import Sidebar from "@/components/Sidebar.vue";

export default {
  name: "CarriersRules",
  components: {
    // NewCarrierForm,
    Table,
    Sidebar,
  },
  data() {
    return {
      isLoading: true,
      isPre: true,
      isPost: false,
      contractsFile: null,
      accessControl: false,
    };
  },
  methods: {
    createRuleRedirect() {
      this.$router.push({ name: "CreateRules" });
    },
    async editRuleRedirect(rowData) {
      this.$router.push({ name: "CreateRules", params: { ruleId: rowData.id, profileId: rowData.profile_id } });
    },
  },
};
</script>

<style lang="scss" scoped>
.rules-view {
  display: flex;
  &__sidebar {
    width: 9%;
  }
  &__content {
    width: 91%;
    margin-top: 2rem;
    &__tittle {
      font-weight: bold;
    }
    &__separator {
      width: 95%;
      margin: auto;
    }
  }
}

.create-contracts-section {
  max-width: 1000px;

  .create-contracts-button {
    border: none;
    outline: none;
    width: 100%;
    text-align: left;
    background-color: transparent;

    &:hover {
      color: #00ff00;
    }
  }

  .validation-alert {
    display: none;
  }
}
@media (max-width: 900px) {
  .rules-view {
    &__sidebar {
      width: 0%;
    }
    &__content {
      width: 98%;
      margin-top: 7rem;
    }
  }
}
</style>
