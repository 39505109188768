<template>
  <div :state="sendAccessorialsGlobal" class="accessorials-haul">
    <div class="accessorials-haul__card">
      <b-form-checkbox
        v-for="(accessorial, index) in accessorialsGeneral"
        :key="accessorial.id"
        v-model="acceptedAccessorials[index]"
        value="accepted"
        @input="selectAccessorial(accessorial, index)"
        unchecked-value="not_accepted"
        size="sm"
        class="accessorials-haul__checkbox"
      >
        {{ accessorial.accessorial_name }}
      </b-form-checkbox>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AccessorialsHaul",
  props: {
    sendAccessorials: Array,
  },
  data() {
    return {
      selectedAccessorialsGlobal: [],
      accessorialsGeneral: [],
      acceptedAccessorials: [],
    };
  },
  async created() {
    this.getAccessorialsGeneral();
  },
  computed: {
    ...mapGetters("user", ["showCurrentCompanyDepartment"]),
    sendAccessorialsGlobal() {
      return this.$emit("input", this.selectedAccessorialsGlobal);
    },
  },
  methods: {
    getAccessorialsGeneral() {
      for (let i = 0; i < this.sendAccessorials.length; i += 1) {
        if (this.sendAccessorials[i].accessorial_type === "general") {
          this.accessorialsGeneral.push(this.sendAccessorials[i]);
        }
      }
    },
    selectAccessorial(accessorial, index) {
      if (this.acceptedAccessorials[index] === "accepted") {
        this.selectedAccessorialsGlobal.push(accessorial);
      }
      for (let i = 0; i < this.selectedAccessorialsGlobal.length; i += 1) {
        if (
          this.selectedAccessorialsGlobal[i].accessorial_name === accessorial.accessorial_name
          && this.acceptedAccessorials[index] === "not_accepted"
        ) {
          this.selectedAccessorialsGlobal.splice(i, 1);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.accessorials-haul {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &__card {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    background-color: #fff;
    border-radius: 20px;
    padding: 20px;
    margin: 15px 0;
    box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2),
      0 1px 8px 0 rgba(0, 0, 0, 0.12);
  }
  &__checkbox {
    padding: 0 1rem;
  }
}
</style>
