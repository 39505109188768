<template>
  <div>
    <!-- MODAL -->
    <b-modal hide-footer class="user-modal" ref="new-user-modal" size="lg" :title="modalTitle">
      <div class="user-modal__row">
        <div class="user-modal__column">
          <label class="user-modal__column__text" for="name"
            >Name <b style="color: red">*</b></label
          >
          <InputText
            class="user-modal__column__input"
            :class="errors.name"
            :key="renderInput"
            @change="validateName(newUser.name, 'name')"
            v-model="newUser.name"
            id="name"
            type="text"
          />
        </div>
        <div class="user-modal__column">
          <label class="user-modal__column__text" for="lastname"
            >Last Name <b style="color: red">*</b></label
          >
          <InputText
            class="user-modal__column__input"
            :class="errors.lastname"
            :key="renderInput"
            v-model="newUser.lastname"
            @change="validateName(newUser.lastname, 'lastname')"
            id="lastname"
            type="text"
          />
        </div>
        <div class="user-modal__column">
          <label class="user-modal__column__text" for="email"
            >Email <b style="color: red">*</b></label
          >
          <InputText
            class="user-modal__column__input"
            :class="errors.email"
            :key="renderInput"
            v-model="newUser.email"
            @change="validateEmail(newUser.email, 'email')"
            id="email"
            type="text"
          />
        </div>
      </div>
      <div class="user-modal__row">
        <div class="user-modal__column">
          <label class="user-modal__column__text" for="username"
            >Username <b style="color: red">*</b></label
          >
          <InputText
            class="user-modal__column__input"
            :class="errors.username"
            :key="renderInput"
            v-model="newUser.username"
            @change="validateUsername(newUser.username, 'username')"
            id="username"
            type="text"
          />
        </div>
        <div v-if="modalTitle === 'Edit User'" class="user-modal__status-column">
          <label class="user-modal__column__text" for="role">Status</label>
          <Dropdown class="user-modal__column__input" v-model="newUser.status" :options="status" />
        </div>
        <div v-if="modalTitle !== 'Edit User'" class="user-modal__column">
          <label class="user-modal__column__text" for="password">
            *Password <b style="color: red">*</b>
          </label>
          <InputText
            class="user-modal__column__input"
            :class="errors.password"
            :key="renderInput"
            v-model="newUser.password"
            @change="validatePassword(newUser.password, 'password')"
            id="password"
            type="password"
          />
        </div>
        <div v-if="modalTitle !== 'Edit User'" class="user-modal__column">
          <label class="user-modal__column__text" for="confirmPassword"
            >Confirm Password <b style="color: red">*</b></label
          >
          <InputText
            class="user-modal__column__input"
            :class="errors.confirmPassword"
            :key="renderInput"
            v-model="newUser.confirmation_password"
            @change="verifyPassword(newUser.confirmation_password, 'confirmPassword')"
            id="confirmPassword"
            type="password"
          />
        </div>
      </div>
      <p class="user-modal__password-text" v-if="modalTitle !== 'Edit User'">
        *Password must have 8 characters and contain at least: one capital letter, one number and
        one special character.
      </p>
      <hr v-if="modalTitle !== 'Edit User'" />
      <div v-if="modalTitle !== 'Edit User'" class="user-modal__row">
        <div class="user-modal__column">
          <label class="user-modal__column__text" for="role"
            >Role <b style="color: red" v-if="!disabledRole">*</b></label
          >
          <Dropdown
            class="user-modal__column__input"
            v-model="newUser.role"
            :options="roles"
            optionLabel="name"
            optionValue="code"
            @change="roleChanged"
            :disabled="disabledRole"
          />
        </div>
        <div class="user-modal__column">
          <label class="user-modal__column__text" for="name"
            >Company <b style="color: red" v-if="companies.length !== 0">*
              </b></label
          >
          <Dropdown
            class="user-modal__column__input"
            v-model="newUser.company_id"
            :options="companies"
            optionLabel="name"
            optionValue="id"
            :disabled="disabledCompany || companies.length === 0"
            @change="getdepartments()"
          />
        </div>
        <div class="user-modal__column">
          <label class="user-modal__column__text" for="department">Department/s
            <b style="color: red" v-if="!disabledDep">*</b>
          </label>
          <MultiSelect
            class="user-modal__column__input"
            v-model="newUser.department_id"
            :options="departments"
            :optionLabel="optionMultiselect() == true ?  'name' : 'department_name'"
            :optionValue="optionMultiselect() == true ?  'id' : 'department_id'"
            :disabled="disabledDep"
            placeholder=""
          />
        </div>
      </div>
      <b-overlay class="overlay-button" :show="isLoading" rounded opacity="0.6" spinner-small>
        <b-button
          v-if="modalTitle !== 'Edit User'"
          @click="createUser()"
          class="user-modal__button"
          :disabled="!canCreate"
        >
          Create User
        </b-button>
        <b-button v-else @click="verifyUpdate()" class="user-modal__button" :disabled="!canUpdate">
          Save Changes
        </b-button>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import { successMessage, deleteMessage } from "@/utils/alertMessages";
import { userInformation } from "@/mixins/itemsTable/infoUser";

export default {
  name: "CreateUserModal",
  data() {
    return {
      modalTitle: "",
      newUser: {},
      errors: {},
      canCreate: false,
      canUpdate: false,
      disabledCompany: false,
      disabledDep: false,
      disabledRole: false,
      userInfo: null,
      checked: false,
      isLoading: false,
      companies: [],
      departments: [],
      roles: [
        { name: "Company Admin", code: "company_admin" },
        { name: "Department Admin", code: "department_admin" },
      ],
      status: ["active", "inactive"],
      renderInput: 0,
    };
  },
  created() {
    this.userInfo = JSON.parse(localStorage.getItem("user-info"));
    this.cleanColor();
  },
  watch: {
    newUser: {
      handler() {
        this.checkForm();
      },
      deep: true,
    },
  },
  methods: {
    validateName(value, input) {
      if (/^[a-zA-ZÀ-ÿ\u00f1\u00d1]*$/.test(value)) {
        this.errors[input] = "input-valid";
      } else {
        this.errors[input] = "error";
      }
      this.errors[input] = !value ? "error" : this.errors[input];
      this.renderInput += 1;
      this.checkForm();
    },
    validateUsername(value, input) {
      if (/^[a-zA-ZÀ-ÿ0-9]*$/.test(value)) {
        this.errors[input] = "input-valid";
      } else {
        this.errors[input] = "error";
      }
      this.errors[input] = !value ? "error" : this.errors[input];
      this.renderInput += 1;
      this.checkForm();
    },
    validateEmail(value, input) {
      if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || value === "") {
        this.errors[input] = "input-valid";
      } else {
        this.errors[input] = "error";
      }
      this.errors[input] = !value ? "error" : this.errors[input];
      this.renderInput += 1;
      this.checkForm();
    },
    validatePassword(value, input) {
      if (
        /^(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%=+/();:<>_^&*¿?¡,.{}])[a-zA-Z0-9\d!@#$%=+/();:<>_^&*¿?¡,.{}]+$/.test(value)
        || value === ""
      ) {
        this.errors[input] = "input-valid";
      } else {
        this.errors[input] = "error";
      }
      this.errors[input] = !value ? "error" : this.errors[input];
      this.renderInput += 1;
      this.checkForm();
    },
    verifyPassword(value, input) {
      if (value === this.newUser.password && value) {
        this.errors[input] = "input-valid";
      } else {
        this.errors[input] = "error";
      }
      this.renderInput += 1;
      this.checkForm();
    },
    cleanColor() {
      this.errors.name = "";
      this.errors.lastname = "";
      this.errors.email = "";
      this.errors.username = "";
      this.errors.password = "";
      this.errors.confirmPassword = "";
    },
    checkForm() {
      if (
        !Object.values(this.errors).some((error) => error === true || error === "error")
        && this.newUser.name !== ""
        && this.newUser.lastname !== ""
        && this.newUser.email !== ""
        && this.newUser.username !== ""
      ) {
        this.canCreate = true;
        this.canUpdate = true;
        if (
          this.modalTitle === "Add New User"
          && (this.newUser.confirmation_password === ""
            || this.newUser.role === ""
            || this.newUser.company_id === null
            || this.newUser.department_id.length === 0
            || this.newUser.department_id === "")
        ) {
          this.canCreate = false;
          this.canUpdate = false;
        }
      } else {
        this.canCreate = false;
        this.canUpdate = false;
      }
    },
    validateAllFields() {
      this.cleanColor();
      this.validateName(this.newUser.name, "name");
      this.validateName(this.newUser.lastname, "lastname");
      this.validateUsername(this.newUser.username, "username");
      this.validateEmail(this.newUser.email, "email");
    },
    async roleChanged($event) {
      if ($event.value === "company_admin") {
        if (this.userInfo.role === "global_admin") {
          this.disabledDep = true;
          this.newUser.department_id = "";
          this.newUser.department_id = [-2];
          const companiesData = await this.$store.dispatch("company/getAllCompanies");
          this.companies = companiesData.data;
        } else {
          this.disabledDep = true;
          this.disabledCompany = true;
          this.newUser.department_id = "";
          this.newUser.company_id = this.userInfo.company_id;
          if (this.userInfo.role === "company_admin") {
            this.departments = this.userInfo.company_info[0].departments;
            this.disabledDep = false;
          }
          // this.newUser.department_id = this.userInfo.company_info[0].departmentsdepartment_id;
        }
      } else {
        let companiesData = null;
        this.newUser.department_id = [];
        this.disabledDep = false;
        if (this.userInfo.role === "global_admin") {
          companiesData = await this.$store.dispatch("company/getAllCompanies");
          this.companies = companiesData.data;
        } else if (this.userInfo.role === "company_admin") {
          this.disabledCompany = true;
          this.newUser.company_id = this.userInfo.company_id;
          await this.getdepartments();
        }
      }
    },
    getUserInfo() {
      return userInformation(this.userInfo);
    },
    async showModal(option) {
      if (option) {
        this.modalTitle = "Edit User";
        this.canUpdate = false;
        const params = this.getUserInfo(this.userInfo);
        params.user_id = option.id;
        if (this.userInfo.role === "department_admin") {
          params.department_id = option.departments[0].id;
        }
        this.newUser = await this.$store.dispatch("user/getUserByIdCompleteInfo", params);
        this.validateAllFields();
      } else {
        this.cleanColor();
        this.modalTitle = "Add New User";
        this.disabledDep = false;
        this.newUser = {
          username: "",
          name: "",
          lastname: "",
          email: "",
          company_id: null,
          status: "active",
          password: "",
          confirmation_password: "",
          role: "",
          department_id: [],
        };
        if (this.userInfo.role === "department_admin") {
          this.disabledRole = true;
          this.disabledCompany = true;
          this.newUser.role = "department_admin";
          this.newUser.company_id = this.userInfo.company_id;
          this.departments = this.userInfo.company_info[0].departments;
        }
      }
      this.$refs["new-user-modal"].show();
    },
    async getdepartments() {
      this.validateRoleForDep();
      this.disabledCompany = true;
      const params = {
        company_id: this.newUser.company_id,
        department_id: -2,
      };
      const depData = await this.$store.dispatch("company/getDepartmentsPerCompany", params);
      this.disabledCompany = false;
      this.departments = depData.data;
    },
    async validateRoleForDep() {
      this.newUser.department_id = [];
      this.departments = [];
      if (this.newUser.role === "company_admin") {
        if (this.userInfo.role === "global_admin") {
          this.disabledDep = true;
          this.newUser.department_id = "";
          this.newUser.department_id = [-2];
        } else {
          this.disabledDep = true;
          this.newUser.department_id = "";
          if (this.userInfo.role === "company_admin") {
            this.disabledDep = false;
          }
        }
      } else {
        this.newUser.department_id = [];
        this.disabledDep = false;
        if (this.userInfo.role === "company_admin") {
          this.disabledCompany = true;
          await this.getdepartments();
        }
      }
    },
    async createUser() {
      this.isLoading = true;
      const data = {
        company_id: this.newUser.company_id,
        department_id: -2,
        info: this.newUser,
      };
      if (this.newUser.department_id === "") {
        this.newUser.department_id = null;
      } else if (this.userInfo.role === "company_admin" && this.newUser.role === "company_admin") {
        this.newUser.department_id = [null];
      } else if (this.userInfo.role === "department_admin") {
        [data.department_id] = this.newUser.department_id;
      }
      const response = await this.$store.dispatch("user/createUser", data);
      this.isLoading = false;
      if (response.status === 200 || response.status === 201) {
        this.$refs["new-user-modal"].hide();
        successMessage(`The user has been created.`);
        this.isLoading = false;
        const event = {
          index: 0,
        };
        this.$emit("getData", event);
      }
    },
    async verifyUpdate() {
      const warningResponse = await deleteMessage(
        'Are you sure you want to update the user?',
        "You won't be able to revert this!",
      );
      if (warningResponse.isConfirmed) {
        this.updateUser();
      }
    },
    async updateUser() {
      this.isLoading = true;
      const data = {
        company_id: this.newUser.company_id,
        user_id: this.newUser.id,
        info: {
          username: this.newUser.username,
          name: this.newUser.name,
          lastname: this.newUser.lastname,
          email: this.newUser.email,
          status: this.newUser.status,
        },
      };
      if (this.userInfo.role === "department_admin") {
        data.department_id = this.newUser.departments[0].id;
      } else {
        data.department_id = -2;
      }
      const response = await this.$store.dispatch("user/updateUser", data);
      this.isLoading = false;
      if (response.status === 200 || response.status === 204) {
        this.$refs["new-user-modal"].hide();
        successMessage(`The user has been updated.`);
        this.isLoading = false;
        const event = {
          index: 0,
        };
        this.$emit("getData", event);
      }
    },
    optionMultiselect() {
      return (this.userInfo.role === 'department_admin'
        && this.newUser.role === 'department_admin')
                || (this.userInfo.role === 'company_admin' && this.newUser.role === 'company_admin');
    },
  },
};
</script>

<style lang="scss" scoped>
.user-modal {
  &__button {
    @include submit-button;
  }
  &__row {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
  }
  &__column {
    display: flex;
    flex-direction: column;
    width: 30%;
    &__text {
      color: $color-input-text;
      margin-left: 0.2rem;
    }
    &__input {
      @include input;
    }
  }
  &__status-column {
    display: flex;
    flex-direction: column;
    width: 28%;
    position: absolute;
    left: 36%;
  }
  &__password-text {
    color: $color-input-text;
    font-size: 12px;
    padding: 0 1rem 1rem;
  }
  &__container-button {
    padding: 1rem;
  }
  &__card {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    box-shadow: $box-shadow;
    width: 95%;
    margin: 0 auto 1rem;
    border-radius: 15px;
    padding: 1rem 3rem;
    position: relative;
    &__column {
      display: flex;
      flex-direction: column;
      width: 48%;
    }
  }
  &__trash {
    position: absolute;
    right: 0;
    top: 0;
    margin: 0.5rem 1rem;
    cursor: pointer;
  }
}
.error {
  border-color: $color-input-error;
}
.input-valid {
  border: 1px solid #00b900;
}
.overlay-button {
  display: flex;
  margin: auto;
  justify-content: center;
  padding: 2rem 1rem 1rem;
}
:deep(.p-dropdown-label) {
  padding-top: 0.3rem;
}
</style>
