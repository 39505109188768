<template>
  <div class="formulas-table">
    <b-button class="formulas-table__button" @click="showTable = !showTable" variant="primary"
      ><b-icon :icon="showTable ? 'chevron-double-up' : 'chevron-double-down'"></b-icon>
      Formulas</b-button
    >
    <b-collapse v-model="showTable" id="formulas">
      <b-table :items="formulasInfo">
        <template #cell(numeric_ops)="row">
          <b-td v-for="value in fields" :key="value"
            ><b>{{ value }}</b></b-td
          >
          <b-tr v-for="(operations, index) in row.item.numeric_ops" :key="index">
            <b-td>{{ Object.keys(operations)[0] }}</b-td>
            <b-td>{{ Object.values(operations)[0].symbol }}</b-td>
          </b-tr>
        </template>
        <template #cell(sequences_ops)="row">
          <b-td v-for="value in fields" :key="value"
            ><b>{{ value }}</b></b-td
          >
          <b-tr v-for="(operations, index) in row.item.sequences_ops" :key="index">
            <b-td>{{ Object.keys(operations)[0] }}</b-td>
            <b-td>{{ Object.values(operations)[0].symbol }}</b-td>
          </b-tr>
        </template>
        <template #cell(int_float_variable)="row">
          <b-tr v-for="(values, index) in row.item.int_float_variable" :key="index">
            <b-td>{{ values }}</b-td>
          </b-tr>
        </template>
        <template #cell(sequences_variables)="row">
          <b-tr v-for="(values, index) in row.item.sequences_variables" :key="index">
            <b-td>{{ values }}</b-td>
          </b-tr>
        </template>
      </b-table>
    </b-collapse>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "formulasTable",
  data() {
    return {
      showTable: false,
      formulasInfo: null,
      numeric_op: null,
      fields: ["Name", "Symbol"],
    };
  },
  computed: {
    ...mapGetters("rules", ["showRuleCompleteObject"]),
  },
  watch: {
    showRuleCompleteObject() {
      this.formulasInfo = [this.showRuleCompleteObject.formulas];
    },
  },
};
</script>

<style lang="scss" scoped>
.formulas-table {
  margin: 2rem;
  &__button {
    @include secondary-button;
    display: flex;
    justify-content: flex-start;
    margin: 1rem 0;
  }
  .b-icon.bi {
    margin-right: 0.5rem;
  }
}
:deep(.table) {
  text-align: center;
  & thead th {
    border: 1px solid #dee2e6;
    padding: 0.5rem 0;
  }
  & td {
    padding: 0.1rem 0.3rem;
    border: 1px solid #dee2e6;
    width: 1%;
    & tr {
      padding: 0.5rem 0;
      border: 1px solid #dee2e6;
    }
  }
}
</style>
