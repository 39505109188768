<template>
  <div>
    <div class="rules-modal">
      <AssociateProfForm :entity="'rules'" :ruleEdition="true" :typeTemplateRule="'carrierRule'"/>
      <hr class="rules-modal__info-separator" />
      <div class="rules-modal__row">
        <div class="rules-modal__column">
          <label class="rules-modal__text" for="name">Name</label>
          <InputText
            :key="renderEditor"
            class="rules-modal__input"
            :class="validField.name"
            id="name"
            type="text"
            v-model="rulesName"
            @change="validField.name = changeFieldsColor(rulesName, 'name')"
          />
        </div>
        <div class="rules-modal__column">
          <label class="rules-modal__text" for="description">Description</label>
          <InputText
            :key="renderEditor"
            class="rules-modal__input"
            :class="validField.description"
            id="description"
            type="text"
            v-model="infoCarrierRules.description"
            @change="
              validField.description = changeFieldsColor(
                infoCarrierRules.description,
                'description'
              )
            "
          />
        </div>
        <div class="rules-modal__column">
          <label class="rules-modal__text" for="description">Rule Importance</label>
          <InputText
            :key="renderEditor"
            class="rules-modal__input"
            :class="validField.weight"
            id="weight"
            type="number"
            v-model="infoCarrierRules.rule_weight"
            @change="validField.weight = changeFieldsColor(infoCarrierRules.rule_weight, 'weight')"
            autocomplete="off"
            v-tooltip="{ value: ruleImportant, disabled: false }"
          />
        </div>
        <div class="rules-modal__column">
          <label class="rules-modal__text">Status</label>
          <Dropdown
            class="rules-modal__input"
            v-model="infoCarrierRules.status"
            placeholder="Select status"
            :options="statusOptions"
            optionLabel="name"
            optionValue="label"
          />
        </div>
        <div class="rules-modal__line">
          <label class="rules-modal__line__text" for="label">Label</label>
          <b-form-tags
            :key="renderEditor"
            class="rules-modal__line__input"
            :class="validField.label"
            input-id="label"
            v-model="infoCarrierRules.label"
            @input="validField.label = changeFieldsColor(infoCarrierRules.label, 'label')"
          >
          </b-form-tags>
        </div>
      </div>
      <hr class="rules-modal__info-separator" />
      <div class="rules-modal__row">
        <div
          class="rules-modal__column-group p-inputgroup"
          v-for="(condition, index) in infoCarrierRules.data.conditions.any"
          :key="index"
        >
          <span class="p-inputgroup-addon">
            {{ conditionsLabels[index] }}
          </span>
          <span class="p-inputgroup-addon">
            {{ conditionsOperators[index] }}
          </span>
          <InputNumber
            @input="validField.condition = changeFieldsColor(condition.value, 'condition')"
            v-model="condition.value" placeholder="Value" :minFractionDigits="1"
            :useGrouping="false"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// eslint-disable-next-line import/no-cycle
import { errorDynamicMessage } from "@/utils/alertMessages";
import AssociateProfForm from "@/components/AssociateProfForm.vue";
import infoLTLRules from "@/utils/LTLRules";

export default {
  name: "CarrierRules",
  components: {
    AssociateProfForm,
  },
  data() {
    return {
      infoRule: infoLTLRules.infoLTLRules[0],
      infoCarrierRules: {},
      companyInfo: null,
      validField: {},
      renderEditor: 0,
      ruleEdition: false,
      statusOptions: [
        { name: "Active", label: "active" },
        { name: "Inactive", label: "inactive" },
      ],
      ruleImportant:
        "La importancia de la regla indica en que orden se ejecutará, es decir, las reglas con menor valor se ejecutarán primero y las de mayor valor de último. Por ejemplo, si la regla A, B Y C tienen rule important de 1, las reglas D Y E tienen rule important de 2, y la regla F tiene rule important de 3; el orden de ejecución será: A, B, C, D, E, F.",
      conditionsLabels: [],
      conditionsOperators: [],
      nullData: true,
      conditionsData: {},
      rulesName: null,
    };
  },
  async created() {
    const ruleCopy = JSON.parse(JSON.stringify(this.infoRule));
    this.infoCarrierRules.status = this.infoRule.status;
    this.infoCarrierRules.rule_weight = this.infoRule.rule_weight;
    this.infoCarrierRules.data = { ...ruleCopy.data };
    this.infoCarrierRules.trigger = this.infoRule.trigger;
    this.infoCarrierRules.data.conditions.any.forEach((condition) => {
      if (condition.name === "haul_max_weight_divided_by_hu_count") {
        this.conditionsLabels.push("Max weight per piece");
      } else {
        this.transformLabel(condition.name, this.conditionsLabels);
      }
      this.transformLabel(condition.operator, this.conditionsOperators);
    });
  },
  computed: {
    ...mapGetters("company", ["showProfileCompanysDepartment"]),
  },
  watch: {
    showProfileCompanysDepartment() {
      this.companyInfo = [...this.showProfileCompanysDepartment.data];
    },
  },
  methods: {
    changeCarrierName(carrierName) {
      this.rulesName = this.infoRule.name.replace('<CARRIER NAME>', carrierName.toUpperCase());
      this.infoCarrierRules.label = this.infoRule.label;
      this.infoCarrierRules.label[0] = carrierName.toUpperCase();
      this.infoCarrierRules.description = this.infoRule.description.replace('<carrier name>', carrierName.toLowerCase());
      this.infoCarrierRules.data.actions[0].params.carrier_name = carrierName.toLowerCase();
      this.renderEditor += 1;
    },
    transformLabel(condition, array) {
      const name = condition.replaceAll('_', ' ');
      array.push(name[0].toUpperCase() + name.substring(1));
    },
    async createRule(infoRules) {
      this.$store.commit("rules/setTrigger", this.infoCarrierRules.trigger);
      this.$store.commit("rules/setConditionSchema", this.conditionsData);
      this.$store.commit("rules/setActions", this.infoCarrierRules.data.actions);
      const completeInfoRules = infoRules;
      completeInfoRules.body = this.infoCarrierRules;
      completeInfoRules.body.name = this.rulesName;
      const response = await this.$store.dispatch("rules/createRule", completeInfoRules);
      this.$emit("handleCarrierRules", response);
    },
    verifyForm() {
      let completeData = 0;
      this.nullData = true;
      this.conditionsData = JSON.parse(JSON.stringify(this.infoCarrierRules.data.conditions));
      this.infoCarrierRules.data.conditions.any.forEach((item, index) => {
        completeData += item.value === 0;
        if (!item.value) {
          this.nullData = false;
        }
        this.conditionsData.any[index].value = [item.value];
      });
      if (completeData !== 0 || !this.nullData) {
        errorDynamicMessage("Error", "Please fill in all conditions' parameters in carrier rules");
        return false;
      }
      return true;
      // this.createRule(completeInfoRules);
      // this.$emit("getAreCarrierRules", true);
    },
    changeFieldsColor(fields, type) {
      if (type !== "label" && fields) {
        this.renderEditor += 1;
        return "rules-modal__input--input-valid";
      }
      if (type === "label" && fields.length > 0) {
        this.renderEditor += 1;
        return "rules-modal__input--input-valid";
      }
      this.renderEditor += 1;
      return "p-invalid";
    },
  },
};
</script>

<style lang="scss" scoped>
.rules-modal {
  &__info-separator {
    width: 95%;
    margin: 1.5rem auto 1rem;
  }
  &__button {
    @include button-outlined;
    margin-top: 1rem;
    float: left;
    left: 2rem;
  }
  &__create-button {
    @include submit-button;
  }
  &__row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 2rem;
  }
  &__column {
    display: flex;
    padding-top: 0.5rem;
  }
  &__column-group {
    display: flex;
    padding-top: 0.5rem;
    width: 48%;
  }
  &__text {
    width: 80px;
    align-self: center;
    color: $color-input-text;
    margin: 0 0.5rem 0;
  }
  &__input {
    width: 20rem;
    @include input;
    // height: auto;
    &--input-valid {
      border: 1px solid #00b900;
      border-radius: 8px;
    }
    &--input-invalid {
      border: 1px solid #f44336;
      border-radius: 8px;
    }
  }
  &__line {
    display: flex;
    margin-top: 0.8rem;
    &__text {
      width: 80px;
      color: $color-input-text;
      margin: 0 0.5rem 0;
    }
    &__input {
      width: 20rem;
      @include input;
      // height: auto;
      height: 6rem !important;
      overflow-y: auto;
      &--input-valid {
        border: 1px solid #00b900;
        border-radius: 8px;
      }
      &--input-invalid {
        border: 1px solid #f44336;
        border-radius: 8px;
      }
    }
  }
}
:deep(.associate-prof-form) {
  margin: 1rem 2rem 0;
  &__selection-associate {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    & hr {
      display: none;
    }
  }
  &__row {
    margin: 0;
    &--label {
      width: 80px;
      margin: 0 0.5rem 0;
    }
    &--input {
      width: 20rem;
      height: auto;
    }
  }
}
.overlay-button {
  display: flex;
  margin: auto;
  justify-content: center;
  padding: 2rem 1rem 1rem;
}
:deep(.p-multiselect-label) {
  padding-top: 0.3rem;
}
:deep(.p-dropdown-label) {
  padding-top: 0.3rem;
}
:deep(.p-chips-multiple-container) {
  @include input;
  width: 20rem;
  height: auto;
  margin: 0;
}
:deep(.p-chips-multiple-container) {
  overflow: auto;
}
</style>
