import CreateRules from "../../views/CarriersRules/Components/CreateRuleMain.vue";

const createRulesRoute = [
  {
    path: "/createRules/:ruleId?/:profileId?",
    name: "CreateRules",
    component: CreateRules,
  },
];

export default createRulesRoute;
