import api from '../clients/axios';

const resource = '/api/v1';

export default {
  async getAllCompanies(params) {
    const response = await api.get(`${resource}/companies`, { params });
    return response.data;
  },

  async getCompanyById(idCompany) {
    const response = await api.get(`${resource}/companies/${idCompany}`);
    return response.data;
  },

  createCompany(payload) {
    return api.post(`${resource}/companies`, payload);
  },

  getInfoCompany(payload) {
    const response = api.get(`${resource}/companies/${payload.company_id}/departments/${payload.department_id}/profiles`, payload);
    return response;
  },

  updateCompany(payload) {
    return api.patch(`${resource}/companies/${payload.company_id}`, payload.data);
  },

  deleteCompany(params) {
    return api.delete(`${resource}/companies/${params.company_id}?confirmation_message=${params.confirmation_message}`);
  },

  async getProfilesPerCompany(payload) {
    const response = await api.get(`${resource}/companies/${payload.company_id}/departments/${payload.department_id}/profiles/${payload.profile_id}`);
    return response.data;
  },

  async getDepartmentsPerCompany(payload) {
    const response = await api.get(`${resource}/companies/${payload.company_id}/departments/${payload.department_id}/profiles`, { params: payload });
    return response.data;
  },

  createProfilesAssociation(payload) {
    return api.post(`${resource}/companies/${payload.company_id}/departments/${payload.department_id}/profiles?profile_id=${payload.profile_id}`);
  },

  updateProfilesAssociation(payload) {
    return api.patch(`${resource}/companies/${payload.company_id}/departments/${payload.department_id}`, payload.data);
  },

  createDepartment(payload) {
    return api.post(`${resource}/companies/${payload.company_id}/departments`, payload);
  },

  deleteAssociation(payload) {
    return api.delete(`${resource}/${payload.company_id}/departments/{department_id}?department_x_company_x_profile_id=${payload.department_x_company_x_profile_id}&confirmation_message=${payload.confirmation_message}`);
  },

  async getProfileCompanysDepartment(payload) {
    const response = await api.get(`${resource}/companies/${payload.company_id}/departments/${payload.department_id}/profiles`);
    return response;
  },
};
