<template>
  <div class="update-rates">
    <el-collapse accordion @change="handleCollapseChange">
      <el-collapse-item title="Update Rates" name="1">
        <el-alert
          v-if="amount !== null"
          title="Alert!"
          type="warning"
          :closable="false"
          :description="`This carrier currently has ${amount} existing rates.
          Uploading files will overwrite all existing rates.`"
          show-icon
        />

        <div
          v-for="(label, key) in uploadFields"
          :key="key"
          class="input-container"
        >
          <div class="input-container__header">
            <label class="input-container__label" :for="key">
              {{ label }} <b style="color: red" v-if="key !== 'fuels'">*</b>
            </label>
          </div>
          <el-upload
            class="upload-demo"
            :limit="1"
            accept=".xlsx, .csv"
            :auto-upload="false"
            :action="`/upload/${key}`"
            :on-remove="handleRemove(key)"
            :on-change="(file) => upload(file, key)"
          >
            <template #trigger>
              <el-button type="primary">Select {{ label }} File</el-button>
            </template>
            <template #tip>
              <div class="el-upload__tip text-red">
                Limit 1 file, new file will cover the old file.
              </div>
            </template>
          </el-upload>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
export default {
  name: 'DropdownUpdateRates',
  props: {
    carrierName: String,
  },
  data() {
    return {
      amount: null,
      files: {
        rates: null,
        zones: null,
        fuels: null,
      },
      uploadFields: {
        rates: 'Rates',
        zones: 'Zones',
        fuels: 'Fuels',
      },
    };
  },
  methods: {
    handleRemove(fileType) {
      return () => {
        this.$set(this.files, fileType, null);
        this.validateRequiredFiles();
      };
    },
    async handleCollapseChange(activeNames) {
      if (activeNames.includes('1')) {
        const payload = {
          carrier_service: this.carrierName,
        };
        const responseCount = await this.$store.dispatch(
          'carriers/getCarrierCountRates',
          payload,
        );
        this.amount = responseCount.data.count;
      }
    },
    upload(file, fileType) {
      if (file && file.raw) {
        this.$set(this.files, fileType, file.raw);
        this.validateRequiredFiles();
      }
    },
    validateRequiredFiles() {
      const hasRates = !!this.files.rates;
      const hasZones = !!this.files.zones;
      const isInvalid = (hasRates && !hasZones) || (!hasRates && hasZones);

      this.$emit('filesValidation', isInvalid);
    },
    async uploadFiles() {
      if (!this.files.rates || !this.files.zones) {
        return;
      }
      const fileData = new FormData();
      fileData.append('rates', this.files.rates);
      fileData.append('zones', this.files.zones);

      if (this.files.fuels) {
        fileData.append('fuels', this.files.fuels);
      }

      const payload = {
        carrier_service: this.carrierName,
        body: fileData,
      };
      await this.$store.dispatch('carriers/uploadFileCarrier', payload);
    },
  },
  mounted() {
    this.$on('triggerUpload', this.uploadFiles);
  },
};
</script>

<style lang="scss" scoped>
.update-rates {
  width: 100%;
}

.input-container {
  display: flex;
  flex-direction: column;
  padding: 15px;
  margin: 15px 0;
  border-radius: 13px;
  background-color: #efefef;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__label {
    text-align: start;
    font-size: 16px;
    font-weight: bold;
  }
}

::v-deep {
  .el-collapse {
    width: 100%;
  }

  .el-collapse-item__header {
    font-size: 16px;
  }
  .el-button--primary {
  background-color: #283747;
  border-color: #283747;
}
.el-button--primary:focus, .el-button--primary:hover, .el-button--primary:active {
  background-color: #283747;
  border-color: #283747;
}
.el-alert--warning.is-light {
  text-align: initial;
}
}

</style>
