<template>
  <div>
    <Sidebar />
    <div class="rules-modal">
        <h1 class="rules-modal__content-tittle">LTL Rules</h1>
        <hr class="rules-modal__content-separator" />
        <Button
          icon="pi pi-arrow-left"
          class="p-button-rounded p-button-outlined rules-modal__button"
          @click="backToCards()"
        />
        <div v-loading="isLoading">
          <a class="scrollNavigation top" href="#top"><i class="pi pi-arrow-up"></i></a>
          <a class="scrollNavigation bottom" href="#bottom"><i class="pi pi-arrow-down"></i></a>
          <div class="rules-modal__row">
            <label class="rules-modal__text">Carrier</label>
            <Dropdown
              class="rules-modal__input"
              v-model="ruleCarrier"
              :options="carriers"
              placeholder="Select Carrier"
              :filter="true"
              @change="changeCarrier()"
            />
          </div>
          <Accordion :activeIndex.sync="active">
              <AccordionTab header="Carrier Rules"
              :disabled="accordionDisabled || carrierSent">
                  <CarrierRules ref="carrierRules" @handleCarrierRules="handleCarrierRules"/>
              </AccordionTab>
              <AccordionTab header="Accessorials"
              :disabled="accordionDisabled || accessorialSent">
                  <Accessorials ref="accessorials" @handleAccessorials="handleAccessorials"/>
              </AccordionTab>
              <AccordionTab header="Line Item Accessorials"
              :disabled="accordionDisabled || lineItemSent">
                  <LineItemAccessorials
                  ref="lineItemAccessorials"
                  @handleLineItem="handleLineItem"/>
              </AccordionTab>
          </Accordion>
          <b-overlay
            id="bottom"
            class="overlay-button"
            :show="saveSpinner"
            rounded
            opacity="0.6"
            spinner-small
          >
            <b-button
              class="rules-modal__create-button"
              @click="verifyForm()"
            >
              Create Rule
            </b-button>
          </b-overlay>
        </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CarrierRules from "@/views/CarriersRules/Components/LTLRules/CarrierRule.vue";
// eslint-disable-next-line import/no-cycle
import { warningMessage, successMessage } from "@/utils/alertMessages";
// eslint-disable-next-line import/no-cycle
import Sidebar from "../../../components/Sidebar.vue";
import LineItemAccessorials from "./LTLRules/LineItemAccessorials.vue";
import Accessorials from "./LTLRules/Accessorials.vue";

export default {
  name: "CreateLTLRule",
  components: {
    Sidebar,
    CarrierRules,
    LineItemAccessorials,
    Accessorials,
  },
  data() {
    return {
      ruleCarrier: "",
      saveSpinner: false,
      companyInfo: null,
      accessorialCreated: false,
      lineItemCreated: false,
      carrierRulesCreated: false,
      infoCarrierRules: null,
      infoRules: {
        params: {},
        body: {},
      },
      areCarriersComplete: false,
      areLineItmesComplete: false,
      carriers: [],
      accordionDisabled: true,
      accessorialSent: false,
      carrierSent: false,
      lineItemSent: false,
      active: null,
    };
  },
  created() {
    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false;
    }, 500);
  },
  computed: {
    ...mapGetters("company", ["showProfileCompanysDepartment"]),
  },
  watch: {
    async showProfileCompanysDepartment() {
      this.isLoading = true;
      this.companyInfo = [...this.showProfileCompanysDepartment.data];
      if (this.companyInfo[0].department_name !== "LTL") {
        this.$router.push({ name: "Rules" });
      }
      const payload = {
        profile_id: this.companyInfo[0].profile_id,
        company_id: this.companyInfo[0].company_id,
        department_id: this.companyInfo[0].department_id,
        limit: 0,
      };
      const allInfo = await this.$store.dispatch("carriers/getAllCarriersProfiles", payload);
      const carrierList = [];
      // eslint-disable-next-line no-unused-expressions
      allInfo?.data?.data.forEach((element) => carrierList.push(element.carrier_name));
      const carriersData = new Set(carrierList);
      // this.carriers = allInfo.data.data;
      this.carriers = [...carriersData];
      this.isLoading = false;
    },
  },
  methods: {
    changeCarrier() {
      this.accordionDisabled = false;
      this.$refs.lineItemAccessorials.changeCarrierName(this.ruleCarrier);
      this.$refs.carrierRules.changeCarrierName(this.ruleCarrier);
    },
    async verifyForm() {
      this.saveSpinner = true;
      this.infoRules.params.profile_id = this.companyInfo[0].profile_id;
      this.infoRules.params.company_id = this.companyInfo[0].company_id;
      this.infoRules.params.department_id = this.companyInfo[0].department_id;
      if (!this.lineItemSent) {
        await this.verifyLineItem();
      }
      if (!this.carrierSent) {
        await this.verifyCarrierRule();
      }
      if (!this.accessorialSent) {
        await this.createAccessorials(this.infoRules);
      }
      setTimeout(() => { this.showAlert(); }, 1500);
    },
    async createAccessorials(infoRules) {
      const infoAccessorials = infoRules;
      infoAccessorials.body.carrier = this.ruleCarrier.toLowerCase();
      await this.$refs.accessorials.createAccessorials(infoAccessorials);
    },
    async verifyLineItem() {
      this.areLineItmesComplete = await this.$refs.lineItemAccessorials.verifyForm();
      if (this.areLineItmesComplete) {
        await this.$refs.lineItemAccessorials.createRule(this.infoRules);
      }
    },
    async verifyCarrierRule() {
      this.areCarriersComplete = await this.$refs.carrierRules.verifyForm();
      if (this.areCarriersComplete) {
        await this.$refs.carrierRules.createRule(this.infoRules);
      }
    },
    handleLineItem(response) {
      this.lineItemCreated = response;
    },
    handleAccessorials(response) {
      this.accessorialCreated = response;
    },
    handleCarrierRules(response) {
      this.carrierRulesCreated = response;
    },
    showAlert() {
      this.active = 3;
      this.carrierSent = this.carrierRulesCreated?.status === 201;
      this.lineItemSent = this.lineItemCreated?.status === 201;
      this.accessorialSent = this.accessorialCreated?.status === 204;
      if (
        !this.areCarriersComplete
        || !this.areLineItmesComplete
      ) {
        this.saveSpinner = false;
        return;
      }
      if (
        this.carrierSent
        && this.lineItemSent
        && this.accessorialSent
      ) {
        successMessage("The rule has been created");
        this.backToCards();
      } else {
        this.saveSpinner = false;
        warningMessage("Please try again!", "Some items were created successfully. Please try again with the enable items.");
      }
    },
    backToCards() {
      this.$router.push({ name: "Rules" });
    },
  },
};
</script>

<style lang="scss" scoped>
.rules-modal {
  padding: 2rem 2rem 0 6rem;
  &__content-tittle {
    font-weight: bold;
  }
  &__content-separator {
    width: 95%;
    margin: auto;
  }
  &__button {
    @include button-outlined;
    margin-top: 1rem;
    float: left;
    left: 2rem;
  }
  &__create-button {
    @include submit-button;
  }
  &__row {
    width: 100%;
    display: flex;
    padding: 1rem 2rem;
  }
  &__text {
    width: 80px;
    align-self: center;
    color: $color-input-text;
    margin: 0 0.5rem 0;
  }
  &__input {
    width: 20rem;
    @include input;
    // height: auto;
    &--input-valid {
      border: 1px solid #00b900;
      border-radius: 8px;
    }
    &--input-invalid {
      border: 1px solid #f44336;
      border-radius: 8px;
    }
  }
  &__dropdown {
    width: 65%;
    @include input;
  }
}
.overlay-button {
  display: flex;
  margin: auto;
  justify-content: center;
  padding: 2rem 1rem 1rem;
}
:deep(.p-dropdown-label) {
  padding-top: 0.3rem;
}
.scrollNavigation {
  @include button-outlined;
  position: fixed;
  right: 10px;
  bottom: 10px;
  background-color: #ffffff;
  border: 1px solid #00ff00;
  width: 37px;
  height: 37px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  text-decoration: none;
  &:hover {
    color: $color-submit-hover !important;
  }
}
.scrollNavigation.top {
  bottom: calc(10px + 40px);
}
:deep(.p-accordion) {
  padding: 0 2rem;
}
:deep(.p-accordion-header-link) {
  text-decoration: none;
}
:deep(.el-loading-spinner i) {
  color: #a5b1b7;
  scale: 5;
  animation: loading-rotate 1.5s linear infinite;
}
:deep(.el-loading-spinner .path) {
  stroke: #a5b1b7;
}
:deep(.el-loading-spinner .el-loading-text) {
  color: #a5b1b7;
  font-size: 16px;
  font-weight: 500;
}
:deep(.el-loading-spinner) {
  top: 60%;
}
</style>
