import Vue from 'vue';
import Vuex from 'vuex';
import user from './modules/user';
import accessorial from './modules/accessorial';
import company from './modules/company';
import department from './modules/department';
import cannedResponses from './modules/cannedResponses';
import carriers from './modules/carriers';
import profile from './modules/profile';
import rules from './modules/rules';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    cannedResponses,
    carriers,
    company,
    department,
    profile,
    accessorial,
    rules,
  },
});
