<template>
  <div class="create-rule">
    <Triggers @conditionalOptions="condicionalOptions" :editTrigger="ruleData"/>
    <Conditions :editConditions="editConditions" :conditionsData="optionsConditions" />
    <Actions :editActions="editActions"/>
  </div>
</template>

<script>
import Actions from "./Actions/Actions.vue";
import Conditions from "./Conditions/Conditions.vue";
import Triggers from "./Triggers/Triggers.vue";

export default {
  name: "CreateRule",
  props: {
    ruleData: {
      type: Object,
    },
  },
  components: {
    Conditions,
    Actions,
    Triggers,
  },
  data() {
    return {
      optionsConditions: null,
      editActions: [],
      editConditions: {},
      formulaElement: {
        label: "Formulation",
        options: [
          {
            value: "Formula",
            text: "Formula",
            description: "Se puede ingresar una fórmula. *Recuerda no eliminar el prefijo 'formula: '",
          },
        ],
      },
    };
  },
  watch: {
    ruleData() {
      this.editActions = this.ruleData.data.actions;
      this.editConditions = this.ruleData.data.conditions;
    },
  },
  methods: {
    condicionalOptions(data) {
      this.optionsConditions = data;
      if (this.optionsConditions) {
        this.optionsConditions.push(this.formulaElement);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.create-rule {
  padding: 1rem 2rem;
}
</style>
