<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  name: "App",
  components: {},
  created() {
    this.redirectionMain();
  },
  methods: {
    redirectionMain() {
      if (
        this.$route.path === "/"
        && localStorage.getItem("user-access-token")
        && this.$route.name
      ) {
        this.$router.push({ path: "/admin" });
      } else if (!localStorage.getItem("user-access-token")) {
        this.$router.push({ name: "Login" }).catch((err) => {
          if (
            err.name !== "NavigationDuplicated"
            && !err.message.includes("Avoided redundant navigation to current location")
          ) {
            // logError(err);
          }
        });
        localStorage.clear();
      }
    },
  },
};
</script>

<style lang="scss">
* {
  scroll-behavior: smooth;
}
body {
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
