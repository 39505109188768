import api from '../clients/axios';

const resource = 'api/v1';

export default {
  async getAllProfiles(params) {
    const response = await api.get(`${resource}/profiles`, { params });
    return response.data;
  },

  async getProfileById(profileId) {
    const response = await api.get(`${resource}/profiles/${profileId}`);
    return response.data;
  },

  createProfile(payload) {
    return api.post(`${resource}/profiles`, payload);
  },

  updateProfile(payload) {
    return api.patch(`${resource}/profiles/${payload.profile_id}`, payload.data);
  },

  deleteProfile(params) {
    return api.delete(`${resource}/profiles/${params.profile_id}?confirmation_message=${params.confirmation_message}`);
  },

};
