const infoLTLRules = [
  {
    name: "STANDARD <CARRIER NAME> THRESHOLDS",
    profile_id: 1,
    status: "active",
    label: [
      "<CARRIER NAME>",
      "STANDARD RULES",
      "BLOCKING RULES",
      "TOTAL VOLUME",
      "MAX HEIGHT",
      "MAX LENGTH",
      "LINEAR FEET",
      "TOTAL HU COUNT",
      "TOTAL WEIGHT",
      "MAX WEIGHT PER PIECE",
      "MAX WIDTH",
    ],
    description: "These are the standard thresholds for the carrier <carrier name>",
    trigger: "business_rules",
    rule_weight: 1,
    data: {
      conditions: {
        any: [
          {
            name: "haul_total_weight",
            operator: "greater_than",
            value: 0,
          },
          {
            name: "haul_max_weight_per_piece",
            operator: "greater_than",
            value: 0,
          },
          {
            name: "haul_total_hu_count",
            operator: "greater_than",
            value: 0,
          },
          {
            name: "haul_max_length",
            operator: "greater_than",
            value: 0,
          },
          {
            name: "haul_max_width",
            operator: "greater_than",
            value: 0,
          },
          {
            name: "haul_max_height",
            operator: "greater_than",
            value: 0,
          },
          {
            name: "haul_linear_feet",
            operator: "greater_than",
            value: 0,
          },
          {
            name: "haul_total_volume",
            operator: "greater_than",
            value: 0,
          },
        ],
      },
      actions: [
        {
          name: "block_carrier_by_name",
          params: {
            carrier_name: "<carrier name>",
          },
        },
      ],
    },
  },
  {
    name: "LINE ITEM ACCESSORIALS <CARRIER NAME> THRESHOLDS",
    profile_id: 1,
    status: "active",
    label: [
      "<CARRIER NAME>",
      "LINE ITEM ACCESSORIALS",
      "BLOCKING RULES",
      "PACKAGING TYPE: ROLL(S)",
      "PACKAGING TYPE: TUBE(S)",
      "PACKAGING TYPE: BUNDLE(S)",
      "LIFTGATE",
    ],
    description: "These are the line item accessorials thresholds for the carrier <carrier name>",
    trigger: "business_rules",
    rule_weight: 1,
    data: {
      conditions: {
        any: [
          {
            all: [
              {
                name: "accessorial_fullnames",
                operator: "shares_at_least_one_element_with",
                value: ["liftgate delivery_delivery", "liftgate pick up_pick up"],
              },
              {
                any: [
                  {
                    all: [
                      {
                        any: [
                          {
                            name: "haul_max_length",
                            operator: "greater_than",
                            value: [0],
                          },
                          {
                            name: "haul_max_width",
                            operator: "greater_than",
                            value: [0],
                          },
                        ],
                      },
                      {
                        any: [
                          {
                            name: "haul_max_width",
                            operator: "greater_than",
                            value: [0],
                          },
                          {
                            name: "haul_max_length",
                            operator: "greater_than",
                            value: [0],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    name: "haul_max_height",
                    operator: "greater_than",
                    value: [0],
                  },
                  {
                    name: "haul_max_weight_per_piece",
                    operator: "greater_than",
                    value: [0],
                  },
                ],
              },
            ],
          },
          {
            all: [
              {
                name: "accessorial_fullnames",
                operator: "contains",
                value: ["packaging type: bundle(s)_general"],
              },
              {
                any: [
                  {
                    name: "haul_max_length",
                    operator: "greater_than",
                    value: [0],
                  },
                  {
                    name: "haul_max_width",
                    operator: "greater_than",
                    value: [0],
                  },
                  {
                    name: "haul_max_height",
                    operator: "greater_than",
                    value: [0],
                  },
                  {
                    name: "haul_total_hu_count",
                    operator: "greater_than",
                    value: [0],
                  },
                  {
                    name: "haul_max_weight_per_piece",
                    operator: "greater_than",
                    value: [0],
                  },
                ],
              },
            ],
          },
          {
            all: [
              {
                name: "accessorial_fullnames",
                operator: "contains",
                value: ["packaging type: roll(s)_general"],
              },
              {
                any: [
                  {
                    name: "haul_max_length",
                    operator: "greater_than",
                    value: [0],
                  },
                  {
                    name: "haul_max_width",
                    operator: "greater_than",
                    value: [0],
                  },
                  {
                    name: "haul_max_height",
                    operator: "greater_than",
                    value: [0],
                  },
                  {
                    name: "haul_total_hu_count",
                    operator: "greater_than",
                    value: [0],
                  },
                  {
                    name: "haul_max_weight_per_piece",
                    operator: "greater_than",
                    value: [0],
                  },
                ],
              },
            ],
          },
          {
            all: [
              {
                name: "accessorial_fullnames",
                operator: "contains",
                value: ["packaging type: tube(s)_general"],
              },
              {
                any: [
                  {
                    name: "haul_max_length",
                    operator: "greater_than",
                    value: [0],
                  },
                  {
                    name: "haul_max_width",
                    operator: "greater_than",
                    value: [0],
                  },
                  {
                    name: "haul_max_height",
                    operator: "greater_than",
                    value: [0],
                  },
                  {
                    name: "haul_total_hu_count",
                    operator: "greater_than",
                    value: [0],
                  },
                  {
                    name: "haul_max_weight_per_piece",
                    operator: "greater_than",
                    value: [0],
                  },
                ],
              },
            ],
          },
        ],
      },
      actions: [
        {
          name: "block_carrier_by_name",
          params: {
            carrier_name: "<carrier name>",
          },
        },
      ],
    },
  },
];

module.exports = {
  infoLTLRules,
};
