// eslint-disable-next-line import/no-cycle
import { errorDynamicMessage } from "../utils/alertMessages";

export async function deleteArray(array, index) {
  array.splice(index, 1);
  return array;
}

export async function validateEmptyContainers(copyArray) {
  let validate = true;
  if (copyArray) {
    copyArray.forEach((item, index) => {
      const [nameMainArray] = Object.keys(copyArray[index]);
      if (copyArray[index].any || copyArray[index].all) {
        if (copyArray[index][nameMainArray]?.length === 0) {
          deleteArray(copyArray, index);
          validate = true;
          validateEmptyContainers(copyArray);
          return validate;
        }
        validateEmptyContainers(copyArray[index]?.all || copyArray[index]?.any);
      }
      validate = false;
      return validate;
    });
  }
  return validate;
}

let validate = [];
export async function cleanValidateArray() {
  validate = [];
}
export async function validateAllConditionContents(copyArray) {
  if (copyArray) {
    copyArray.forEach((item, index) => {
      if (copyArray[index].name) {
        if (copyArray[index]?.name
            && copyArray[index]?.operator
            && (copyArray[index]?.value?.length > 0)) {
          validate.push(true);
        } else {
          validate.push(false);
        }
      } else {
        validateAllConditionContents(copyArray[index].any || copyArray[index].all);
      }
    });
  }
  return validate;
}

export async function conditionValidationMessages(conditionsValidationArray) {
  if (conditionsValidationArray.every((element) => element === true)) {
    return true;
  }
  errorDynamicMessage("Please Verify!", "Please fill in all required information in the conditions");
  return false;
}
