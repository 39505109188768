<template>
  <div class="carriers-view">
    <div class="carriers-view__sidebar">
      <Sidebar />
    </div>
    <div class="carriers-view__content">
      <h1 class="carriers-view__content--tittle">Carriers</h1>
      <hr class="carriers-view__content--separator"/>
      <CarriersTable class="mt-5"/>
    </div>
  </div>
</template>

<script>
import CarriersTable from "./Components/CarriersTable.vue";
import Sidebar from "../../components/Sidebar.vue";

export default {
  name: "Carriers",
  components: {
    CarriersTable,
    Sidebar,
  },
  data() {
    return {
      userInfo: null,
      currentCompanyAndDepartment: null,
    };
  },
  computed: {},
  watch: {},
  created() {
    this.userInfo = JSON.parse(localStorage.getItem("user-info"));
    this.currentCompanyAndDepartment = JSON.parse(localStorage.getItem("company-data"));
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.carriers-view {
  display: flex;
  &__sidebar {
    width: 9%;
  }
  &__content {
    width: 91%;
    margin-top: 2rem;
    &--tittle {
      font-weight: bold;
    }
    &--separator {
      width: 95%;
      margin: auto;
    }
  }
}
@media (max-width: 900px) {
  .carriers-view {
    &__sidebar {
      width: 0%;
    }
    &__content {
      width: 98%;
      margin-top: 7rem;
    }
  }
}
</style>
