import api from '../clients/axios';

const resource = '/api/v1/companies';

export default {
  async getAllCannedResponses(params) {
    const response = await api.get(`${resource}/${params.company_id}/departments/${params.department_id}/canned-responses`, { params });
    return response;
  },
  async createCannedResponse(payload) {
    const { body } = payload;
    body.status = "active";
    const response = await api.post(`${resource}/${payload.params.company_id}/departments/${payload.params.department_id}/canned-responses`, payload.body);
    return response;
  },
  async getCannedResponseById(params) {
    const response = await api.get(`${resource}/${params.company_id}/departments/${params.department_id}/canned-responses/${params.canned_response_id}`);
    return response;
  },
  async deleteCannedResponse(params) {
    const response = await api.delete(`${resource}/${params.company_id}/departments/${params.department_id}/canned-responses/${params.canned_response_id}?confirmation_message=${params.confirmation_message}`);
    return response;
  },
  async updateCannedResponse(payload) {
    const response = await api.patch(`${resource}/${payload.params.company_id}/departments/${payload.params.department_id}/canned-responses/${payload.params.canned_response_id}`, payload.body);
    return response;
  },
};
