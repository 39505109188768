<template>
  <div>
    <template>
      <b-button
        class="rule-button"
        @click="openOptionsContainer"
        aria-controls="conditions-overlay-panel"
        ref="conditions-rules-button-block"
      >
        <i class="pi pi-plus-circle"></i>
        <b> New condition</b>
      </b-button>
      <OverlayPanel
        id="conditions-overlay-panel"
        ref="conditions-overlay"
        :style="'width:' + rulesButtonWidth + 'px'"
      >
        <div :ref="'conditions-block-select' + blockNumber">
          <div class="search-conditions">
            <input
              ref="searchConditions"
              class="search-conditions__input"
              type="text"
              placeholder="Search..."
              v-model="searchConditions"
              :id="'search-conditions-input' + blockNumber"
            />
          </div>
          <div class="rule-options">
            <div v-for="(category, index) in conditionsOptions" :key="index">
              <p
                class="rule-options__category"
                :id="'conditions-block-select__category' + blockNumber"
              >
                <i :class="category.label"></i>{{ category.label }}
              </p>
              <div
                v-for="(item, index) in category.options"
                @click="optionsConditionalConstructor(item, category.label)"
                :key="index"
                :id="'conditions-block-select__option' + blockNumber"
                class="rule-options__option"
              >
                <input
                  type="radio"
                  class="rule-options__radio"
                  :id="item.value"
                  :name="item.value"
                />
                <label
                  v-tooltip="{ value: item.description, disabled: false }"
                  class="rule-options__label"
                  :id="'conditions-block-select__label' + blockNumber"
                  :for="item.text"
                  >{{ item.text }}</label
                >
              </div>
            </div>
          </div>
        </div>
      </OverlayPanel>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import OverlayPanel from "primevue/overlaypanel";

export default {
  components: {
    OverlayPanel,
  },
  props: {
    blockNumber: {
      type: Number,
    },
    conditionsData: {
      type: Array,
    },
  },
  data() {
    return {
      selectedCondition: "",
      selectedConditionId: null,
      selectedConditionContent: null,
      conditionsOptions: null,
      searchConditions: "",
      firstCondition: false,
      rulesButtonWidth: null,
      splittedConditionContent: [],
    };
  },
  computed: {
    ...mapGetters("rules", ["showRuleCompleteObject"]),
  },
  created() {
    this.conditionsOptions = this.conditionsData;
  },
  watch: {
    searchConditions(value) {
      if (value.length >= 3) {
        const newConditionsOptions = [];
        for (let i = 0; i < this.conditionsData.length; i += 1) {
          newConditionsOptions.push(JSON.parse(JSON.stringify(this.conditionsData[i])));
          newConditionsOptions[i].options = [];
          for (let j = 0; j < this.conditionsData[i].options.length; j += 1) {
            if (this.filterConditionOption(value, this.conditionsData[i].options[j])) {
              newConditionsOptions[i].options.push(
                this.filterConditionOption(
                  value, this.filterConditionOption(value, this.conditionsData[i].options[j]),
                ),
              );
            }
          }
        }
        this.conditionsOptions = newConditionsOptions;
      } else {
        this.conditionsOptions = this.conditionsData;
      }
    },
  },
  methods: {
    // eslint-disable-next-line consistent-return
    filterConditionOption(value, option) {
      if (option.text.toLowerCase().includes(value.toLowerCase())) {
        return option;
      }
    },
    openOptionsContainer(event) {
      this.$refs["conditions-overlay"].toggle(event);
    },
    optionsConditionalConstructor(dataItem, category) {
      const ruleData = this.showRuleCompleteObject;
      this.selectedCondition = ruleData?.variables?.filter(
        (condition) => condition.label === dataItem.text && condition.category === category,
      );
      const dataEmit = {
        selectedCondition: this.selectedCondition,
        index: this.blockNumber,
      };
      this.$emit("pushNewConditionBlock", dataEmit);
    },
  },
};
</script>

<style lang="scss" scoped>
.rule-button {
  @include option-rules-button;
  margin: 10px 0px;
  width: auto;
  font-size: 14px;
  &:active {
    border: 3px solid $color-primary-button !important;
    color: $color-primary-button !important;
    border-style: dashed dotted !important;
    background: none !important;
    background-color: transparent !important;
  }
  &:focus {
    box-shadow: none !important;
  }
}

.rule-options {
  @include list-of-rules;
}

.conditions-icon {
  font-size: 18px;
  margin-right: 7px;
}

.search-conditions {
  &__input {
    @include list-of-rules-input;
  }
}

.and-operator {
  margin-top: 3px;
  margin-bottom: 4px;
  text-align: center;
  font-weight: bold;
}
</style>
