<template>
  <div class="carrier">
    <div class="carrier__card">
      <h3>Carrier</h3>
      <b-alert show variant="warning"
        ><b style="color: red">* </b> Please, fill out at least one carrier</b-alert
      >
      <div class="carrier__action-carriers">
        <b-button variant="info" class="carrier__button" @click="addRow()"
          >Add new carrier</b-button
        >
        <b-button
          :disabled="carriers.length === 1"
          variant="info"
          class="carrier__button"
          @click="deleteRow()"
          >Delete Carrier</b-button
        >
      </div>
      <div v-for="(carrierObject, i) in carriers" :key="i">
        <div class="carrier__container-input">
          <Dropdown
            v-model="carrierSelected[i]"
            :options="allCarriersData"
            optionLabel="carrier_name"
            dataKey="id"
            :filter="true"
            @change="setCarrierOption(i, carrierSelected[i])"
            placeholder="carrier *"
            class="carrier__input"
            :class="carriers[i].carrier ? 'carrier__input--valid' : 'p-invalid'"
            />
          <Dropdown
            v-model="scacSelected[i]"
            :options="allCarriersData"
            optionLabel="carrier_scac"
            :filter="true"
            @change="setScacOption(i, scacSelected[i])"
            placeholder="scac *"
            class="carrier__input"
            :class="carriers[i].scac ? 'carrier__input--valid' : 'p-invalid'"
            />
          <InputText
            v-for="(value, j) in carrierObject"
            :key="j"
            v-model="carriers[i][j]"
            @change="sendCarrier()"
            v-show="
              j !== 'documents_required_for_dispatch' &&
              j !== 'automatic_dispatch' &&
              j !== 'notify_glt_ops' &&
              j !== 'connection' &&
              j !== 'scac' &&
              j !== 'carrier'
            "
            :placeholder="j.split('_').join(' ') + validateField(j)"
            :type="
              j == 'third_party_id' ||
              j == 'used_liability' ||
              j == 'transit_time' ||
              j == 'price' ||
              j == 'new_liability' ||
              j == 'pickup_performance' ||
              j == 'delivery_performance'
                ? 'number'
                : 'text'
            "
            class="carrier__input"
            :class="validateColorField(j, carriers[i][j])"
          />
          <b-form-select
            v-model="carriers[i].connection"
            :options="connection"
            @change="sendCarrier()"
            class="carrier__input"
          ></b-form-select>
        </div>
        <div class="carrier__container-checkbox">
          <b-form-checkbox
            v-for="(value, j) in carrierObject"
            :key="j"
            v-model="carriers[i][j]"
            @change="sendCarrier()"
            v-show="
              j === 'documents_required_for_dispatch' ||
              j === 'automatic_dispatch' ||
              j === 'notify_glt_ops'
            "
            class="carrier__checkbox"
          >
            {{ j.split("_").join(" ") }}
          </b-form-checkbox>
        </div>
        <hr />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Carrier",
  data() {
    return {
      carriers: [],
      carrierParameters: {
        service_class: "",
        rating: "",
        load_id: "",
        quote_number: "",
        quote_contract_id: "",
        mode_name: "",
        load_number: "",
        estimated_delivery: "",
        direct_interline: "",
        currency_code: "",
        expiration_date: "",
        carrier: "",
        scac: "",
        connection: "stored_rate",
        third_party_id: null,
        used_liability: null,
        transit_time: null,
        price: null,
        new_liability: null,
        pickup_performance: null,
        delivery_performance: null,
        documents_required_for_dispatch: false,
        automatic_dispatch: false,
        notify_glt_ops: false,
      },
      connection: ["stored_rate", "easy_post", "p44"],
      allCarriersData: [],
      scacSelected: [],
      carrierSelected: [],
      companyInfo: null,
    };
  },
  computed: {
    ...mapGetters("company", ["showProfileCompanysDepartment"]),
  },
  created() {
    this.carriers.push({ ...this.carrierParameters });
    this.getAllCarriers();
  },
  methods: {
    sendCarrier() {
      return this.$emit("input", this.carriers);
    },
    addRow() {
      this.carriers.push({ ...this.carrierParameters });
    },
    deleteRow() {
      if (this.carriers.length > 1) {
        this.carriers.pop();
      }
    },
    validateField(field) {
      if (field === "carrier" || field === "scac" || field === "price") {
        return " *";
      }
      return "";
    },
    validateColorField(field, fieldValue) {
      if (field === "carrier" || field === "scac" || field === "price") {
        if (fieldValue) {
          return "carrier__input--valid";
        }
        return "carrier__input-isrequired";
      }
      return "carrier__input";
    },
    async getAllCarriers() {
      this.companyInfo = [...this.showProfileCompanysDepartment.data];
      const payload = {
        profile_id: this.companyInfo[0].profile_id,
        company_id: this.companyInfo[0].company_id,
        department_id: this.companyInfo[0].department_id,
        limit: -2,
      };
      const carriers = await this.$store.dispatch("carriers/getAllCarriersProfiles", payload);
      this.allCarriersData = carriers?.data?.data;
    },
    setScacOption(index, scacSelected) {
      this.carrierSelected[index] = scacSelected;
      this.carriers[index].scac = scacSelected?.carrier_scac;
      this.carriers[index].carrier = scacSelected?.carrier_name;
    },
    setCarrierOption(index, carrierSelected) {
      this.scacSelected[index] = carrierSelected;
      this.carriers[index].scac = carrierSelected?.carrier_scac;
      this.carriers[index].carrier = carrierSelected?.carrier_name;
    },
  },
};
</script>

<style lang="scss" scoped>
.carrier {
  width: 100% !important;
  @include card;
  &__action-carriers {
    display: flex;
    justify-content: start;
    margin-bottom: 1.5rem;
  }
  &__button {
    margin-right: 0.5rem;
  }
  &__container-input {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
  }
  &__input {
    width: 80% !important;
    margin: 5px !important;
    &--valid {
      border: 1px solid #00b900;
    }
  }
  &__input-isrequired {
    &::placeholder {
      color: #ff000096;
      font-weight: 600;
    }
    &:placeholder-shown {
      border: 1px solid #f44336;
    }
  }
  &__container-checkbox {
    margin-top: 1rem;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
  }
  &__checkbox {
    display: flex;
    flex-direction: row;
    font-size: 14px;
  }
}
</style>
