<template>
  <div class="admin-view">
    <div class="admin-view__sidebar">
      <Sidebar />
    </div>
    <!-- SPINNER -->
    <div
      class="d-flex justify-content-center align-items-center spinner-container"
      v-if="isLoading"
    >
      <img src="@/assets/gif/spinner.gif" width="200" height="200" />
    </div>
    <!-- CARRIERS VIEW -->
    <div class="admin-view__content">
      <h1 class="admin-view__content__tittle">Administration Panel</h1>
      <hr class="admin-view__content__separator" />
      <TabView :activeIndex="0" @tab-click="getData($event)">
        <TabPanel header="Users">
          <!-- USERS TABLE -->
          <Table ref="usersTable" @openModal="openUserModal" type="User" :firstPage="tabCurrent"
          class="mt-5" />
        </TabPanel>
        <TabPanel header="Profiles" :disabled="userInfo.role !== 'global_admin'">
          <!-- PROFILE TABLE -->
          <Table ref="profilesTable" @openModal="openProfileModal" type="Profile"
          :firstPage="tabCurrent" class="mt-5" />
        </TabPanel>
        <TabPanel header="Companies" :disabled="userInfo.role !== 'global_admin'">
          <!-- COMPANY TABLE -->
          <Table ref="companiesTable" @openModal="openCompanyModal" type="Company"
          :firstPage="tabCurrent" class="mt-5" />
        </TabPanel>
        <TabPanel header="Departments">
          <!-- DEPARTMENTS TABLE -->
          <Table ref="departmentsTable" @openModal="openDepartmentModal" type="Department"
          :firstPage="tabCurrent" class="mt-5" />
        </TabPanel>
      </TabView>
    </div>
    <!-- USER MODAL -->
    <CreateUserModal ref="createUser" @getData="getData" />

    <!-- PROFILE MODAL -->
    <CreateProfileModal ref="createProfile" @getData="getData" />

    <!-- COMPANY MODAL -->
    <CreateCompanyModal ref="createCompany" @getData="getData" />

    <!-- DEPARTMENT MODAL -->
    <CreateDepartmentModal ref="createDepartment" @getData="getData" />
  </div>
</template>

<script>
// import NewCarrierForm from "@/components/Carriers/NewCarrierForm.vue";
import Table from "@/components/Table.vue";
import Sidebar from "../../components/Sidebar.vue";
import CreateUserModal from "./Components/CreateUserModal.vue";
import CreateProfileModal from "./Components/CreateProfileModal.vue";
import CreateCompanyModal from "./Components/CreateCompanyModal.vue";
import CreateDepartmentModal from "./Components/CreateDepartmentModal.vue";

export default {
  name: "Admin",
  components: {
    Table,
    Sidebar,
    CreateUserModal,
    CreateProfileModal,
    CreateCompanyModal,
    CreateDepartmentModal,
  },
  data() {
    return {
      isLoading: false,
      userInfo: null,
      tabCurrent: null,
    };
  },
  created() {
    this.userInfo = JSON.parse(localStorage.getItem("user-info"));
  },
  methods: {
    getData($event) {
      this.tabCurrent = $event.index;
      if ($event.index === 0) {
        this.$refs.usersTable.getUsers();
      } else if ($event.index === 1) {
        this.$refs.profilesTable.getprofiles();
      } else if ($event.index === 2) {
        this.$refs.companiesTable.getCompanies();
      } else if ($event.index === 3) {
        this.$refs.departmentsTable.getdepartments();
      }
    },
    openUserModal(option) {
      this.$refs.createUser.showModal(option);
    },
    openProfileModal(option) {
      this.$refs.createProfile.showModal(option);
    },
    openCompanyModal(option) {
      this.$refs.createCompany.showModal(option);
    },
    openDepartmentModal(option) {
      this.$refs.createDepartment.showModal(option);
    },
  },
};
</script>

<style lang="scss" scoped>
.admin-view {
  display: flex;
  &__sidebar {
    width: 9%;
  }
  &__content {
    width: 91%;
    margin-top: 2rem;
    &__tittle {
      font-weight: bold;
    }
    &__separator {
      width: 95%;
      margin: auto;
    }
  }
}
:deep(.p-tabview-nav-container) {
  width: 95%;
  margin-left: 2rem;
}
:deep(.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link) {
  border-color: #283747;
  color: #283747;
}
:deep(.p-tabview .p-tabview-nav li .p-tabview-nav-link) {
  text-decoration: none;
}
:deep(.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus) {
  box-shadow: none;
}
@media (max-width: 900px) {
  .admin-view {
    &__sidebar {
      width: 0%;
    }
    &__content {
      width: 98%;
      margin-top: 7rem;
    }
  }
}
</style>
