const editableTableItems = {
  freight_details: [
    {
      header: ["Freight Details"],
      table_content: [
        { key: "Origin", value: "", actions: "" },
        { key: "Destination", value: "", actions: "" },
        { key: "Number of Pieces", value: "", actions: "" },
        { key: "Total Weight (lb.)", value: "", actions: "" },
        { key: "Dimensions (inches)", value: "", actions: "" },
        { key: "Commodity", value: "", actions: "" },
      ],
    },
  ],
  rate_table: [
    {
      header: ["Rate"],
      table_content: [
        {
          key: "Linehaul Rate + FSC (included)",
          value: "$",
          actions: "",
        },
        {
          key: "Chassis",
          value: "$40 per day (2 days minimum)",
          actions: "",
        },
        {
          key: "Redelivery may apply if the container is not able to be returned same day once it is loaded/unloaded",
          value: "",
          actions: "",
        },
      ],
    },
  ],
  accessorial_table: [
    {
      header: ["Accessorials"],
      table_content: [
        {
          key: "Chassis Fee",
          note: "Charges are per day and two (2) days minimum will be billed. Customers that are exempt from chassis fees must have their contract number on their shipping's paperwork for the carrier to review.",
          value: "$40",
          actions: "",
        },
        {
          key: "Chassis Fee",
          note: "Charges are per day and two (2) days minimum will be billed. Customers that are exempt from chassis fees must have their contract number on their shipping's paperwork for the carrier to review.",
          value: "$40",
          actions: "",
        },
        {
          key: "Chassis Fee",
          note: "Charges are per day and two (2) days minimum will be billed. Customers that are exempt from chassis fees must have their contract number on their shipping's paperwork for the carrier to review.",
          value: "$40",
          actions: "",
        },
      ],
    },
  ],
  quote_information: [
    {
      header: ["Quote Information"],
      fields: ["mode", "equipment", "transit_time", "rate"],
      table_content: [
        {
          mode: "",
          equipment: "",
          transit_time: "Days",
          rate: "",
        },
      ],
    },
  ],
};

export default editableTableItems;
