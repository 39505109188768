<template>
  <div class="canned-responses">
    <div class="canned-responses__sidebar">
      <Sidebar />
    </div>
    <div class="canned-responses__content">
      <h1 class="canned-responses__content__tittle">Canned Responses</h1>
      <hr class="canned-responses__content__separator" />
      <div
        class="d-flex justify-content-center align-items-center spinner-container"
        v-if="isLoading"
      >
        <img src="@/assets/gif/spinner.gif" width="200" height="200" />
      </div>
      <Table
        v-if="!isLoading"
        :type="type"
        class="mt-5"
      />
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
import Table from "@/components/Table.vue";
import { mapGetters } from "vuex";

export default {
  name: "CannedResponses",
  components: {
    Sidebar,
    Table,
  },
  data() {
    return {
      currentCompanyDepartment: null,
      isLoading: false,
      type: 'CannedResponses',
    };
  },
  computed: {
    ...mapGetters("user", ["showCurrentCompanyDepartment"]),
  },
  watch: {
    async showCurrentCompanyDepartment() {
      if (this.currentCompanyDepartment) {
        this.currentCompanyDepartment = this.showCurrentCompanyDepartment;
      }
    },
  },
  async created() {
    this.currentCompanyDepartment = JSON.parse(localStorage.getItem("company-data"));
    this.currentUserInfo = JSON.parse(localStorage.getItem("user-info"));
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.canned-responses {
  display: flex;
  &__sidebar {
    width: 9%;
  }
  &__content {
    width: 91%;
    margin-top: 2rem;
    &__tittle {
      font-weight: bold;
    }
    &__separator {
      width: 95%;
      margin: auto;
    }
  }
}
:deep(.p-button.p-button-outlined:enabled:hover) {
  color: $color-submit-hover;
}
@media (max-width: 900px) {
  .canned-responses {
    &__sidebar {
      width: 0%;
    }
    &__content {
      width: 98%;
      margin-top: 7rem;
    }
  }
}
</style>
