<template>
  <div>
    <div class="rules-modal">
      <AssociateProfForm :entity="'rules'" :ruleEdition="true" :typeTemplateRule="'lineItemARule'"/>
      <hr class="rules-modal__info-separator" />
      <div class="rules-modal__row">
        <div class="rules-modal__column">
          <label class="rules-modal__text" for="name">Name</label>
          <InputText
            :key="renderEditor"
            class="rules-modal__input"
            :class="validField.name"
            id="name"
            type="text"
            v-model="rulesName"
            @change="validField.name = changeFieldsColor(rulesName, 'name')"
          />
        </div>
        <div class="rules-modal__column">
          <label class="rules-modal__text" for="description">Description</label>
          <InputText
            :key="renderEditor"
            class="rules-modal__input"
            :class="validField.description"
            id="description"
            type="text"
            v-model="rulesDescription"
            @change="
              validField.description = changeFieldsColor(
                rulesDescription,
                'description'
              )
            "
          />
        </div>
        <div class="rules-modal__column">
          <label class="rules-modal__text" for="description">Rule Importance</label>
          <InputText
            :key="renderEditor"
            class="rules-modal__input"
            :class="validField.weight"
            id="weight"
            type="number"
            v-model="rulesWeight"
            autocomplete="off"
            v-tooltip="{ value: ruleImportant, disabled: false }"
            @change="validField.weight = changeFieldsColor(rulesWeight, 'weight')"
          />
        </div>
        <div class="rules-modal__column">
          <label class="rules-modal__text">Status</label>
          <Dropdown
            class="rules-modal__input"
            v-model="ruleStatus"
            placeholder="Select status"
            :options="statusOptions"
            optionLabel="name"
            optionValue="label"
          />
        </div>
        <div class="rules-modal__line">
          <label class="rules-modal__line__text" for="label">Label</label>
          <b-form-tags
            :key="renderEditor"
            class="rules-modal__line__input"
            :class="validField.name"
            input-id="label"
            v-model="selectedLabel"
            @input="validField.label = changeFieldsColor(infoCarrierRules.label, 'label')"
          >
          </b-form-tags>
        </div>
      </div>
      <hr class="rules-modal__info-separator" />
      <div class="rules-modal__row-group">
        <div
          class="rules-modal__column-group"
          v-for="(condition, index) in conditionsInfo"
          :key="index"
        >
          <p>
            <b>{{ condition.tittle }}</b>
          </p>
          <div class="p-inputgroup" v-for="(option, i) in condition.options" :key="i">
            <span class="p-inputgroup-addon">
              {{ option.label }}
            </span>
            <span class="p-inputgroup-addon">
              {{ option.operator }}
            </span>
            <InputText
              type="number"
              placeholder="Value"
              :key="index + i"
              :id="index + i"
              v-model="conditionsValues[index][i]"
              @blur="changeConditionValue(index, i)"
              onkeydown="return (event.keyCode!=188);"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { errorDynamicMessage } from "@/utils/alertMessages";
import AssociateProfForm from "@/components/AssociateProfForm.vue";
import infoLTLRules from "@/utils/LTLRules";

export default {
  name: "LineItemAccessorials",
  components: {
    AssociateProfForm,
  },
  data() {
    return {
      infoRule: infoLTLRules.infoLTLRules[1],
      rulesName: null,
      rulesDescription: null,
      rulesWeight: null,
      ruleEdition: false,
      ruleStatus: "active",
      statusOptions: [
        { name: "Active", label: "active" },
        { name: "Inactive", label: "inactive" },
      ],
      selectedLabel: [],
      ruleImportant:
        "La importancia de la regla indica en que orden se ejecutará, es decir, las reglas con menor valor se ejecutarán primero y las de mayor valor de último. Por ejemplo, si la regla A, B Y C tienen rule important de 1, las reglas D Y E tienen rule important de 2, y la regla F tiene rule important de 3; el orden de ejecución será: A, B, C, D, E, F.",
      conditionsInfo: [],
      conditionsLabels: [],
      optionsTransformed: [],
      conditionsValues: [],
      renderEditor: 0,
      validField: {},
    };
  },
  created() {
    const ruleCopy = JSON.parse(JSON.stringify(this.infoRule));
    this.rulesWeight = this.infoRule.rule_weight;
    this.ruleStatus = this.infoRule.status;
    this.conditionsValues.length = ruleCopy.data.conditions.any.length;
    ruleCopy.data.conditions.any.forEach((condition, index) => {
      this.conditionsValues[index] = [];
      if (condition.all[0].value[0].includes("liftgate")) {
        this.conditionsInfo[index] = {
          tittle: "Liftgate Pick Up/Delivery",
          options: condition.all[1].any[0].all[0].any,
        };
        this.conditionsInfo[index].options.push(condition.all[1].any[1]);
        this.conditionsInfo[index].options.push(condition.all[1].any[2]);
      } else {
        this.conditionsInfo[index] = {
          tittle: this.transformTitle(condition.all[0].value[0]),
          options: condition.all[1].any,
        };
      }
      this.transformOptions(index);
    });
  },
  methods: {
    changeCarrierName(carrierName) {
      this.rulesName = this.infoRule.name.replace('<CARRIER NAME>', carrierName.toUpperCase());
      this.selectedLabel = this.infoRule.label;
      this.selectedLabel[0] = carrierName.toUpperCase();
      this.rulesDescription = this.infoRule.description.replace('<carrier name>', carrierName.toLowerCase());
      this.infoRule.data.actions[0].params.carrier_name = carrierName.toLowerCase();
      this.renderEditor += 1;
    },
    transformTitle(condition) {
      const separate = condition.split(": ");
      const name = separate[1].replaceAll("_", " ");
      return name[0].toUpperCase() + name.substring(1);
    },
    transformLabel(condition) {
      const name = condition.replaceAll("_", " ");
      return name[0].toUpperCase() + name.substring(1);
    },
    transformOptions(index) {
      this.conditionsInfo[index].options.forEach((option, i) => {
        this.conditionsValues[index].push(null);
        this.conditionsInfo[index].options[i].label = this.transformLabel(option.name);
        this.conditionsInfo[index].options[i].operator = this.transformLabel(option.operator);
      });
    },
    changeConditionValue(index, i) {
      const stringValue = this.conditionsValues[index][i];
      if (stringValue === "" || stringValue === null) {
        this.conditionsValues[index][i] = null;
      } else {
        this.conditionsValues[index][i] = parseFloat(stringValue);
      }
      if (this.infoRule.data.conditions.any[index].all[0].value[0].includes("liftgate")) {
        if (i === 0 || i === 1) {
          this.infoRule.data.conditions.any[index].all[1].any[0].all[0].any[i].value = [
            this.conditionsValues[index][i],
          ];
          this.infoRule.data.conditions.any[index].all[1].any[0].all[1].any[i].value = [
            this.conditionsValues[index][i],
          ];
        } else {
          this.infoRule.data.conditions.any[index].all[1].any[i - 1].value = [
            this.conditionsValues[index][i],
          ];
        }
      } else {
        this.infoRule.data.conditions.any[index].all[1].any[i].value = [
          this.conditionsValues[index][i],
        ];
      }
    },
    verifyForm() {
      let completeData = 0;
      this.conditionsValues.forEach((item) => {
        completeData += item.some((element) => element === null);
      });
      if (completeData === 0) {
        return true;
        // this.$emit("getAreLineItems", true);
        // this.createRule(completeInfoRules);
      }
      errorDynamicMessage("Oops...", "Please fill in all conditions' parameters in Line Items Accessorials");
      return false;
    },
    async createRule(infoRules) {
      const completeInfoRules = infoRules;
      completeInfoRules.body.name = this.rulesName;
      completeInfoRules.body.description = this.rulesDescription;
      completeInfoRules.body.label = this.selectedLabel;
      completeInfoRules.body.rule_weight = Number(this.rulesWeight);
      completeInfoRules.body.status = this.ruleStatus;
      this.$store.commit('rules/setActions', this.infoRule.data.actions);
      this.$store.commit("rules/setConditionSchema", this.infoRule.data.conditions);
      this.$store.commit("rules/setTrigger", this.infoRule.trigger);
      const response = await this.$store.dispatch("rules/createRule", completeInfoRules);
      this.$emit("handleLineItem", response);
    },
    changeFieldsColor(fields, type) {
      if (type !== "label" && fields) {
        this.renderEditor += 1;
        return "rules-modal__input--input-valid";
      }
      if (type === "label" && fields.length > 0) {
        this.renderEditor += 1;
        return "rules-modal__input--input-valid";
      }
      this.renderEditor += 1;
      return "p-invalid";
    },
  },
};
</script>

<style lang="scss" scoped>
.rules-modal {
  &__info-separator {
    width: 95%;
    margin: 1.5rem auto 1rem;
  }
  &__row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 2rem;
  }
  &__column {
    display: flex;
    padding-top: 0.5rem;
  }
  &__row-group {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  &__column-group {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    width: 48%;
    align-items: start;
  }
  &__text {
    width: 80px;
    align-self: center;
    color: $color-input-text;
    margin: 0 0.5rem 0;
  }
  &__input {
    width: 20rem;
    @include input;
    // height: auto;
    &--input-valid {
      border: 1px solid #00b900;
      border-radius: 8px;
    }
    &--input-invalid {
      border: 1px solid #f44336;
      border-radius: 8px;
    }
  }
  &__line {
    display: flex;
    margin-top: 0.8rem;
    &__text {
      width: 80px;
      color: $color-input-text;
      margin: 0 0.5rem 0;
    }
    &__input {
      width: 20rem;
      @include input;
      // height: auto;
      height: 6rem !important;
      overflow-y: auto;
      &--input-valid {
        border: 1px solid #00b900;
        border-radius: 8px;
      }
      &--input-invalid {
        border: 1px solid #f44336;
        border-radius: 8px;
      }
    }
  }
}
:deep(.associate-prof-form) {
  margin: 1rem 2rem 0;
  &__selection-associate {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    & hr {
      display: none;
    }
  }
  &__row {
    margin: 0;
    &--label {
      width: 80px;
      margin: 0 0.5rem 0;
    }
    &--input {
      width: 20rem;
      height: auto;
    }
  }
}
.overlay-button {
  display: flex;
  margin: auto;
  justify-content: center;
  padding: 2rem 1rem 1rem;
}
:deep(.p-multiselect-label) {
  padding-top: 0.3rem;
}
:deep(.p-dropdown-label) {
  padding-top: 0.3rem;
}
:deep(.p-chips-multiple-container) {
  @include input;
  width: 20rem;
  height: auto;
  margin: 0;
}
:deep(.p-chips-multiple-container) {
  overflow: auto;
}
:deep(.p-inputgroup) {
  padding-bottom: 0.5rem;
}
</style>
