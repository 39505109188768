<template>
  <div>
    <!-- MODAL -->
    <b-modal
      hide-footer
      class="rules-modal"
      ref="new-accessorial-modal"
      size="lg"
      :title="modalType + ' Accessorial'"
      scrollable
      @hide="hideModal"
    >
      <b-form class="rules-modal__form">
        <div class="rules-modal__row">
          <div class="rules-modal__column">
            <label class="rules-modal__column__text" for="name"
              >Name <b style="color: red">*</b></label
            >
            <InputText
              class="rules-modal__column__input"
              v-model="accessorialName"
              id="name"
              type="text"
              :class="validFields.name"
              :disabled="loggedUser.role !== 'global_admin' || modalType === 'Edit'"
              @change="validFields.name = changeFieldColors(accessorialName)"
            />
          </div>
          <div class="rules-modal__column-description">
            <label class="rules-modal__column__text" for="description">Description</label>
            <InputText
              v-model="description"
              class="rules-modal__column__input"
              id="description"
              type="text"
              :disabled="loggedUser.role !== 'global_admin'"
            />
          </div>
        </div>
        <!-- REVENOVA INFO -->
        <label class="rules-modal__subtittle">Revenova Information</label>
        <hr />
        <div class="rules-modal__row">
          <div class="rules-modal__column">
            <label class="rules-modal__column__text" for="name"
              >Type <b style="color: red">*</b></label
            >
            <Dropdown
              class="rules-modal__column__input"
              :class="validFields.type"
              v-model="selectedType"
              placeholder="Select accessorial type"
              :options="accessorialType"
              optionLabel="name"
              optionValue="code"
              :disabled="loggedUser.role !== 'global_admin' || modalType === 'Edit'"
              @change="validFields.type = changeFieldColors(selectedType)"
            />
          </div>
          <div class="rules-modal__column">
            <label class="rules-modal__column__text" for="description">Stop Number </label>
            <Dropdown
              class="rules-modal__column__input"
              v-model="stopNumber"
              placeholder="Select an option"
              :options="stopNumberOptions"
              optionLabel="text"
              optionValue="value"
              :disabled="loggedUser.role !== 'global_admin'"
            />
          </div>
        </div>
        <div class="rules-modal__row">
          <div class="rules-modal__column">
            <label class="rules-modal__column__text" for="description"
              >Reve Name <b v-if="isRequiered" style="color: red">*</b></label
            >
            <InputText
              class="rules-modal__column__input"
              :class="validFields.reveName"
              id="reve-name"
              type="string"
              v-model="reveName"
              @change="validateIfRequired()"
              :disabled="loggedUser.role !== 'global_admin' || modalType === 'Edit'"
            />
          </div>
          <div class="rules-modal__column">
            <label class="rules-modal__column__text" for="name"
              >Reve ID <b v-if="isRequiered" style="color: red">*</b></label
            >
            <InputText
              class="rules-modal__column__input"
              :class="validFields.reveId"
              v-model="reveId"
              id="reve-id"
              type="text"
              @change="validateIfRequired()"
              :disabled="loggedUser.role !== 'global_admin' || modalType === 'Edit'"
            />
          </div>
        </div>
        <div class="rules-modal__row">
          <div class="rules-modal__column">
            <label class="rules-modal__column__text" for="description"
              >P44_Name <b v-if="isRequieredP44" style="color: red">*</b></label
            >
            <InputText
              class="rules-modal__column__input"
              :class="validFields.p44Name"
              id="reve-name"
              type="string"
              v-model="p44Name"
              @change="validateIfRequired()"
              :disabled="loggedUser.role !== 'global_admin'"
            />
          </div>
          <div class="rules-modal__column">
            <label class="rules-modal__column__text" for="name"
              >P44_Code <b v-if="isRequieredP44" style="color: red">*</b></label
            >
            <InputText
              class="rules-modal__column__input"
              :class="validFields.p44Code"
              v-model="p44Code"
              id="reve-id"
              type="text"
              @change="validateIfRequired()"
              :disabled="loggedUser.role !== 'global_admin'"
            />
          </div>
        </div>
        <AssociateProfForm
          :entity="'accessorial'"
          :entityId="rowData?.accessorial_id || rowData?.id"
          :modalType="modalType"
          @associateProfile="getAssociateProfile"
          @profileList="getProfileList"
        />
        <b-overlay class="overlay-button" :show="isLoading" rounded opacity="0.6" spinner-small>
          <b-button
            @click="modalType === 'Edit' ? verifyUpdate() : createOrEditoAccessorial()"
            class="rules-modal__button"
            :disabled="!enableAction"
          >
            {{ optionButton }}
          </b-button>
        </b-overlay>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AssociateProfForm from "@/components/AssociateProfForm.vue";
import {
  warningMessage,
  successMessage,
  errorDynamicMessage,
  deleteMessage,
} from "../../../utils/alertMessages";
import { promiseAssociate, constructMessage } from "../../../mixins/associateProfile";

export default {
  name: "CreateAccessorial",
  props: {
    rowData: Object,
    modalType: String,
  },
  components: {
    AssociateProfForm,
  },
  data() {
    return {
      loggedUser: null,
      isLoading: false,
      selectedType: null,
      accessorialName: null,
      isRequiered: false,
      isRequieredP44: false,
      accessorialType: [
        { name: "Pickup", code: "pick up" },
        { name: "Delivery", code: "delivery" },
        { name: "General", code: "general" },
      ],
      optionButton: "Create Accessorial",
      description: null,
      stopNumber: null,
      companyInfo: null,
      associateProfile: false,
      profileId: null,
      reveId: null,
      reveName: null,
      p44Name: null,
      p44Code: null,
      accessorialId: null,
      validFields: {},
      enableAction: false,
      stopNumberOptions: [
        { value: null, text: "Select an option" },
        { value: "1", text: "Pickup (1)" },
        { value: "2", text: "Delivery (2)" },
      ],
      profileSelected: null,
    };
  },
  computed: {
    ...mapGetters("company", ["showProfileCompanysDepartment"]),
  },
  watch: {
    rowData(data) {
      if (data) {
        this.optionButton = "Edit Accessorial";
        this.accessorialName = data.accessorial_name || data.name;
        this.accessorialId = data.accessorial_id || data.id;
        this.stopNumber = (data.accessorial_reve_stop_number || data.reve_stop_number)?.toString();
        this.description = data.accessorial_description || data.description;
        this.reveName = data.accessorial_reve_name || data.reve_name;
        this.reveId = data.accessorial_reve_id || data.reve_id;
        this.p44Name = data.accessorial_p44_name || data.p44_name;
        this.p44Code = data.accessorial_p44_code || data.p44_code;
        this.selectedType = data.accessorial_type || data.type;
        this.validateIfRequired();
      } else {
        this.optionButton = "Create Accessorial";
      }
    },
    showProfileCompanysDepartment() {
      if (
        this.showProfileCompanysDepartment
        && this.showProfileCompanysDepartment.data.length > 0
      ) {
        [this.companyInfo] = this.showProfileCompanysDepartment.data;
        this.profileId = this.showProfileCompanysDepartment.data[0].profile_id;
      }
    },
  },
  created() {
    this.companyInfo = JSON.parse(localStorage.getItem("company-data"));
    this.loggedUser = JSON.parse(localStorage.getItem("user-info"));
    this.cleanColor();
  },
  methods: {
    show() {
      this.$refs["new-accessorial-modal"].show();
    },
    hideModal() {
      this.accessorialName = "";
      this.accessorialId = null;
      this.selectedType = "";
      this.stopNumber = null;
      this.description = "";
      this.reveName = "";
      this.reveId = "";
      this.p44Name = "";
      this.p44Code = "";
      this.isRequiered = false;
      this.isRequieredP44 = false;
      this.enableAction = false;
      this.isLoading = false;
      this.cleanColor();
      this.$emit("hideModal");
    },
    async verifyUpdate() {
      const warningResponse = await deleteMessage(
        'Are you sure you want to update the accessorial?',
        "You won't be able to revert this!",
      );
      if (warningResponse.isConfirmed) {
        this.createOrEditoAccessorial();
      }
    },
    async createOrEditoAccessorial() {
      this.isLoading = true;
      let payload = this.payloadConstructor();
      this.colorFields();
      this.enableAction = this.accValidation();
      if (this.enableAction) {
        let msg;
        let globalEdit;
        let responseAccessorial;
        if (this.optionButton.includes("Create")) {
          msg = "created";
          responseAccessorial = await this.createAccessorial(payload);
          this.accessorialId = responseAccessorial?.data?.id;
        } else {
          msg = "edited";
          payload = this.editAccValidation(payload);
          const data = {};
          data.body = payload;
          data.accessorial_id = this.accessorialId;
          if (this.loggedUser.role === "global_admin") {
            responseAccessorial = await this.$store.dispatch("accessorial/updateAccessorial", data);
            globalEdit = responseAccessorial?.status === 204;
          } else {
            globalEdit = true;
          }
        }
        if (
          (this.modalType === "Edit" && globalEdit)
          || (this.modalType === "Create" && responseAccessorial?.status === 201)
        ) {
          if (this.associateProfile) {
            await this.validateProfileList();
            this.isLoading = false;
          } else {
            this.$emit("getAccessorials");
            successMessage(`The accessorial has been ${msg}.`);
            this.isLoading = false;
            this.$refs["new-accessorial-modal"].hide();
          }
        }
      } else {
        warningMessage("Oops...", "Please complete the required fields.");
        this.isLoading = false;
      }
      this.isLoading = false;
    },
    async createAccessorial(payload) {
      const response = await this.$store.dispatch("accessorial/createAccessorial", payload);
      return response;
    },
    async createAccessorialByProfile(profile) {
      const params = {};
      params.accessorial_id = this.accessorialId;
      params.company_id = profile.company_id || this.companyInfo.chosenCompany.id;
      params.department_id = profile.department_id || this.companyInfo.chosenDepartment.id;
      params.profile_id = profile.profile_id;
      params.body = {};
      params.body.status = profile.status ? profile.status : "active";
      params.body.most_common = profile.most_common;
      params.body.bol_visibility = profile.bol_visibility;
      params.body.p44_enabled = profile.p44_enabled;
      params.body.charlie_availability = profile.charlie_availability;
      params.body.portal_agents_availability = profile.portal_agents_availability;
      params.body.portal_clients_availability = profile.portal_clients_availability;
      const response = await this.$store.dispatch("accessorial/createAccessorialByProfile", params);
      return response;
    },
    async validateProfileList() {
      const actionModal = this.modalType === "Edit" ? "updated" : "created";
      if (this.profileSelected.length > 0) {
        const response = await this.runCreateByProfiles();
        this.isLoading = false;
        if (response?.status === 201) {
          successMessage(`The accessorial has been ${actionModal} and associated with profile(s)`);
          this.$emit("getAccessorials");
          this.isLoading = false;
          this.$refs["new-accessorial-modal"].hide();
        } else {
          errorDynamicMessage(
            "Oops..",
            `The accessorial has been ${actionModal} but ${response?.msg}`,
          );
          this.$emit("getAccessorials");
        }
      } else {
        warningMessage("Oops..", "Please complete the profile selection.");
      }
    },
    async runCreateByProfiles() {
      const arrayResponses = [];
      this.profileSelected.forEach((profile) => {
        const response = this.createAccessorialByProfile(profile);
        arrayResponses.push(response);
      });
      const allResponse = await promiseAssociate(arrayResponses, this.profileSelected);
      const dataResponse = constructMessage(allResponse);
      return dataResponse;
    },
    payloadConstructor() {
      const payload = {};
      payload.name = this.accessorialName;
      payload.type = this.selectedType;
      payload.description = this.description;
      payload.reve_stop_number = this.stopNumber;
      payload.reve_id = this.reveId;
      payload.reve_name = this.reveName;
      payload.p44_name = this.p44Name;
      payload.p44_code = this.p44Code;
      return payload;
    },
    accValidation() {
      if (
        !this.accessorialName
        || !this.selectedType
        || (this.associateProfile && this.profileSelected?.length < 1)
      ) {
        return false;
      } if (this.isRequiered) {
        if (!this.reveId || !this.reveName) {
          return false;
        }
      } if (this.isRequieredP44) {
        if (!this.p44Name || !this.p44Code) {
          return false;
        }
      }
      return true;
    },
    editAccValidation(infoPayload) {
      const payload = { ...infoPayload };
      Object.entries(payload).forEach(([key, value]) => {
        if (value === null && key !== "reve_stop_number") {
          delete payload[key];
        }
      });
      return payload;
    },
    cleanColor() {
      this.validFields = {
        name: "",
        type: "",
        reveId: "",
        reveName: "",
        p44Name: "",
        p44Code: "",
      };
    },
    colorFields() {
      if (this.loggedUser.role === "global_admin") {
        this.validFields.name = this.changeFieldColors(this.accessorialName);
        this.validFields.type = this.changeFieldColors(this.selectedType);
        if (this.isRequiered) {
          this.validFields.reveId = this.changeFieldColors(this.reveId);
          this.validFields.reveName = this.changeFieldColors(this.reveName);
        } else {
          this.validFields.reveId = "";
          this.validFields.reveName = "";
        }
        if (this.isRequieredP44) {
          this.validFields.p44Name = this.changeFieldColors(this.p44Name);
          this.validFields.p44Code = this.changeFieldColors(this.p44Code);
        } else {
          this.validFields.p44Name = "";
          this.validFields.p44Code = "";
        }
      }
      this.enableAction = this.accValidation();
    },
    changeFieldColors(field) {
      this.enableAction = this.accValidation();
      if (this.loggedUser.role === "global_admin") {
        if (field) {
          this.renderModal += 1;
          return "rules-modal__column--input-valid";
        }
        this.renderModal += 1;
        return "p-invalid";
      }
      return null;
    },
    getAssociateProfile(value) {
      this.associateProfile = value;
      this.enableAction = this.accValidation();
    },
    getProfileList(value) {
      this.profileSelected = value;
      this.enableAction = this.accValidation();
    },
    validateIfRequired() {
      if (
        (this.reveName && this.reveId)
        || (this.reveName && !this.reveId)
        || (!this.reveName && this.reveId)) {
        this.isRequiered = true;
      } else {
        this.isRequiered = false;
      }
      if (
        (this.p44Name && this.p44Code)
        || (this.p44Name && !this.p44Code)
        || (!this.p44Name && this.p44Code)) {
        this.isRequieredP44 = true;
      } else {
        this.isRequieredP44 = false;
      }
      this.colorFields();
    },
  },
};
</script>

<style lang="scss" scoped>
.rules-modal {
  &__button {
    @include submit-button;
  }
  &__row {
    display: flex;
    justify-content: flex-start;
    padding: 1rem;
  }
  &__column {
    display: flex;
    flex-direction: column;
    width: 100%;
    &__text {
      color: $color-input-text;
      margin-left: 0.2rem;
    }
    &__input {
      @include input;
    }
    &--input-valid {
      border: 1px solid #00b900;
    }
  }
  &__column-description {
    display: flex;
    flex-direction: column;
    width: 100%;
    &__text {
      color: $color-input-text;
      margin-left: 0.2rem;
    }
    &__input {
      @include input;
    }
  }
  &__dropdown {
    width: 65%;
    @include input;
  }
  &__subtittle {
    color: $color-input-text;
    margin-top: 1.5rem;
  }
}
.overlay-button {
  display: flex;
  margin: auto;
  justify-content: center;
  padding: 2rem 1rem 1rem;
}
:deep(.p-dropdown-label) {
  padding-top: 0.3rem;
}
:deep(.p-multiselect-label) {
  padding-top: 0.3rem;
}
</style>
