<template>
  <div class="icon-tooltip-message">
    <i class="pi pi-info-circle" :id="targetId"></i>
    <b-tooltip :target="targetId" triggers="hover">
      Put the word ALL to add ALL states on this country
    </b-tooltip>
  </div>
</template>

<script>
export default {
  name: "IconTooltipMessage",
  props: {
    targetId: String,
  },
};
</script>

<style lang="scss" scoped></style>
