<template>
  <div class="companies-button-menu">
    <Button
      icon="pi pi-user"
      class="p-button-rounded button"
      @click="toggle"
      aria-haspopup="true"
      aria-controls="overlay_menu"
    />
    <TieredMenu id="overlay_menu" ref="menu" :model="items" :popup="true" />
  </div>
</template>

<script>
export default {
  name: "CompaniesButtonMenu",
  data() {
    return {
      companiesOptions: null,
      departmentsOptions: null,
      items: [
        {
          label: "Company",
          icon: "pi pi-arrow-circle-right",
        },
        {
          separator: true,
        },
        {
          label: "Departments",
          icon: "pi pi-arrow-circle-right",
        },
        {
          label: "Logout",
          icon: "pi pi-sign-out",
          command: () => this.logout(),
        },
      ],
    };
  },
  created() {
    const userInfo = JSON.parse(localStorage.getItem("user-info"));
    this.setCompaniesOptions(userInfo);
    let companyData = JSON.parse(localStorage.getItem("company-data"));
    if (!companyData) {
      companyData = {};
      localStorage.setItem("company-data", JSON.stringify(companyData));
      this.selectCompany(userInfo.company_info[0]);
      this.selectDeparment(userInfo.company_info[0].departments[0]);
      this.handleDepartmentItemsByLength(userInfo.company_info[0].departments);
      return;
    }
    if (companyData.chosenCompany) {
      this.selectCompany(companyData.chosenCompany);
    }
    if (companyData.chosenDepartment) {
      this.selectDeparment(companyData.chosenDepartment);
      this.handleDepartmentItemsByLength(companyData.chosenCompany.departments);
    }
  },
  methods: {
    setCompaniesOptions(userInfo) {
      this.companiesOptions = userInfo.company_info.map((company) => ({
        label: company.name,
        icon: "pi pi-arrow-circle-right",
        id: company.id,
        command: () => this.selectCompany(company),
      }));
      this.items[0].items = [...this.companiesOptions];
    },
    setDepartmentsOptions(departments) {
      this.departmentsOptions = departments.map((department) => ({
        label: department.name,
        icon: "pi pi-arrow-circle-right",
        id: department.id,
        command: () => this.selectDeparment(department),
      }));
      this.handleDepartmentItemsByLength(departments);
    },
    selectCompany(company) {
      this.items[0].label = company.name;
      if (this.companiesOptions.length === 1) {
        delete this.items[0].items;
      } else {
        this.items[0].items = this.companiesOptions.filter((item) => item.label !== company.name);
      }
      this.setCompanyInfoInStorage("chosenCompany", company);
      this.setDepartmentsOptions(company.departments);
    },
    selectDeparment(department) {
      this.items[2].label = department.name;
      this.items[2].items = this.departmentsOptions.filter(
        (item) => item.label !== department.name,
      );
      this.setCompanyInfoInStorage("chosenDepartment", department);
    },
    setCompanyInfoInStorage(key, value) {
      const companyData = JSON.parse(localStorage.getItem("company-data"));
      companyData[key] = value;
      localStorage.setItem("company-data", JSON.stringify(companyData));
      this.$store.commit('user/setCurrentCompanyDepartment', companyData);
    },
    handleDepartmentItemsByLength(departments) {
      this.items[2].visible = true;
      if (departments.length > 1) {
        this.items[2].items = [...this.departmentsOptions];
        const companyData = JSON.parse(localStorage.getItem("company-data"));
        if (companyData.chosenDepartment !== undefined) {
          this.selectDeparment(companyData.chosenDepartment);
        } else {
          this.selectDeparment(departments[0]);
        }
      } else if (departments.length === 1) {
        this.items[2].label = departments[0].name;
        this.items[2].icon = "pi pi-arrow-circle-right";
        delete this.items[2].items;
        this.setCompanyInfoInStorage("chosenDepartment", departments[0]);
      } else {
        this.items[2].visible = false;
        const companyData = JSON.parse(localStorage.getItem("company-data"));
        delete companyData.chosenDepartment;
        localStorage.setItem("company-data", JSON.stringify(companyData));
        this.$store.commit('user/setCurrentCompanyDepartment', companyData);
      }
    },
    toggle(event) {
      this.$refs.menu.toggle(event);
    },
    logout() {
      localStorage.clear();
      this.$router.push({ name: "Login" });
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  @include user-button;
}
</style>
