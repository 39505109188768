<template>
  <div v-if="tabsName.length > 0" :key="renderComponent">
    <div v-for="(element, index) in conditionSubArray" :key="index" v-show="!element.name">
      <Panel
        class="collapse-component"
        :header="`${element}`"
        :toggleable="true"
        :collapsed="showOpenCollapse"
      >
        <template #header>
          <div class="collapse-component__header">
            <span>{{ `${`${element?.any ? "OR" : "AND"} ${index}`}` }}</span>
            <div class="collapse-component__header-options">
              <NewCondition
                @pushNewConditionBlock="pushNewConditionBlock"
                :blockNumber="index"
                :conditionsData="conditionsData"/>
              <DropdownComponent
                :itemsList="itemsCreateSubArray"
                titleButton="New Container"
                iconButton="save2"
                :index="index"
                @selectOption="selectedOptionNewContainer"
                />
            <b-button
              @click="deleteArray(index)"
              size="sm"
              pill
              variant="primary"
              class="collapse-component__header-options--button"
            >
              <i class="pi pi-trash"></i>
            </b-button>
            </div>
          </div>
        </template>
        <CollapseComponent
          class="complex-conditions__tab"
          :conditionSubArray="element.any || element.all"
          :indexOfContainerArray="index"
          :originArray="originArray"
          :conditionsData="conditionsData"
          @renderAll="renderAll"
          :closeCollapse="false"
        />
        <div v-for="(item, secondIndex) in element.any || element.all"
            :key="secondIndex">
          <OptionContainer
            v-if="item.name"
            :conditionEditData="item"
            :blockNumber="secondIndex"
            :indexOfContainerArray="index"
            @deleteOptionContainer="deleteOptionContainer"
            @editCondition="editCondition"
          />
        </div>
      </Panel>
    </div>
  </div>
</template>

<script>
import { deleteMessage } from "@/utils/alertMessages";
import Panel from "primevue/panel";
import DropdownComponent from "./DropdownComponent.vue";
import OptionContainer from "../OptionContainer.vue";
import NewCondition from "../NewCondition.vue";

export default {
  name: "CollapseComponent",
  props: {
    conditionSubArray: Array,
    // This property receives the index of the container array,
    // receives the index of the parent array according to the current child array.
    indexOfContainerArray: Number,
    originArray: Object,
    conditionsData: {
      type: Array,
    },
    closeCollapse: {
      type: Boolean,
    },
  },
  components: {
    Panel,
    DropdownComponent,
    OptionContainer,
    NewCondition,
  },
  data() {
    return {
      showOpenCollapse: true,
      tabsName: [],
      itemsCreateSubArray: [
        { value: 'all', text: "[ ] AND Container" },
        { value: 'any', text: "[ ] OR Container" },
      ],
      renderComponent: 0,
      selectedCondition: null,
    };
  },
  computed: {},
  watch: {},
  created() {
    this.validateConditionsArray();
    this.showOpenCollapse = this.closeCollapse;
  },
  methods: {
    validateConditionsArray() {
      if (this.conditionSubArray?.length > 0) {
        this.conditionSubArray.forEach((element, index) => {
          if (!element.name) {
            const [nameTab] = Object.keys(element);
            this.tabsName.push({ name: `${nameTab} [${index}]`, value: index });
          }
        });
      }
    },
    toggle(event) {
      this.$refs.menu.toggle(event);
    },
    async deleteArray(index) {
      // This function deletes the array inside the current subArray,
      // the current index of this one is received as a property
      // when executing the deleteArray action.
      const warningResponse = await deleteMessage(
        "Are you sure you want to remove this condition container?",
        "Remember that you will not be able to revert it and all the conditions it contains will be erased.",
      );
      if (warningResponse.isConfirmed) {
        this.conditionSubArray.splice(index, 1);
        this.renderComponent += 1;
        this.$emit("renderAll");
        this.$store.commit("rules/setConditionSchema", this.originArray);
      }
    },
    selectedOptionNewContainer(value) {
      const array = this.conditionSubArray[value.index];
      const [nameCollapse] = (Object.keys(array));
      const addArray = { [`${value.command}`]: [] };
      array[nameCollapse].push(addArray);
      this.showOpenCollapse = false;
      this.renderComponent += 1;
      this.$store.commit("rules/setConditionSchema", this.originArray);
    },
    pushNewConditionBlock(value) {
      const array = this.conditionSubArray[value.index];
      const [nameCollapse] = (Object.keys(array));
      const addArray = {};
      addArray.name = value.selectedCondition[0].name;
      addArray.operator = null;
      addArray.value = null;
      array[nameCollapse].push(addArray);
      this.showOpenCollapse = false;
      this.renderComponent += 1;
      this.$store.commit("rules/setConditionSchema", this.originArray);
    },
    async deleteOptionContainer(deleteElement) {
      const array = this.conditionSubArray[deleteElement.containerIndex];
      const [nameCollapse] = (Object.keys(array));
      const warningResponse = await deleteMessage(
        "Are you sure you want to remove this condition?",
        "Remember that you will not be able to revert it.",
      );
      if (warningResponse.isConfirmed) {
        array[nameCollapse].splice(deleteElement.currentIndex, 1);
        this.renderComponent += 1;
        this.showOpenCollapse = false;
        this.$store.commit("rules/setConditionSchema", this.originArray);
      }
    },
    editCondition(editElement) {
      const array = this.conditionSubArray[editElement.containerIndex];
      const [nameCollapse] = (Object.keys(array));
      array[nameCollapse][editElement.currentIndex] = { ...editElement.objectCondition };
      this.$store.commit("rules/setConditionSchema", this.originArray);
    },
    renderAll() {
      this.renderComponent += 1;
    },
  },
};
</script>

<style scoped lang="scss">
.collapse-component {
  padding: 1rem 0.4rem;
  border: 2px solid rgba(128, 128, 128, 0.167);
  border-radius: 0.5rem;
  box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2),
    0 1px 8px 0 rgba(0, 0, 0, 0.12);
  margin: 10px 0;
  height: 100%;
  width: 100%;
  &__header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    span {
      font-weight: 700;
      color: #495057;
      font-size: 13px;
    }
  }
  &__header-options {
    display: flex;
    justify-content: space-around;
    align-items: center;
    &--button {
      background-color: transparent;
      color: #6c757d;
      padding: 0px;
      width: 26px;
      height: 25px;
      border-radius: 20px;
      border: none;
      &:hover, &:active, &:focus {
        border-radius: 20px;
        border: none !important;
        background-color: transparent !important;
        color: #6c757d !important;
      }
    }
  }
}

:deep(.p-panel .p-panel-content) {
  border: none;
}
:deep(.p-panel .p-panel-header) {
  background: none;
  border-radius: 5px;
}
</style>
