import Admin from "../../views/Admin/Index.vue";

const AdminRoute = [
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
  },
];

export default AdminRoute;
