import swal from "sweetalert2";
import router from "../router";

export function errorMessage() {
  swal.fire({
    title: "There is something wrong",
    text: "Please, try again.",
    icon: "error",
    showConfirmButton: true,
    confirmButtonColor: "#e53935",
  });
}

export function errorValidationMessage(text) {
  swal.fire({
    title: "Validation Error",
    text: `${text}`,
    icon: "error",
    showConfirmButton: true,
    confirmButtonColor: "#cb352a",
  });
}

export function warningMessage(title, text) {
  return swal.fire({
    title: `${title}`,
    text: `${text}`,
    icon: "warning",
    showConfirmButton: true,
    confirmButtonColor: "#cb352a",
  });
}

export async function deleteMessage(title, text) {
  return swal
    .fire({
      title: `${title}`,
      text: `${text}`,
      icon: "warning",
      showConfirmButton: true,
      confirmButtonColor: "#00b900",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      allowEnterKey: false,
      allowEscapeKey: false,
    })
    .then((willDelete) => willDelete);
}

export function successMessage(text) {
  swal.fire({
    icon: "success",
    title: `Success!`,
    text: `${text}`,
    showConfirmButton: false,
    timer: 1500,
  });
}

export function errorDynamicMessage(title, text) {
  swal.fire({
    title: `${title}`,
    html: `${text}`,
    icon: "error",
    showConfirmButton: true,
    confirmButtonColor: "#e53935",
  });
}

export function tokenExpired() {
  swal
    .fire({
      title: "The token has expired",
      text: "Please, login again",
      icon: "warning",
      showConfirmButton: true,
      confirmButtonColor: "#e53935",
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
    })
    .then((willDelete) => {
      localStorage.clear();
      router.push({ name: "Login" });
      return willDelete;
    });
}

export function infoMessageRules() {
  swal
    .fire({
      title: "Please Wait!",
      text: "We are working on it",
      icon: "info",
      showConfirmButton: true,
      confirmButtonColor: "#283747",
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
    }).then((willDelete) => {
      router.push({ name: "Rules" });
      return willDelete;
    });
}
