<template>
  <div class="associate-prof-form">
    <div class="associate-prof-form__row" v-if="loggedUser.role === 'global_admin'">
      <b-form-checkbox
        v-if="entity !== 'cannedResponse' && entity !== 'rules'"
        v-model="associateProfile"
        @change="setassociateProfile()"
      >
        Associate {{ entity }} with profile</b-form-checkbox
      >
    </div>
    <div class="associate-prof-form__selection-associate" v-if="associateProfile">
      <hr />
      <div class="associate-prof-form__row">
        <label class="associate-prof-form__row--label" for="carrier-input">Company</label>
        <Dropdown
          class="associate-prof-form__row--input"
          v-model="selectedCompany"
          :options="loggedUser.company_info"
          optionLabel="name"
          placeholder="Select Company"
          :disabled="loggedUser.role === 'department_admin' || ruleEdition"
        />
      </div>
      <div class="associate-prof-form__row">
        <label class="associate-prof-form__row--label" for="carrier-input">Department</label>
        <Dropdown
          class="associate-prof-form__row--input"
          v-model="selectedDepartment"
          :options="departmentOptions"
          optionLabel="name"
          placeholder="Select Department"
          :disabled="!departmentOptions || departmentOptions?.length === 0 || ruleEdition  ||
          (loggedUser.role === 'company_admin' &&
          currentCompanyDepartment.chosenDepartment.name === 'LTL')"
        />
      </div>
      <div
        class="associate-prof-form__row"
        v-if="profileACtive && entity !== 'accessorial' && entity !== 'carrier'"
      >
        <label class="associate-prof-form__row--label" for="carrier-input">Profile</label>
        <Dropdown
          class="associate-prof-form__row--input"
          v-model="profileACtive.profile_name"
          :placeholder="profileACtive.profile_name"
          disabled
        />
      </div>
      <div class="associate-prof-form__row"
        v-if="entity === 'accessorial' || entity === 'carrier'">
        <label class="associate-prof-form__row--label" for="profile-select">
          Associate with Profile(s)</label
        >
        <MultiSelect
          class="associate-prof-form__row--multiselect"
          @change="setProfiles()"
          v-model="profileSelected"
          :options="profilesOptions"
          optionLabel="profile_name"
          placeholder="Select one or more profiles"
          display="chip"
        />
      </div>
    </div>
    <div
      v-if="
        (entity === 'accessorial' || entity === 'carrier') &&
        associateProfile &&
        profileSelected?.length > 0
      "
    >
      <Accordion
        v-for="(index, element) in profileSelected"
        :key="element"
        :activeIndex="0"
      >
        <AccordionTab :header="profileSelected[element]?.profile_name">
          <div v-show="entity === 'carrier'">
            <div class="associate-prof-form__row">
              <label class="associate-prof-form__row--label" for="profile-select"
                >Default notes <b style="color: red">*</b></label
              >
              <b-form-textarea
                v-model="defaultNotes[element]"
                rows="5"
                size="sm"
                placeholder="Write a note..."
                @blur="setDefaultNotes(element)"
              ></b-form-textarea>
            </div>
            <div class="associate-prof-form__row">
              <b-form-checkbox
                v-if="entity !== 'cannedResponse' && entity !== 'rules'"
                v-model="isTsa[element]"
                @change="setIsTsa(element)"
              >
                Is TSA</b-form-checkbox
              >
            </div>
          </div>
          <div class="associate-prof-form__fields-section" v-if="entity === 'carrier'">
            <div class="associate-prof-form__container-contract-type">
              <label class="associate-prof-form__label" for="profile-select">
                Contract type <b style="color: red">*</b>
              </label>
              <Dropdown
                :key="renderComponent"
                class="associate-prof-form__row--input"
                v-model="selectedContract[element]"
                :options="contractType"
                placeholder="Select contract type"
                :disabled="profileSelected?.length < 1"
                @change="contractTypeSelected(element)"
                optionDisabled="volumetric weight"
              />
            </div>
            <div
              class="associate-prof-form__container-contract-type"
              v-if="selectedContract[element] === 'volumetric weight'"
            >
              <label class="associate-prof-form__label" for="profile-select">
                Dim factor <b style="color: red">*</b>
              </label>
              <InputNumber
                v-model="dimFactor[element]"
                mode="decimal"
                placeholder="Enter factor"
                :maxFractionDigits="3"
                @blur="setDim(element)"
              />
            </div>
          </div>
          <div class="associate-prof-form__field-container">
            <div class="associate-prof-form__active-container">
              <label class="associate-prof-form__label" for="check-button">
                Status <b style="color: red">*</b>
              </label>
              <b-form-checkbox
                v-model="statusSelected[element]"
                name="check-button"
                :key="renderComponent"
                @change="setStatus(element)"
                switch
              >
              </b-form-checkbox>
            </div>
            <div class="associate-prof-form__active-container" v-if="entity === 'accessorial'">
              <label class="associate-prof-form__label" for="check-button">
                Charlie <b style="color: red">*</b>
              </label>
              <b-form-checkbox
                v-model="charlieSelected[element]"
                name="check-button"
                :key="renderComponent"
                @change="setCharlie(element)"
                switch
              >
              </b-form-checkbox>
            </div>
            <div class="associate-prof-form__active-container" v-if="entity === 'accessorial'">
              <label class="associate-prof-form__label" for="check-button">
                P44 Enabled <b style="color: red">*</b>
              </label>
              <b-form-checkbox
                v-model="pEnabledSelected[element]"
                name="check-button"
                :key="renderComponent"
                @change="setPEnabled(element)"
                switch
              >
              </b-form-checkbox>
            </div>
            <div class="associate-prof-form__active-container" v-if="entity === 'accessorial'">
              <label class="associate-prof-form__label" for="check-button">
                Portal Agents <b style="color: red">*</b>
              </label>
              <b-form-checkbox
                v-model="portalAgentsSelected[element]"
                name="check-button"
                :key="renderComponent"
                @change="setPortalAgents(element)"
                switch
              >
              </b-form-checkbox>
            </div>
            <div class="associate-prof-form__active-container" v-if="entity === 'accessorial'">
              <label class="associate-prof-form__label" for="check-button">
                Most Common <b style="color: red">*</b>
              </label>
              <b-form-checkbox
                v-model="mostCommonSelected[element]"
                name="check-button"
                :key="renderComponent"
                @change="setMostCommon(element)"
                switch
              >
              </b-form-checkbox>
            </div>
            <div class="associate-prof-form__active-container" v-if="entity === 'accessorial'">
              <label class="associate-prof-form__label" for="check-button">
                BOL Visibility <b style="color: red">*</b>
              </label>
              <b-form-checkbox
                v-model="bolVisibility[element]"
                name="check-button"
                :key="renderComponent"
                @change="setBolVisibility(element)"
                switch
              >
              </b-form-checkbox>
            </div>
            <div class="associate-prof-form__active-container" v-if="entity === 'accessorial'">
              <label class="associate-prof-form__label" for="check-button">
                Portal Clients <b style="color: red">*</b>
              </label>
              <b-form-checkbox
                v-model="portalClientsSelected[element]"
                name="check-button"
                :key="renderComponent"
                @change="setPortalClients(element)"
                switch
              >
              </b-form-checkbox>
            </div>
          </div>
        </AccordionTab>
      </Accordion>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MultiSelect from "primevue/multiselect";
import Accordion from 'primevue/accordion';

export default {
  name: "AssociateProfForm",
  props: {
    entity: String,
    typeTemplateRule: String,
    ruleEdition: Boolean,
    modalType: String,
    entityId: Number,
    selectedCarrierConnection: String,
  },
  components: {
    MultiSelect,
    Accordion,
  },
  data() {
    return {
      loggedUser: null,
      associateProfile: false,
      selectedCompany: null,
      selectedDepartment: null,
      departmentOptions: null,
      profileACtive: null,
      currentCompanyDepartment: null,
      profileSelected: [],
      profilesOptions: [],
      contractType: ["nmfc", "density"],
      selectedContract: [],
      dimFactor: [],
      renderComponent: 0,
      statusSelected: [],
      charlieSelected: [],
      pEnabledSelected: [],
      portalAgentsSelected: [],
      mostCommonSelected: [],
      portalClientsSelected: [],
      defaultNotes: [],
      isTsa: [],
      bolVisibility: [],
    };
  },
  computed: {
    ...mapGetters("company", ["showProfileCompanysDepartment"]),
    ...mapGetters("user", ["showCurrentCompanyDepartment"]),
  },
  watch: {
    selectedCarrierConnection() {
      if (this.selectedCarrierConnection === 'stored_rate') {
        this.contractType = ["nmfc", "density", "volumetric weight"];
      } else {
        this.contractType = ["nmfc", "density"];
        this.profileSelected.forEach((element, index) => {
          this.dimFactor[index] = 0;
          this.setDim(index);
          this.selectedContract[index] = "density";
          this.contractTypeSelected(index);
          this.setDefaultNotes(index);
          this.setIsTsa(index);
        });
      }
    },
    showCurrentCompanyDepartment() {
      if (this.showCurrentCompanyDepartment) {
        this.currentCompanyDepartment = this.showCurrentCompanyDepartment;
        this.infoDropdowns();
      }
    },
    selectedCompany() {
      if (this.selectedCompany.departments) {
        this.departmentOptions = this.selectedCompany.departments;
        if (this.selectedCompany.id !== this.currentCompanyDepartment.chosenCompany.id) {
          [this.selectedDepartment] = this.departmentOptions;
        } else {
          this.getCurrentDepartment();
        }
      }
    },
    async selectedDepartment() {
      await this.getProfileACtive();
    },
    showProfileCompanysDepartment() {
      if (
        this.showProfileCompanysDepartment?.data
        && this.showProfileCompanysDepartment?.data?.length > 0
      ) {
        [this.profileACtive] = this.showProfileCompanysDepartment.data;
        this.updateProfilesSelected();
      }
    },
    associateProfile() {
      this.getEntityInfoById(0);
    },
    profileSelected() {
      this.profileSelected.forEach((element, index) => {
        this.selectedContract[index] = "density";
        this.getEntityInfoById(index);
      });
    },
  },
  created() {
    this.loggedUser = JSON.parse(localStorage.getItem("user-info"));
    this.currentCompanyDepartment = this.showCurrentCompanyDepartment;
    this.infoDropdowns();
    this.associateProfile = this.loggedUser.role !== "global_admin";
    if (this.entity === "cannedResponse" || this.entity === "rules") {
      this.associateProfile = true;
    }
    this.$emit("associateProfile", this.associateProfile);
    if (this.showProfileCompanysDepartment?.data) {
      [this.profileACtive] = this.showProfileCompanysDepartment.data;
    }
    if (this.selectedCarrierConnection === 'stored_rate') {
      this.contractType = ["nmfc", "density", "volumetric weight"];
    }
  },
  methods: {
    infoDropdowns() {
      const getCurrentCompany = this.loggedUser.company_info.filter(
        (company) => company.id === this.currentCompanyDepartment.chosenCompany.id,
      );
      [this.selectedCompany] = getCurrentCompany;
      this.departmentOptions = this.selectedCompany.departments;
      if (this.currentCompanyDepartment.chosenDepartment) {
        this.getCurrentDepartment();
      }
      if (this.showProfileCompanysDepartment) {
        [this.profileACtive] = this.showProfileCompanysDepartment.data;
      }
    },
    async getProfileACtive() {
      if (this.typeTemplateRule !== "lineItemARule") {
        const params = {
          company_id: this.selectedCompany.id,
          department_id: this.selectedDepartment.id,
        };
        const response = await this.$store.dispatch("company/getProfileCompanysDepartment", params);
        this.profileACtive = response.status === 200 ? response.data.data[0] : null;
      }
    },
    setassociateProfile() {
      this.$emit("associateProfile", this.associateProfile);
    },
    getCurrentDepartment() {
      const getCurrentDto = this.departmentOptions.filter(
        (department) => department.id === this.currentCompanyDepartment.chosenDepartment.id,
      );
      [this.selectedDepartment] = getCurrentDto;
    },
    updateProfilesSelected() {
      if (this.entity === "accessorial" || this.entity === "carrier") {
        if (
          !this.profilesOptions.some(
            (elem) => elem.profile_name === this.profileACtive.profile_name,
          )
        ) {
          this.profilesOptions.push(this.profileACtive);
          this.profileSelected.push(this.profileACtive);
        }
        this.$emit("profileList", this.profileSelected);
      }
    },
    setProfiles() {
      this.$emit("profileList", this.profileSelected);
      this.selectedContract = [];
    },
    contractTypeSelected(element) {
      this.profileSelected[element].contractType = this.selectedContract[element];
      if (this.selectedContract[element] !== 'volumetric weight') {
        this.profileSelected[element].dim_factor = 0;
      } else {
        this.profileSelected[element].dim_factor = this.dimFactor[element];
      }
      this.$emit("profileList", this.profileSelected);
    },
    setDefaultNotes(element) {
      this.profileSelected[element].default_notes = this.defaultNotes[element];
      this.$emit("profileList", this.profileSelected);
    },
    setIsTsa(element) {
      this.profileSelected[element].is_tsa = this.isTsa[element];
      this.$emit("profileList", this.profileSelected);
    },
    setDim(element) {
      this.profileSelected[element].dim_factor = this.dimFactor[element];
      this.$emit("profileList", this.profileSelected);
    },
    setStatus(element) {
      this.profileSelected[element].status = this.statusSelected[element] ? 'active' : 'inactive';
      this.$emit("profileList", this.profileSelected);
    },
    setCharlie(element) {
      this.profileSelected[element].charlie_availability = this.charlieSelected[element];
      this.$emit("profileList", this.profileSelected);
    },
    setPEnabled(element) {
      this.profileSelected[element].p44_enabled = this.pEnabledSelected[element];
      this.$emit("profileList", this.profileSelected);
    },
    setPortalAgents(element) {
      this.profileSelected[element].portal_agents_availability = this.portalAgentsSelected[element];
      this.$emit("profileList", this.profileSelected);
    },
    setMostCommon(element) {
      this.profileSelected[element].most_common = this.mostCommonSelected[element];
      this.$emit("profileList", this.profileSelected);
    },
    setBolVisibility(element) {
      this.profileSelected[element].bol_visibility = this.bolVisibility[element];
      this.$emit("profileList", this.profileSelected);
    },
    setPortalClients(element) {
      this.profileSelected[element].portal_clients_availability = this.portalClientsSelected[
        element
      ];
      this.$emit("profileList", this.profileSelected);
    },
    async getCarrierByProfByIdInfo(index) {
      if (this.profileSelected[index]) {
        const params = {
          profile_id: this.profileSelected[index]?.profile_id,
          company_id: this.profileSelected[index]?.company_id,
          department_id: this.profileSelected[index]?.department_id,
          carrier_id: this.entityId,
        };
        const response = await this.$store.dispatch("carriers/getAllCarriersProfiles", params);
        this.updateFields(index, response?.data?.data[0]);
      }
    },
    async getAccessorialByProfByIdInfo(index) {
      if (this.profileSelected[index]?.company_id) {
        const params = {};
        params.accessorial_id = this.entityId;
        params.company_id = this.profileSelected[index]?.company_id;
        params.department_id = this.profileSelected[index]?.department_id;
        params.profile_id = this.profileSelected[index]?.profile_id;
        const response = await this.$store.dispatch("accessorial/getAccessorialsByProfile", params);
        this.updateFields(index, response?.data[0]);
      }
    },
    async getEntityInfoById(index) {
      if (this.associateProfile) {
        if (this.modalType === "Edit" && this.entityId) {
          if (this.entity === "carrier") {
            await this.getCarrierByProfByIdInfo(index);
          } else {
            await this.getAccessorialByProfByIdInfo(index);
          }
        } else if (this.entity === "accessorial" || this.entity === "carrier") {
          this.statusSelected[index] = true;
          this.setStatus(index);
        }
      }
    },
    updateFields(index, response) {
      if (!response) {
        this.statusSelected[index] = true;
        this.profileSelected[index].status = "active";
      } else {
        this.statusSelected[index] = response?.status === "active" ? !!response?.status : false;
        this.profileSelected[index].status = response?.status;
      }
      if (this.entity === "carrier") {
        this.defaultNotes[index] = response?.default_notes !== null
          ? response?.default_notes
          : null;
        this.isTsa[index] = response?.is_tsa
          ? response?.is_tsa
          : false;
        this.selectedContract[index] = response?.contract_type
          ? response?.contract_type
          : "density";
        this.dimFactor[index] = response?.dim_factor
          ? response?.dim_factor
          : 0;
        this.profileSelected[index].contractType = response?.contract_type;
        this.profileSelected[index].dim_factor = response?.dim_factor;
        this.profileSelected[index].default_notes = response?.default_notes;
      } else {
        this.pEnabledSelected[index] = !!response?.p44_enabled;
        this.mostCommonSelected[index] = !!response?.most_common;
        this.charlieSelected[index] = !!response?.charlie_availability;
        this.portalAgentsSelected[index] = !!response?.portal_agents_availability;
        this.portalClientsSelected[index] = !!response?.portal_clients_availability;
        this.profileSelected[index].p44_enabled = !!response?.p44_enabled;
        this.profileSelected[index].most_common = !!response?.most_common;
        this.bolVisibility[index] = !!response?.bol_visibility;
        this.profileSelected[index].charlie_availability = !!response?.charlie_availability;
        this.profileSelected[
          index
        ].portal_agents_availability = !!response?.portal_agents_availability;
        this.profileSelected[
          index
        ].portal_clients_availability = !!response?.portal_clients_availability;
      }
      this.renderComponent += 1;
      this.$emit("profileList", this.profileSelected);
    },
  },
};
</script>

<style lang="scss" scoped>
.associate-prof-form {
  &__row {
    margin: 2rem 0.5rem;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    &--label {
      color: $color-input-text;
      margin-left: 0.2rem;
      margin-right: 0.6rem;
      text-align: start;
      width: 100%;
    }
    &--input {
      width: 100%;
      @include input;
    }
    &--multiselect {
      width: 100%;
      @include input;
      height: auto;
    }
  }
  &__fields-section {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  &__field-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  &__container-contract-type {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: space-between;
    width: 50%;
    padding-bottom: 2rem;
  }
  &__active-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: space-between;
    width: 80%;
    margin: 5px;
  }
  &__label {
    color: $color-input-text;
  }
}
:deep(.p-inputtext) {
  @include input;
  background-color: transparent !important;
}
:deep(.p-dropdown-label) {
  margin-top: -4px;
}
</style>
