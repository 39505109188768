import Login from "../../views/Login/Index.vue";

const LoginRoute = [
  {
    path: '/',
    name: 'Login',
    component: Login,
  },
];

export default LoginRoute;
