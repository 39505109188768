<template>
  <div class="when-rules">
    <h5 class="when-rules__title">When...</h5>
    <button
      v-if="triggerSelected.text === 'Trigger'"
      class="rule-button"
      aria-controls="triggers-overlay-panel"
      ref="trigger-rules-button"
      @click="openOptionsContainer"
    >
      <i class="pi pi-plus-circle"></i> <b>{{ triggerSelected.text }}</b>
    </button>
    <div v-else class="option-container">
      <label>{{ triggerSelected.text }}</label>
      <button class="option-container__delete-option" @click="deleteOption">
        <i class="pi pi-trash"></i>
      </button>
    </div>
    <OverlayPanel id="triggers-overlay-panel" ref="triggers-overlay">
      <div class="rule-options">
        <div
          v-for="(item, index) in triggerOptions"
          :key="index"
          class="rule-options__option"
          @click="selectTrigger(item)"
        >
          <label
            v-tooltip="{ value: item.description, disabled: false }"
            class="rule-options__label"
            :for="item.label"
            >{{ item.text }}</label
          >
        </div>
      </div>
    </OverlayPanel>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Triggers",
  props: {
    editTrigger: {
      type: Object,
    },
  },
  data() {
    return {
      infoCreateRules: {},
      triggerOptions: [],
      triggerSelected: { value: null, text: "Trigger" },
    };
  },
  computed: {
    ...mapGetters("rules", ["showRuleCompleteObject"]),
  },
  watch: {
    showRuleCompleteObject() {
      this.infoCreateRules = this.showRuleCompleteObject;
      this.triggerConstructor();
    },
    triggerSelected() {
      if (this.triggerSelected.value !== null) {
        const triggerValue = this.infoCreateRules.triggers[this.triggerSelected.value];
        const labelCondition = Object.keys(triggerValue.variables);
        const conditionContent = triggerValue.variables;
        this.conditionConstructor(labelCondition, conditionContent);
      }
    },
    editTrigger() {
      if (this.infoCreateRules?.triggers) {
        const trigger = {
          value: this.editTrigger?.trigger,
          text: this.infoCreateRules?.triggers[this.editTrigger?.trigger]?.label,
        };
        this.selectTrigger(trigger);
      }
    },
  },

  methods: {
    triggerConstructor() {
      const triggerOptions = { ...this.infoCreateRules.triggers };
      Object.entries(triggerOptions).forEach(([key, value]) => {
        this.triggerOptions.push({
          value: key,
          text: value.label,
          description: value.description,
        });
      });
    },
    selectTrigger(trigger) {
      this.triggerSelected = trigger;
      this.$store.commit("rules/setTrigger", trigger.value);
      this.$refs["triggers-overlay"].hide();
    },
    conditionConstructor(labelCondition, conditionContent) {
      this.conditionalOptions = [];
      const allVariables = this.infoCreateRules.variables;
      for (let i = 0; i < labelCondition.length; i += 1) {
        const conditionObject = { label: labelCondition[i], options: [] };
        for (let j = 0; j < conditionContent[labelCondition[i]].length; j += 1) {
          const label = conditionContent[labelCondition[i]][j];
          const category = labelCondition[i];
          const variable = allVariables.find((e) => (e.label === label && e.category === category));
          conditionObject.options.push({
            value: conditionContent[labelCondition[i]][j],
            text: conditionContent[labelCondition[i]][j],
            description: variable.description,
          });
        }
        this.conditionalOptions.push(conditionObject);
      }
      this.$emit("conditionalOptions", this.conditionalOptions);
    },
    openOptionsContainer(event) {
      this.$refs["triggers-overlay"].toggle(event);
    },
    deleteOption() {
      this.triggerSelected = { value: null, text: "Trigger" };
      this.$emit("conditionalOptions", null);
    },
  },
};
</script>

<style lang="scss" scoped>
.overlays {
  width: 60%;
}

.when-rules {
  margin-top: 20px;
  &__title {
    display: flex;
  }
}

.rule-button {
  @include option-rules-button;
}

.rule-options {
  @include list-of-rules;
}

.search-triggers {
  &__input {
    @include list-of-rules-input;
  }
}

.option-container {
  @include each-option-container;
}
</style>
