<template>
  <div>
    <!-- MODAL -->
    <b-modal
      hide-footer
      class="company-modal"
      ref="new-company-modal"
      size="lg"
      :title="modalTitle"
    >
      <div class="company-modal__row">
        <div class="company-modal__column">
          <label class="company-modal__column__text" for="name">
            Name <b style="color: red">*</b></label>
          <InputText
            class="company-modal__column__input"
            :class="errors.name"
            :key="renderInput"
            v-model="newCompany.name"
            @change="validateField(newCompany.name, 'name')"
            id="name"
            type="text"
          />
        </div>
        <div class="company-modal__column">
          <label class="company-modal__column__text" for="description">
            Description <b style="color: red">*</b></label>
          <InputText
            class="company-modal__column__input"
            :class="errors.description"
            :key="renderInput"
            v-model="newCompany.description"
            @change="validateField(newCompany.description, 'description')"
            id="description"
            type="text"
          />
        </div>
      </div>
      <b-overlay class="overlay-button" :show="isLoading" rounded opacity="0.6" spinner-small>
        <b-button
          v-if="modalTitle !== 'Edit Company'"
          @click="createCompany()"
          class="company-modal__button"
          :disabled="!canCreate"
        >
          Create Company
        </b-button>
        <b-button v-else
        @click="verifyUpdate()" class="company-modal__button" :disabled="!canUpdate">
          Save Changes
        </b-button>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import { successMessage, deleteMessage } from "@/utils/alertMessages";

export default {
  name: "CreateCompanyModal",
  data() {
    return {
      modalTitle: "",
      checked: false,
      canCreate: false,
      canUpdate: false,
      isLoading: false,
      newCompany: {
        name: "",
        description: "",
      },
      companyId: null,
      errors: {},
      renderInput: 0,
    };
  },
  watch: {
    newCompany: {
      handler() {
        this.validateForm();
      },
      deep: true,
    },
  },
  methods: {
    validateForm() {
      if (this.newCompany.name !== '' && this.newCompany.description !== '') {
        this.canCreate = true;
        this.canUpdate = true;
      } else {
        this.canCreate = false;
        this.canUpdate = false;
      }
    },
    validateField(value, input) {
      this.errors[input] = !value ? "error" : "input-valid";
      this.renderInput += 1;
    },
    cleanColor() {
      this.errors.name = '';
      this.errors.description = '';
    },
    async showModal(option) {
      this.cleanColor();
      if (option) {
        this.companyId = option.id;
        this.modalTitle = "Edit Company";
        this.newCompany = await this.$store.dispatch("company/getCompanyById", option.id);
        this.validateField(this.newCompany.name, 'name');
        this.validateField(this.newCompany.description, 'description');
      } else {
        this.modalTitle = "Add New Company";
        this.newCompany = {
          name: "",
          description: "",
        };
      }
      this.$refs["new-company-modal"].show();
    },
    async createCompany() {
      this.isLoading = true;
      const response = await this.$store.dispatch("company/createCompany", this.newCompany);
      this.isLoading = false;
      if (response.status === 200 || response.status === 201) {
        this.$refs["new-company-modal"].hide();
        successMessage(`The company has been created.`);
        this.isLoading = false;
        const event = {
          index: 2,
        };
        this.$emit("getData", event);
      }
    },
    async verifyUpdate() {
      const warningResponse = await deleteMessage(
        'Are you sure you want to update the company?',
        "You won't be able to revert this!",
      );
      if (warningResponse.isConfirmed) {
        this.updateCompany();
      }
    },
    async updateCompany() {
      this.isLoading = true;
      const params = {
        company_id: this.companyId,
        data: this.newCompany,
      };
      const response = await this.$store.dispatch("company/updateCompany", params);
      this.isLoading = false;
      if (response.status === 200 || response.status === 204) {
        this.$refs["new-company-modal"].hide();
        successMessage(`The company has been updated.`);
        this.isLoading = false;
        const event = {
          index: 2,
        };
        this.$emit("getData", event);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.company-modal {
  &__button {
    @include submit-button;
  }
  &__row {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
  }
  &__column {
    display: flex;
    flex-direction: column;
    width: 48%;
    &__text {
      color: $color-input-text;
      margin-left: 0.2rem;
    }
    &__input {
      @include input;
    }
  }
  &__description-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem;
  }
}
.overlay-button {
  display: flex;
  margin: auto;
  justify-content: center;
  padding: 2rem 1rem 1rem;
}
:deep(.p-dropdown-label) {
  padding-top: 0.3rem;
}
.error {
  border-color: $color-input-error;
}
.input-valid {
  border: 1px solid #00b900;
}
</style>
