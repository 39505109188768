import TestingLaboratory from "../../views/CreateHauls/Index.vue";

const TestingLaboratoryRoute = [
  {
    path: "/testing-laboratory",
    name: "TestingLaboratory",
    component: TestingLaboratory,
  },
];

export default TestingLaboratoryRoute;
