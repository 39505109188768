export async function promiseAssociate(arrayResponses, profileSelected) {
  const dataResponse = [];
  const allResponse = await Promise.allSettled(arrayResponses)
    .then((responses) => responses)
    .catch((err) => err);
  allResponse.forEach((prof, index) => {
    dataResponse.push({
      profile: profileSelected[index],
      errorDetail: prof.value.response?.data?.detail || "",
      data: prof.value?.response || prof?.value?.data,
      statusResponse: prof.value?.response?.status || prof.value?.status,
    });
  });
  return dataResponse;
}

export function constructMessage(arrayResponses) {
  const someError = arrayResponses.some((element) => element.statusResponse !== 201);
  let msg = "the following profiles presented some problems in associating: <br>";
  let currentMsg = '';
  if (someError) {
    arrayResponses.forEach((element) => {
      if (element.statusResponse !== 201) {
        if (element.errorDetail.match("already exists")) {
          currentMsg += `- ${element.profile.profile_name} profile of  ${element.profile.company_name} company already exists.<br>`;
        } else {
          currentMsg += `- ${element.profile.profile_name} profile of  ${element.profile.company_name}, ${element.errorDetail}<br>`;
        }
      }
    });
    msg += currentMsg;
    return { status: 422, msg };
  }
  msg = "Has been associated with success";
  return { status: 201, msg };
}
