<template>
    <div class="ltl-accessorials">
      <div class="ltl-accessorials__row">
        <div class="ltl-accessorials__column p-inputgroup"
        v-for="(accessorial, index) in infoAccessorials" :key="index">
            <span class="p-inputgroup-addon">
                {{accessorial.name}}
            </span>
            <span class="p-inputgroup-addon">
                {{accessorial.type}}
            </span>
            <span class="p-inputgroup-addon">
                <Checkbox v-model="checkedAccessorials[index]" :binary="true"
                @change="changeEnable(index)"/>
            </span>
            <InputNumber placeholder="Costo" v-model="costValues[index]" :minFractionDigits="1"
            :disabled="!checkedAccessorials[index]" @input="changeCost(index)" />
            <Dropdown v-if="checkedAccessorials[index]"
                v-model="serviceSelected[index]"
                placeholder="Select status"
                :options="servicesOptions"
                optionLabel="name"
                optionValue="label"
                @change="changeService(index)"
            />
        </div>
      </div>
    </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle

export default {
  name: "Accessorials",
  components: {},
  data() {
    return {
      infoAccessorials: [],
      renderEditor: 0,
      checkFields: false,
      selectedLabel: [],
      checkedAccessorials: [],
      costValues: [],
      serviceSelected: [],
      completeAccessorials: [],
      servicesOptions: [{ name: 'Guaranteed', label: 'guaranteed' }, { name: 'Standard', label: 'standard' }, { name: 'Any', label: 'any' }],
    };
  },
  async created() {
    const params = {
      limit: 0,
    };
    const allInfo = await this.$store.dispatch("accessorial/getAllAccessorials", params);
    this.infoAccessorials = allInfo.data;
    this.createObject();
  },
  methods: {
    createObject() {
      this.infoAccessorials.forEach((accessorial) => {
        const obj = {
          name: accessorial.name,
          type: accessorial.type,
          enable: false,
          fee: 0,
          service_class: null,
        };
        this.completeAccessorials.push(obj);
      });
    },
    changeEnable(index) {
      this.completeAccessorials[index].enable = this.checkedAccessorials[index];
      if (this.checkedAccessorials[index] === false) {
        this.costValues[index] = null;
        this.completeAccessorials[index].fee = 0;
        this.completeAccessorials[index].service_class = null;
      }
    },
    changeCost(index) {
      this.completeAccessorials[index].fee = this.costValues[index];
    },
    changeService(index) {
      this.completeAccessorials[index].service_class = this.serviceSelected[index];
    },
    async createAccessorials(infoRules) {
      const payload = infoRules;
      payload.body.accessorials = this.completeAccessorials;
      const response = await this.$store.dispatch("rules/createAccessorialsRule", infoRules);
      this.$emit("handleAccessorials", response);
    },
  },
};
</script>

<style lang="scss" scoped>
.ltl-accessorials {
  &__row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  &__column {
    display: flex;
    width: 48%;
    padding-top: 1rem;
  }
}
</style>
