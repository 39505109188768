import Repository from "../../services/repositories/RepositoryFactory";
import {
  errorMessage,
  errorValidationMessage,
  tokenExpired,
  warningMessage,
} from "../../utils/alertMessages";

const RulesRepository = Repository.service("rulesAdmin");

export default {
  namespaced: true,
  state: {
    conditions: {},
    actions: [],
    booleanActions: [],
    trigger: "",
    valueConditions: [],
    ruleCompleteObject: {},
    formulaChecked: {},
    allFormulasChecked: true,
  },
  getters: {
    showActions(state) {
      return state.actions;
    },
    showConditionsSchema(state) {
      return state.conditions;
    },
    showTrigger(state) {
      return state.trigger;
    },
    showValueConditions(state) {
      return state.valueConditions;
    },
    showBooleanActions(state) {
      return state.booleanActions;
    },
    showRuleCompleteObject(state) {
      return state.ruleCompleteObject;
    },
    showAllFormulasChecked(state) {
      return state.allFormulasChecked;
    },
  },
  mutations: {
    setConditionSchema(state, conditionSchema) {
      state.conditions = { ...conditionSchema };
    },
    setResetValues(state) {
      state.conditions = {};
      state.valueConditions = [];
      state.actions = [];
    },
    setEditConditionSchema(state, editCondition) {
      state.conditions.all.forEach((element, indexAnd) => {
        // eslint-disable-next-line no-prototype-builtins
        if (element.hasOwnProperty("any")) {
          element.any.forEach((orElement, indexOr) => {
            if (orElement.index === editCondition.conditionObject.index) {
              state.conditions.all[indexAnd].any[indexOr] = editCondition.conditionObject;
            }
          });
        } else if (element.index === editCondition.conditionObject.index) {
          state.conditions.all[indexAnd] = editCondition.conditionObject;
        }
      });
    },
    setDeleteCondition(state, indexCondition) {
      state.conditions.all.forEach((element, indexAnd) => {
        // eslint-disable-next-line no-prototype-builtins
        if (element.hasOwnProperty("any")) {
          element.any.forEach((orElement, indexOr) => {
            if (orElement.index === indexCondition) {
              state.conditions.all[indexAnd].any.splice(indexOr, 1);
              if (state.conditions.all[indexAnd].any.length === 0) {
                state.conditions.all.splice([indexAnd], 1);
              }
            }
          });
        } else if (element.index === indexCondition) {
          state.conditions.all.splice(indexAnd, 1);
        }
      });
    },
    setActions(state, newArrayActions) {
      state.actions = newArrayActions;
    },
    setBooleanActions(state, newArrayBooleanActions) {
      state.booleanActions = newArrayBooleanActions;
    },
    setTrigger(state, newTrigger) {
      state.trigger = newTrigger;
    },
    setValueConditions(state, newValueConditions) {
      state.valueConditions = newValueConditions;
    },
    setRuleCompleteObject(state, newRuleCompleteObject) {
      state.ruleCompleteObject = newRuleCompleteObject;
      const element = {
        name: "formula:",
        label: "Formula",
        field_type: "numeric",
        options: [],
        category: "Formulation",
        source: null,
        options_type: null,
        description: "Se puede ingresar una fórmula. *Recuerda no eliminar el prefijo 'formula: '",
      };
      state.ruleCompleteObject.variables.push(element);
    },
    setFormulasChecked(state, newFormulasChecked) {
      if (newFormulasChecked) {
        state.formulaChecked[newFormulasChecked.index] = newFormulasChecked.status;
        state.allFormulasChecked = Object.values(state.formulaChecked)
          .every((formula) => formula === true);
      } else {
        state.formulaChecked = {};
      }
    },
  },
  actions: {
    async createHaul(_, payload) {
      try {
        const response = await RulesRepository.createHaul(payload);
        return response;
      } catch (error) {
        if (error.response.status === 422) {
          warningMessage("Sorry", "Rules could not be applied. Not enough values to perform the test");
        } else {
          errorMessage();
        }
        return error;
      }
    },
    async getAllRules(_, payload) {
      try {
        const response = await RulesRepository.getAllRules(payload);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
    async createRule({ state }, infoRules) {
      const payload = {
        payload: {
          name: infoRules.body.name,
          rule_weight: infoRules.body.rule_weight,
          label: infoRules.body.label,
          description: infoRules.body.description,
          status: infoRules.body.status,
          data: {
            conditions: state.conditions,
            actions: state.actions,
          },
          trigger: state.trigger,
        },
        params: infoRules.params,
      };
      try {
        const response = await RulesRepository.createRule(payload);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          if (error?.response?.data?.detail[0]?.msg || error?.response?.data?.detail) {
            errorValidationMessage(error?.response?.data?.detail[0]?.msg
              || error?.response?.data?.detail);
          } else {
            let message = "There's a problem with the condition(s): ";
            error.response.data.detail.forEach((item) => {
              message += item.name;
            });
            errorValidationMessage(message);
          }
        } else if (error.response.data.detail) {
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
    async getRuleById(_, payload) {
      try {
        const response = await RulesRepository.getRuleById(payload);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
    async deleteRule(_, accessorialId) {
      try {
        const response = await RulesRepository.deleteRule(accessorialId);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
    async updateRule({ state }, infoRules) {
      const payload = {
        payload: {
          name: infoRules.body.name,
          rule_weight: infoRules.body.rule_weight,
          label: infoRules.body.label,
          description: infoRules.body.description,
          status: infoRules.body.status,
          trigger: state.trigger,
          data: {
            conditions: state.conditions,
            actions: state.actions,
          },
        },
        params: infoRules.params,
      };
      try {
        const response = await RulesRepository.updateRule(payload);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          if (error?.response?.data?.detail[0]?.msg || error?.response?.data?.detail) {
            errorValidationMessage(error.response.data.detail[0].msg
              || error?.response?.data?.detail);
          } else {
            let message = "There's a problem with the condition(s): ";
            error.response.data.detail.forEach((item) => {
              message += item.name;
            });
            errorValidationMessage(message);
          }
        } else if (error.response.data.detail) {
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
    async checkFormula(_, payload) {
      try {
        const response = await RulesRepository.checkFormula(payload);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
    async exportRulesData(_, params) {
      try {
        const response = await RulesRepository.exportRulesData(params);
        return response.data;
      } catch (error) {
        return error;
      }
    },
    async createAccessorialsRule(_, infoRules) {
      const payload = {
        payload: {
          carrier: infoRules.body.carrier,
          accessorials: infoRules.body.accessorials,
        },
        params: infoRules.params,
      };
      try {
        const response = await RulesRepository.createAccessorialsRule(payload);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
  },
};
