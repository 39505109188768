<template>
  <div class="login">
    <div class="login__information">
      <div class="login__information__row">
        <h1>
          <b>We Are The</b>
        </h1>
        <img
          src="https://shipwithglt.com/wp-content/uploads/2018/09/DOS-FLECHAS.svg"
          width="15%"
          height="15%"
        />
      </div>
      <h1>
        <b>Experts You Need</b>
      </h1>
    </div>
    <div class="login__content">
      <div class="login__content__form">
        <img class="login__content__form--logo" src="../../assets/images/glt-logo.png" />
        <div class="login__content__form__submit">
          <div class="login__content__form__submit__input-group p-inputgroup">
            <span class="p-inputgroup-addon">
              <i class="pi pi-user"></i>
            </span>
            <InputText
              class="login__content__form__submit__input-group--input"
              v-model="username"
              placeholder="Username"
            />
          </div>
          <div class="login__content__form__submit__input-group p-inputgroup">
            <span class="p-inputgroup-addon">
              <i class="pi pi-lock"></i>
            </span>
            <InputText
              class="login__content__form__submit__input-group--input"
              v-model="password"
              v-on:keyup.enter="login()"
              placeholder="Password"
              type="password"
            />
          </div>
        </div>

        <div class="login__content__form__submit__options">
          <b-overlay
            :show="isLoading"
            rounded
            opacity="0.6"
            spinner-small
            class="d-inline-block"
            @hidden="onHidden"
          >
            <b-button
              ref="button-accept"
              :disabled="isLoading"
              class="login__content__form__submit__options--button"
              @click="login()"
              >Sign In</b-button
            >
          </b-overlay>
        </div>
        <p class="login__content__form--footer">© 2022 Developed by guane Enterprises</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
      isLoading: false,
    };
  },
  methods: {
    async login() {
      this.isLoading = true;
      const formData = new FormData();
      formData.append("username", this.username);
      formData.append("password", this.password);
      const response = await this.$store.dispatch("user/login", formData);
      if (response?.status === 200) {
        const decoded = this.$jwt.decode(response.data.access_token);
        localStorage.setItem('user-info', JSON.stringify(decoded));
        localStorage.setItem('user-access-token', response.data.access_token);
        this.$router.push({ path: '/admin' });
      }
      this.isLoading = false;
    },
    onHidden() {
      this.$refs["button-accept"].focus();
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  position: relative;
  min-height: $view-height;
  background-image: url("../../assets/images/login.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;

  &::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.425);
  }

  &__information {
    display: flex;
    position: fixed;
    flex-direction: column;
    color: $color-white;
    margin: 10% 0 0 6%;
    text-align: initial;
    &__row {
      display: flex;
    }
    h1 {
      font-size: 60px;
      margin: 0;
    }
  }
  &__content {
    display: flex;
    justify-content: flex-end;
    position: relative;

    &__form {
      width: 30%;
      height: $view-height;
      padding: 4rem 3rem 0rem;
      background-color: $color-background-darker;
      position: relative;
      &--logo {
        width: 50%;
      }

      &__submit {
        padding: 20px 0px;
        &__input-group {
          margin-bottom: 1rem;
          span {
            background-color: $color-submit-button;
            border-color: $color-submit-button;
            color: $color-black;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
          }
          &--input {
            border-top-right-radius: 8px !important;
            border-bottom-right-radius: 8px !important;
          }
        }
        &__options {
          display: flex;
          justify-content: flex-start;
          margin: $margin-most-used 0px;

          &--button {
            @include submit-button;
          }
        }
      }
      &--footer {
        position: absolute;
        bottom: 8px;
        left: 14%;
      }
    }
  }
}

@media (max-width: 1225px) {
  .login__content__form {
    width: 40%;
  }
}
@media (max-width: 960px) {
  .login {
    &__information {
      width: 55%;
      &__row {
        flex-wrap: wrap;
      }
      h1 {
        font-size: 4rem;
      }
      img {
        width: 6rem;
      }
    }
  }
}

@media (max-width: 750px) {
  .login {
    &__information {
      display: none;
    }
    &__content {
      justify-content: center;
      align-items: center;
      height: $view-height;
      &__form {
        width: 80%;
        height: auto;
        border-radius: 0.25rem;
        padding: 2rem 2rem;
      }
    }
  }
}
</style>
