<template>
  <div class="mode">
    <div class="mode__input-container">
      <label for="autocomplete">Mode id: </label>
      <InputText
        placeholder="Mode Id"
        @change="sendMode()"
        v-model="tmsMode.id"
        type="text"
      />
    </div>
    <div class="mode__input-container">
      <label for="autocomplete">Mode name: <b v-if="fieldRequired" style="color: red">*</b></label>
      <InputText
        @change="sendMode()"
        placeholder="Mode name"
        v-model="tmsMode.name"
        type="text"
        :class="fieldColor"
      />
    </div>
    <div class="mode__input-container">
      <label for="autocomplete">Mode code: </label>
      <InputText
        placeholder="Mode code"
        @change="sendMode()"
        v-model="tmsMode.tms_code"
        type="text"
      />
    </div>
  </div>
</template>

<script>
// import AutoComplete from "primevue/autocomplete";

export default {
  name: "Mode",
  components: {
    // AutoComplete,
  },
  data() {
    return {
      tmsMode: {
        id: null,
        name: null,
        tms_code: null,
      },
      fieldRequired: false,
      fieldColor: '',
    };
  },
  methods: {
    sendMode() {
      this.validateFieldColor();
      return this.$emit("input", this.tmsMode);
    },
    validateFieldColor() {
      if (this.tmsMode.id || this.tmsMode.name || this.tmsMode.tms_code) {
        this.fieldRequired = true;
      } else {
        this.fieldRequired = false;
      }
      if (this.fieldRequired) {
        if (this.tmsMode.name) {
          this.fieldColor = 'mode__input--valid';
        } else {
          this.fieldColor = 'mode__input-isrequired';
        }
      } else {
        this.fieldColor = '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mode {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
  margin: 1rem 0;
  &__input-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 5px;
  }
}
.mode {
  &__input--valid {
      border: 1px solid #00b900;
    }
  &__input-isrequired:placeholder-shown {
    border: 1px solid #ff000096;
  }
}
</style>
