<template>
  <div class="header-mobile">
    <b-navbar toggleable type="dark" variant="dark">
      <img class="img-fluid" src="@/assets/images/glt-logo.png" />
      <b-navbar-toggle target="navbar-toggle-collapse">
      </b-navbar-toggle>
      <b-collapse id="navbar-toggle-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item
            ><a class="the-sidebar-link" @click="changeRoute('/admin')">
              <span class="the-sidebar-link-icon"><i class="pi pi-user-edit"></i></span>
              <span class="the-sidebar-link-title">Admin</span>
            </a></b-nav-item
          >
          <b-nav-item
            ><a class="the-sidebar-link" @click="changeRoute('/carriers')">
              <span class="the-sidebar-link-icon"><b-icon icon="truck"></b-icon></span>
              <span class="the-sidebar-link-title">Carriers</span>
            </a></b-nav-item
          >
          <b-nav-item
            ><a class="the-sidebar-link" @click="changeRoute('/carrier-rules')">
              <span class="the-sidebar-link-icon"><b-icon icon="list-ul"></b-icon></span>
              <span class="the-sidebar-link-title">Rules</span>
            </a></b-nav-item
          >
          <b-nav-item
            ><a class="the-sidebar-link" @click="changeRoute('/accessorials')">
              <span class="the-sidebar-link-icon"><b-icon icon="list-ul"></b-icon></span>
              <span class="the-sidebar-link-title">Accessorials</span>
            </a></b-nav-item
          >
          <b-nav-item
            ><a class="the-sidebar-link" @click="changeRoute('/canned-responses')">
              <span class="the-sidebar-link-icon"><i class="pi pi-inbox"></i></span>
              <span class="the-sidebar-link-title">Canned Responses</span>
            </a></b-nav-item
          >
          <b-nav-item
            ><a class="the-sidebar-link" @click="changeRoute('/testing-laboratory')">
              <span class="the-sidebar-link-icon"><b-icon icon="plus-circle"></b-icon></span>
              <span class="the-sidebar-link-title">Testing Laboratory</span>
            </a></b-nav-item
          >
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>

export default {
  name: "HeaderMobile",

  data() {
    return {
      departments: [],
    };
  },
  methods: {
    changeRoute(route) {
      this.$router.push(route).catch((err) => {
        if (
          err.name !== "NavigationDuplicated"
          && !err.message.includes("Avoided redundant navigation to current location")
        ) {
          // logError(err);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  display: none;
}
@media (max-width: 900px) {
  .navbar {
    display: flex;
    width: 100vw;
    z-index: 1000;
  }
  .bg-dark {
    background: $color-background-darker !important;
  }
  .img-fluid {
    width: 70px;
  }
  .navbar-toggler {
    margin-right: 1rem;
  }
  .nav-link {
    text-align: start;
  }
  .the-sidebar-link {
    color: #fff;
  }
  .the-sidebar-link-icon {
    padding-right: 0.5rem;
  }
}
</style>
