<template>
  <div :state="sendPickupAndDelivery" class="pickup-and-delivery">
    <Pickup :sendAccessorialsPickup="accessorialsPickup" v-model="pickupAndDelivery.from" />
    <Delivery :sendAccessorialsDelivery="accessorialsDelivery" v-model="pickupAndDelivery.to" />
  </div>
</template>

<script>
import Delivery from "./Delivery.vue";
import Pickup from "./Pickup.vue";

export default {
  name: "PickupAndDelivery",
  props: {
    sendAccessorials: Array,
  },
  components: {
    Pickup,
    Delivery,
  },
  data() {
    return {
      pickupAndDelivery: {
        from: {
          zip_from: null,
          city_from: null,
          state_from: null,
        },
        to: {
          zip_to: null,
          city_to: null,
          state_to: null,
        },
      },
      accessorialsPickup: [],
      accessorialsDelivery: [],
    };
  },
  created() {
    this.accessorialsPickupAndDelivery();
  },
  computed: {
    sendPickupAndDelivery() {
      return this.$emit("input", this.pickupAndDelivery);
    },
  },
  methods: {
    accessorialsPickupAndDelivery() {
      for (let i = 0; i < this.sendAccessorials.length; i += 1) {
        if (this.sendAccessorials[i].accessorial_type === "pick up") {
          this.accessorialsPickup.push(this.sendAccessorials[i]);
        }
        if (this.sendAccessorials[i].accessorial_type === "delivery") {
          this.accessorialsDelivery.push(this.sendAccessorials[i]);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pickup-and-delivery {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
</style>
