import CannedResponses from "../../views/CannedResponses/Index.vue";

const CannedResponsesRoute = [
  {
    path: "/canned-responses",
    name: "CannedResponses",
    component: CannedResponses,
  },
];

export default CannedResponsesRoute;
