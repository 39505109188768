<template>
  <div class="carriers-table">
    <div
      class="d-flex justify-content-center align-items-center spinner-container"
      v-if="isLoading"
    >
      <img src="@/assets/gif/spinner.gif" width="200" height="200" />
    </div>
    <DataTable
      v-else
      :value="carriersData"
      :paginator="false"
      class="p-datatable-customers"
      :rows="10"
      dataKey="id"
      :rowHover="true"
      responsiveLayout="scroll"
    >
      <template #header>
        <div class="carriers-table__header">
          <div>
          <b-button
            v-if="loggedUser.role === 'global_admin'"
            class="carriers-table__header--button"
            @click="openCarrierModal('Create New')"
          >
            Add Carrier
          </b-button>
          </div>
          <div class="carriers-table__container-filter">
            <b-button
              class="carriers-table__container-filter--button-filter"
              @click="changeGetCarriers()"
            >
              <b-icon icon="funnel" aria-hidden="true"></b-icon>
              {{ getCarriers === true ? `Carriers By ${buttonName}` : "All carriers" }}
            </b-button>
            <div class="p-inputgroup carriers-table__container-filter-search">
              <InputText
                placeholder="Search by name"
                v-model="searchField"
                class="carriers-table__container-filter-search--input-text"
                v-on:keyup.enter="searchCarrier()"
              />
              <Button
                icon="pi pi-times"
                class="p-button carriers-table__container-filter-search--button-clear"
                @click="clear()"
              />
              <Button
                icon="pi pi-search"
                class="carriers-table__container-filter-search--button"
                @click="searchCarrier()"
              />
            </div>
          </div>
        </div>
      </template>
      <template #empty> No carriers found. </template>
      <Column field="carrier_id" header="Id">
        <template #body="{ data }">
          {{ data.carrier_id || data.id }}
        </template>
      </Column>
      <Column field="carrier_logo" header="Logo">
        <template #body="{ data }">
          <img width="110" :src="data.logo" :alt="data.logo ? 'Logo' : ''">
        </template>
      </Column>
      <Column field="name" header="Carrier">
        <template #body="{ data }">
          {{ data.carrier_name || data.name }}
        </template>
      </Column>
      <Column field="name" header="Identification">
        <template #body="{ data }">
          {{ data.carrier_scac || data.scac }}
        </template>
      </Column>
      <Column field="actions" header="Actions">
        <template #body="{ data }">
          <div class="carriers-table__table-actions">
            <span
              v-if="
                loggedUser.role === 'global_admin' ||
                (loggedUser.role !== 'global_admin' && getCarriers === true)
              "
              @click="openCarrierModal('Edit', data)"
              ><i class="pi pi-pencil"></i
            ></span>
            <span
              v-if="
                loggedUser.role === 'global_admin' ||
                (loggedUser.role !== 'global_admin' && getCarriers === false)
              "
              @click="deleteRow(data.carrier_id || data.id)"
              ><i class="pi pi-trash"></i
            ></span>
          </div>
        </template>
      </Column>
    </DataTable>
    <b-pagination
      v-if="!isLoading"
      v-model="currentPage"
      pills
      :total-rows="totalItemsCount"
      :per-page="perPage"
      class="my-0"
      @change="paginate"
      aria-controls="data"
    >
    </b-pagination>
    <CarriersModal
      ref="carrierModal"
      :modalType="modalType"
      :infoCarrier="carrierSelected"
      @updateCarriers="updateCarriers"
    ></CarriersModal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CarriersModal from "./CarriersModal.vue";
import { deleteMessage, successMessage } from "../../../utils/alertMessages";

export default {
  name: "CarriersTable",
  props: {},
  components: {
    CarriersModal,
  },
  data() {
    return {
      loggedUser: null,
      carriersData: null,
      modalType: "",
      carrierSelected: null,
      searchField: "",
      isLoading: false,
      currentCompanyDepartment: null,
      profileACtive: null,
      getCarriers: true,
      buttonName: null,
      totalItemsCount: 0,
      currentPage: 1,
      perPage: 15,
    };
  },
  computed: {
    ...mapGetters("user", ["showCurrentCompanyDepartment"]),
    ...mapGetters("carriers", ["showAllCarriersProfiles"]),
    ...mapGetters("company", ["showProfileCompanysDepartment"]),
    page: {
      get() {
        return this.currentPage;
      },
      set(currentPage) {
        this.$emit("setCurrentPage", currentPage);
      },
    },
  },
  watch: {
    showAllCarriersProfiles() {
      if (this.showAllCarriersProfiles) {
        this.carriersData = this.showAllCarriersProfiles.data;
        this.totalItemsCount = this.showAllCarriersProfiles.count;
      }
    },
    async showCurrentCompanyDepartment() {
      if (this.showCurrentCompanyDepartment) {
        this.currentCompanyDepartment = this.showCurrentCompanyDepartment;
        this.buttonName = this.currentCompanyDepartment.chosenDepartment.id === -1
          ? this.currentCompanyDepartment.chosenCompany.name
          : this.currentCompanyDepartment.chosenDepartment.name;
        await this.getInfoProfile();
        await this.updateAllCarriers();
      }
    },
    showProfileCompanysDepartment() {
      if (this.showProfileCompanysDepartment.data) {
        this.profileACtive = this.showProfileCompanysDepartment;
      }
    },
  },
  async created() {
    this.loggedUser = JSON.parse(localStorage.getItem("user-info"));
    this.currentCompanyDepartment = this.showCurrentCompanyDepartment;
    this.buttonName = this.currentCompanyDepartment.chosenDepartment.id === -1
      ? this.currentCompanyDepartment.chosenCompany.name
      : this.currentCompanyDepartment.chosenDepartment.name;
    await this.getInfoProfile();
    await this.getAllCarriers();
    this.isLoading = false;
  },
  methods: {
    async paginate(e) {
      this.currentPage = e;
      let params;
      if (this.searchField) {
        params = {
          name: this.searchField,
        };
      }
      this.updateAllCarriers(params);
      window.scrollTo(0, 0);
    },
    async getAllCarriers(payload) {
      this.isLoading = true;
      this.searchField = payload?.name ? this.searchField : "";
      const params = {
        limit: 15,
        skip: (this.currentPage - 1) * 15,
      };
      if (payload) {
        params.name = payload.name;
      }
      await this.$store.dispatch("carriers/getAllCarriers", params);
      this.isLoading = false;
    },
    async getAllCarriersByCurrentInfo(payload) {
      this.isLoading = true;
      this.searchField = payload?.name ? this.searchField : "";
      if (this.profileACtive) {
        const params = {
          profile_id: this.profileACtive.data[0].profile_id,
          company_id: this.currentCompanyDepartment.chosenCompany.id,
          department_id: this.currentCompanyDepartment.chosenDepartment.id,
          limit: 15,
          skip: (this.currentPage - 1) * 15,
        };
        if (payload) {
          params.carrier_name = payload.name;
        }
        await this.$store.dispatch("carriers/getAllCarriersProfiles", params);
        this.isLoading = false;
      }
      this.isLoading = false;
    },
    async changeGetCarriers() {
      this.getCarriers = !this.getCarriers;
      this.updateAllCarriers();
    },
    async getInfoProfile() {
      this.isLoading = true;
      const params = {
        company_id: this.currentCompanyDepartment.chosenCompany.id,
        department_id: this.currentCompanyDepartment.chosenDepartment.id,
      };
      const response = await this.$store.dispatch("company/getProfileCompanysDepartment", params);
      this.profileACtive = response.status === 200 ? response.data : null;
    },
    async deleteRow(carrierId) {
      const validateRole = this.loggedUser.role === "global_admin" && this.getCarriers === true;
      const msg = validateRole ? "Are you sure you want to remove Carrier from the system?" : `Are you sure you want to remove the Carrier from the ${this.buttonName} profile?`;
      const warningResponse = await deleteMessage(
        msg,
        "You won't be able to revert this!",
      );
      if (warningResponse.isConfirmed) {
        this.deleteCarrier(carrierId);
      }
    },
    async deleteCarrier(carrierId) {
      const params = {
        carrier_id: carrierId,
        confirmation_message: "I'm sure I want to delete this",
      };
      let response;
      if (this.loggedUser.role === "global_admin" && this.getCarriers === true) {
        response = await this.$store.dispatch("carriers/deleteCarrier", params);
      } else {
        params.profile_id = this.profileACtive.data[0].profile_id;
        params.company_id = this.currentCompanyDepartment.chosenCompany.id;
        params.department_id = this.currentCompanyDepartment.chosenDepartment.id;
        response = await this.$store.dispatch("carriers/deleteCarrierProfile", params);
      }
      if (response.status === 204 || response.status === 201) {
        successMessage("Carrier has been deleted");
        this.updateAllCarriers();
      }
    },
    openCarrierModal(modalType, data) {
      this.modalType = modalType;
      this.$refs.carrierModal.show();
      if (modalType === "Edit") {
        this.carrierSelected = data;
      } else {
        this.carrierSelected = null;
      }
    },
    async searchCarrier() {
      if (this.searchField) {
        this.currentPage = 1;
        const params = {
          name: this.searchField,
        };
        this.updateAllCarriers(params);
      }
    },
    clear() {
      this.currentPage = 1;
      this.searchField = "";
      this.updateAllCarriers();
    },
    async updateAllCarriers(params) {
      if (this.getCarriers) {
        await this.getAllCarriers(params);
      } else {
        await this.getAllCarriersByCurrentInfo(params);
      }
    },
    updateCarriers() {
      this.isLoading = true;
      this.updateAllCarriers();
    },

    rowClicked(row) {
      this.$emit("rowClicked", row);
    },
    sortingChanged(ctx) {
      this.$emit("sortChanged", ctx);
    },
    changeStatus(row, status) {
      this.$emit("changeStatus", { row, status });
    },
  },
};
</script>

<style lang="scss" scoped>
.carriers-table {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &__header {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    &--button {
      @include submit-button;
    }
  }
  &__container-filter {
    display: flex;
    align-items: center;
    width: 55%;
    flex-direction: row;
    justify-content: flex-end;
    &--button-filter {
      @include submit-button;
      width: 45%;
      margin-right: 0.5rem;
    }
  }
  &__container-filter-search {
      width: 80%;
      &--input-text {
        border-radius: 8px 0 0 8px;
        border: none;
      }
      &--button {
        @include submit-button;
        border-color: $color-submit-button;
        border-radius: 0px 8px 8px 0;
      }
      &--button-clear {
        background-color: $color-white;
        border-color: $color-white;
        color: gray;
        border: 0;
        &:hover, &:active {
        background-color: $color-white !important;
        border-color: $color-white !important;
        color: gray !important;
        }
      }
    }
  &__table-actions {
    span {
      cursor: pointer;
    }
  }
}
.pi-pencil {
  color: #17a2b8;
  padding-right: 0.5rem;
}
.pi-trash {
  color: #c60707;
  padding-right: 0.5rem;
}
.overlay-button {
  display: flex;
  margin: auto;
  justify-content: center;
  padding: 2rem 1rem 1rem;
}
:deep(.p-datatable-header) {
  background-color: $color-background-dark !important;
}
:deep(.p-datatable) {
  width: 96%;
}
:deep(.p-column-header-content) {
  justify-content: start;
}
:deep(.p-multiselect-label) {
  padding-top: 0.3rem;
}
:deep(.pagination) {
  padding-bottom: 0.625rem;
}

@media (max-width: 912px) and (min-width: 761px) {
 .carriers-table {
  &__container-filter {
    width: 70%;
  }
 }
}

@media (max-width: 760px) and (min-width: 661px) {
 .carriers-table {
  &__container-filter {
    margin: 10px;
    width: 70%;
    flex-direction: column;
    align-items: flex-end;
  }
  &__container-filter--button-filter {
    width: 50%;
    margin-bottom: 0.8rem;
    margin-right: 0;
  }
  &__container-filter-search {
      width: 100%;
    }
  &__header--button {
    margin: 10px;
  }
 }
}

@media (max-width: 660px) {
  .carriers-table {
    &__header {
      flex-direction: column;
      align-items: flex-start;
      &--button {
        margin: 0 0 10px 10px;
      }
    }
    &__container-filter {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 96%;
      margin: 0 0 0 10px;
    }
    &__container-filter--button-filter {
    width: 13.13rem;
    margin-bottom: 0.8rem;
    margin-right: 0;
  }
    &__container-filter-search {
      width: 100%;
    }
  }
}
</style>
