<template>
  <div class="editable-tables">
    <div class="editable-tables__collapse-buttons">
      <b-button @click="visibleAccessorial = !visibleAccessorial" class="editable-tables__button"
        ><b-icon
          class="mr-2"
          :icon="visibleAccessorial ? 'chevron-double-up' : 'chevron-double-down'"
        ></b-icon
        >Accessorials</b-button
      >
      <b-button @click="visibleRate = !visibleRate" class="editable-tables__button"
        ><b-icon
          class="mr-2"
          :icon="visibleRate ? 'chevron-double-up' : 'chevron-double-down'"
        ></b-icon
        >Rate</b-button
      >
    </div>
    <b-collapse v-model="visibleAccessorial">
      <b-button class="editable-tables__add-row" @click="addRow('accessorials')"
        >Add new row</b-button
      >
      <b-table
        v-if="accessorialTable"
        outlined
        class="editable-tables__vertical-table-accessorials"
        responsive
        :items="accessorialTable[0].table_content"
        :key="renderTable"
      >
        <template #thead-top>
          <b-th class="text-center" colspan="3">{{ accessorialTable[0].header[0] }}</b-th>
        </template>
        <template #head()>
          <b-tr></b-tr>
        </template>
        <template #cell(key)="row">
          <b-input @change="changeField()" v-model="row.item.key"></b-input>
        </template>
        <template #cell(value)="row">
          <b-input @change="changeField()" v-model="row.item.value"></b-input>
        </template>
        <template #cell(note)="row">
          <b-form-textarea @change="changeField()" v-model="row.item.note"></b-form-textarea>
        </template>
        <template #cell(actions)="row">
          <b-button class="editable-tables__delete-row" @click="deleteAccessorialsRows(row.index)"
            ><b>X</b></b-button
          >
        </template>
      </b-table>
    </b-collapse>
    <b-collapse v-model="visibleRate">
      <b-button class="editable-tables__add-row" @click="addRow('rate')">Add new row</b-button>
      <b-table
        v-if="rateTable"
        outlined
        class="editable-tables__vertical-table-rate"
        responsive
        :items="rateTable[0].table_content"
        :key="renderTable"
      >
        <template #thead-top>
          <b-th class="text-center" colspan="3">{{ rateTable[0].header[0] }}</b-th>
        </template>
        <template #head()>
          <b-tr></b-tr>
        </template>
        <template #cell(key)="row">
          <b-input @change="changeField()" v-model="row.item.key"></b-input>
        </template>
        <template #cell(value)="row">
          <b-input @change="changeField()" v-model="row.item.value"></b-input>
        </template>
        <template #cell(actions)="row">
          <b-button class="editable-tables__delete-row" @click="deleteRateRows(row.index)"
            ><b>X</b></b-button
          >
        </template>
      </b-table>
    </b-collapse>
    <b-button class="editable-tables__save-table" @click="saveTables()"
      >Save Tables <b-icon v-if="savedTable" icon="check-circle"></b-icon
    ></b-button>
  </div>
</template>

<script>
export default {
  name: "AccessorialsAndRateTable",
  props: ["cannedTablesInfo", "typeCrud"],
  data() {
    return {
      accessorialTable: [],
      rateTable: [],
      renderTable: 0,
      visibleAccessorial: true,
      visibleRate: true,
      savedTable: false,
      disableFields: {
        linehaul: "Linehaul Rate + FSC (included)",
        chassis: "Chassis",
        redelivery: "Redelivery may apply if the container is not able to be returned same day once it is loaded/unloaded",
        chassisFee: "Chassis Fee",
      },
    };
  },
  created() {
    this.accessorialTable = this.cannedTablesInfo.accessorialTable;
    this.rateTable = this.cannedTablesInfo.rateTable;
  },
  methods: {
    deleteAccessorialsRows(index) {
      this.accessorialTable[0].table_content.splice(index, 1);
    },
    deleteRateRows(index) {
      this.rateTable[0].table_content.splice(index, 1);
    },
    addRow(type) {
      if (type === "accessorials") {
        this.accessorialTable[0].table_content.push({
          key: "",
          value: "",
          note: "",
          actions: "",
        });
      } else {
        this.rateTable[0].table_content.push({
          key: "",
          value: "",
          actions: "",
        });
      }
    },
    saveTables() {
      this.accessorialsAndRateInfo = {
        accessorialTable: this.accessorialTable[0],
        rateTable: this.rateTable[0],
      };
      this.$emit("accessorialsAndRateInfo", this.accessorialsAndRateInfo);
      this.savedTable = true;
      this.renderTable += 1;
    },
    changeField() {
      this.savedTable = false;
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.editable-tables) {
  &__collapse-buttons {
    display: flex;
    flex-direction: row;
    margin: 3rem 0;
    flex-wrap: wrap;
  }
  &__button {
    margin: 1rem 1rem 0 0;
    margin-right: 1rem;
    @include secondary-button;
  }
  &__add-row {
    @include submit-button;
    display: flex;
    margin: 1rem 1rem 1rem 0;
  }
  &__delete-row {
    background: none;
    color: $color-cancel-button;
    border: none;
  }
  &__vertical-table-accessorials {
    & .table th,
    .table td {
      padding: 0;
      border: none;
      border-radius: 0;
      textarea.form-control {
        height: 100px;
        width: 400px;
      }
      .form-control {
        height: 100px;
      }
    }
  }
  &__vertical-table-rate {
    & .table th,
    .table td {
      padding: 0;
      border: none;
      border-radius: 0;
    }
  }
  &__save-table {
    margin-top: 2rem;
    @include submit-button;
  }
}
</style>
