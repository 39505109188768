<template>
  <div class="actions-options">
    <div v-if="selectedAction !== '' && selectedAction !== null">
      <div class="option-container">
        <div class="option-container__line">
          <label class="option-container__label">{{ selectedAction.label }}</label>
          <div
            class="option-container__selects"
            :key="index"
            v-for="(item, index) in selectedAction.params"
          >
            <Select v-if="item.fieldType === 'select'"
            @actionConstructor="actionConstructor"
            :optionNumber="optionNumber"
            :paramData="item"
            :paramIndex="index"
            :inputText="inputText"
            />
            <SelectMultiple v-else-if="item.fieldType?.includes('multiple')"
            @actionConstructor="actionConstructor"
            :optionNumber="optionNumber"
            :paramData="item"
            :paramIndex="index"
            :inputText="inputText"
            />
            <Input v-else
            @actionConstructor="actionConstructor"
            :optionNumber="optionNumber"
            :paramData="item"
            :paramIndex="index"
            :renderComponent="renderComponent"
            :inputTextDict="inputTextDict"
            :inputText="inputText"
            :fieldColor="fieldColor"
            />
          </div>
        </div>
        <div>
          <i v-if="showCheckAction" class="pi pi-check-circle"></i>
          <button class="option-container__delete-option" @click="deleteOption">
            <i class="pi pi-trash"></i>
          </button>
        </div>
      </div>
    </div>
    <p v-if="selectedAction !== '' && selectedAction !== null" class="and-text"><b>AND</b></p>
    <template v-if="selectedAction === '' || selectedAction === null">
      <button
        class="rule-button"
        @click="openOptionsContainer"
        aria-controls="actions-overlay-panel"
        ref="actions-rules-button"
      >
        <i class="pi pi-plus-circle"></i> <b>Action</b>
      </button>
      <OverlayPanel id="actions-overlay-panel" ref="actions-overlay">
        <div class="search-actions">
          <input
            ref="searchActions"
            class="search-actions__input"
            type="text"
            placeholder="Search..."
            v-model="searchActions"
            :id="'search-actions-input' + optionNumber"
          />
        </div>
        <div class="rule-options">
          <div
            v-for="(category, index) in actionsOptions"
            :key="index"
            class="rule-options__option"
            @click="selectAction(category)"
          >
            <label
              v-tooltip="{ value: category.description, disabled: false }"
              class="rule-options__label"
              :for="category.name"
              >{{ category.label }}</label
            >
          </div>
        </div>
      </OverlayPanel>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Input from "./Components/Input.vue";
import SelectMultiple from "./Components/SelectMultiple.vue";
import Select from "./Components/Select.vue";

export default {
  name: "ActionsOptions",
  components: {
    Input,
    SelectMultiple,
    Select,
  },
  props: {
    optionNumber: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      selectedAction: "",
      actionsOptions: [],
      allActionsOptions: [],
      searchActions: "",
      inputText: [],
      inputTextDict: [],
      inputSaved: [],
      showCheckAction: false,
      areActionsComplete: [],
      dictType: [
        { name: "en", label: "English" },
        { name: "es", label: "Spanish" },
      ],
      optionSelected: "",
      fieldColor: {},
      renderComponent: 0,
    };
  },
  computed: {
    ...mapGetters("rules", ["showRuleCompleteObject"]),
  },
  watch: {
    searchActions(value) {
      if (value !== "") {
        const result = this.allActionsOptions.filter((action) => this.searchString(action, value));
        this.actionsOptions = result;
      } else {
        this.actionsOptions = this.allActionsOptions;
      }
    },
    showRuleCompleteObject() {
      const ruleData = this.showRuleCompleteObject;
      this.allActionsOptions = ruleData?.actions;
      this.actionsOptions = this.allActionsOptions;
    },
  },
  created() {
    this.getActionsArray();
  },
  methods: {
    searchString(action, value) {
      return action.label.toLowerCase().includes(value.toLowerCase());
    },
    getActionsArray() {
      const ruleData = this.showRuleCompleteObject;
      this.allActionsOptions = ruleData?.actions;
      this.actionsOptions = this.allActionsOptions;
    },
    fillActionsCards(actionSelected) {
      this.showCheckAction = true;
      const action = actionSelected;
      this.areActionsComplete = this.$store.getters["rules/showBooleanActions"];
      this.getActionsArray();
      this.selectedAction = this.allActionsOptions.find((item) => item.name === action.name);
      this.changeFieldColor();
      Object.entries(action.params).forEach(([key, value]) => {
        const paramIndex = this.selectedAction.params.findIndex((item) => item.name === key);
        const inputValue = (typeof value) === 'string' ? value?.toLowerCase() : value;
        this.inputText[paramIndex] = inputValue;
        if (key === "data") {
          Object.entries(action.params.data).forEach(([name, param]) => {
            const dictIndex = this.dictType.findIndex((item) => item.name === name);
            this.inputTextDict[dictIndex] = param;
          });
        }
      });
    },
    getSpanInfo(name, type) {
      const text = document.getElementById(name + this.optionNumber).innerText;
      this.actionConstructor(text, name, type);
    },
    async actionConstructor(text, name, type, index) {
      const infoAction = {
        params: {},
      };
      infoAction.name = this.selectedAction.name;
      if (type === "dict") {
        infoAction.params.data = {
          [name]: text,
        };
      } else {
        infoAction.params[name] = text;
      }
      if (text && typeof text !== 'number' && text?.includes("formula:")) {
        const response = await this.$store.dispatch("rules/checkFormula", text);
        if (response.status !== 200) {
          this.showErrorInput(name, index);
          infoAction.params[name] = "";
        } else if (!response.data.is_valid) {
          this.showErrorInput(name, index);
          infoAction.params[name] = "";
        } else {
          this.showSuccessInput(name, index);
        }
      } else if (type === "numeric") {
        if (Number.isNaN(parseInt(text, 10))) {
          this.showErrorInput(name, index);
          infoAction.params[name] = "";
        } else {
          this.showSuccessInput(name, index);
          const numbers = text.split(',');
          if (numbers.length > 1) {
            const number = numbers[0].concat('.', numbers[1]);
            infoAction.params[name] = parseFloat(number);
          } else {
            infoAction.params[name] = parseFloat(numbers);
          }
        }
      } else if (text && text !== "") {
        this.showSuccessInput(name, index);
      } else {
        this.showErrorInput(name, index);
      }
      this.$emit("buildActionsArray", infoAction, this.optionNumber, name, type);
    },
    selectAction(action) {
      this.selectedAction = action;
      this.$refs["actions-overlay"].hide();
      this.$emit("pushNewAction");
      this.changeBooleanAction(false);
      if (this.selectedAction.params === null || this.selectedAction.params.length === 0) {
        const infoAction = {
          name: this.selectedAction.name,
          params: {},
        };
        this.$emit("buildActionsArray", infoAction, this.optionNumber);
      }
    },
    openOptionsContainer(event) {
      this.$refs["actions-overlay"].toggle(event);
    },
    deleteOption() {
      this.$emit("handleDeleteOption", this.optionNumber);
      this.areActionsComplete.splice(this.optionNumber, 1);
      this.$store.commit("rules/setBooleanActions", this.areActionsComplete);
    },
    showSuccessInput(name, index) {
      const element = document.getElementById(name + this.optionNumber);
      element.classList.remove("error");
      element.classList.add("saved");
      if (index === 0 || index) {
        this.fieldColor[index] = '#00b900';
        this.renderComponent += 1;
      }
    },
    showErrorInput(name, index) {
      const element = document.getElementById(name + this.optionNumber);
      element.classList.remove("saved");
      element.classList.add("error");
      if (index === 0 || index) {
        this.fieldColor[index] = '#f44336';
        this.renderComponent += 1;
      }
    },
    verifyAction(actionsArray) {
      // const index = actionsArray.findIndex((action) => action.name === this.selectedAction.name);
      const finalArraySize = Object.keys(actionsArray[this.optionNumber].params).length;
      let selectedActionSize;
      if (this.selectedAction.params === null) {
        selectedActionSize = 0;
      } else {
        selectedActionSize = this.selectedAction.params.length;
      }
      if (finalArraySize === selectedActionSize) {
        this.showCheckAction = true;
        this.changeBooleanAction(true);
      } else {
        this.changeBooleanAction(false);
      }
    },
    emptyAction() {
      this.showCheckAction = false;
      this.changeBooleanAction(false);
    },
    changeBooleanAction(booleanAction) {
      this.areActionsComplete[this.optionNumber] = booleanAction;
      this.$store.commit("rules/setBooleanActions", this.areActionsComplete);
    },
    changeFieldColor() {
      const arrayLength = Object.values(this.selectedAction.params).length;
      for (let i = 0; i < arrayLength; i += 1) {
        this.fieldColor[i] = '#d9d9d9';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.rule-button {
  @include option-rules-button;
}

.rule-options {
  @include list-of-rules;
}

.search-actions {
  &__input {
    @include list-of-rules-input;
  }
}

.and-text {
  margin: 0.5rem 0;
}

.option-container {
  @include each-option-container;
  &__line {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    width: 95%;
  }
  &__input {
    height: 33px;
    margin-top: 5px;
  }
  &__label {
    margin-top: 5px;
  }
  &__selects {
    width: 100%;
  }
  &__select {
    height: 33px;
    margin-top: 5px;
  }
  &__container-input {
    margin-left: 5px;
    display: flex;
    align-items: baseline;
    justify-content: center;
    margin-top: 5px;
  }
  &__text-area {
    padding-left: 0;
    border: solid 1px #d9d9d9;
  }
}

.saved {
  border-color: $color-user-active !important;
}

.error {
  border-color: $color-input-error !important;
}

.pi-check-circle {
  color: $color-user-active;
  margin-right: 0.2rem;
}

input:focus {
  outline: none;
}
:deep(.p-dropdown-label) {
  padding-top: 0 !important;
}
.input {
  display: inline-block;
  text-align: initial;
  margin: 0 5px;
  background-color: #f3f3f3;
  border: solid 1px #d9d9d9;
  padding: 3px 5px;
  color: #495057;
  height: 28px;
  min-width: 204px;
}
span:focus {
  outline: none;
  overflow: hidden;
}
span:empty:focus::before {
 content: attr(data-focused-advice);
}
:deep(.el-textarea__inner) {
  border: none;
  background-color: #f3f3f3;
  height: 30.99px;
}
</style>
