<template>
  <div>
    <!-- MODAL -->
    <b-modal
      hide-footer
      class="profile-modal"
      ref="new-profile-modal"
      size="lg"
      :title="modalTitle"
    >
      <div>
        <Checkbox
          class="profile-modal__checkbox"
          v-model="checked"
          :binary="true"
          @change="getData()"
        />
        <label class="profile-modal__checkbox__text">Associate with company and department</label>
      </div>
      <div class="profile-modal__row" v-if="checked">
        <div class="profile-modal__column">
          <label class="profile-modal__column__text" for="company"
            >Company <b style="color: red">*</b></label
          >
          <Dropdown
            class="profile-modal__column__input"
            v-model="selectedCompany"
            :options="companies"
            optionLabel="name"
            optionValue="id"
            @change="getdepartments()"
          />
        </div>
        <div class="profile-modal__column">
          <label class="profile-modal__column__text" for="department"
            >Department <b style="color: red">*</b></label
          >
          <Dropdown
            class="profile-modal__column__input"
            v-model="selectedDepartment"
            :options="departments"
            optionLabel="department_name"
            optionValue="department_id"
          />
        </div>
      </div>
      <div class="profile-modal__row">
        <div class="profile-modal__column">
          <label class="profile-modal__column__text" for="name"
            >Name <b style="color: red">*</b></label
          >
          <InputText
            class="profile-modal__column__input"
            :class="errors.name"
            :key="renderInput"
            v-model="newProfile.name"
            @change="validateField(newProfile.name, 'name')"
            id="name"
            type="text"
          />
        </div>
        <div v-if="modalTitle !== 'Edit Profile'" class="profile-modal__column">
          <label class="profile-modal__column__text" for="reference">
            Reference Profile (Optional)
          </label>
          <Dropdown
            class="profile-modal__column__input"
            v-model="newProfile.reference_id"
            :options="profiles"
            optionLabel="name"
            optionValue="id"
            :showClear="true"
          />
        </div>
      </div>
      <div class="profile-modal__description-container">
        <label class="profile-modal__column__text" for="description"
          >Description <b style="color: red">*</b></label
        >
        <InputText
          class="profile-modal__column__input"
          :class="errors.description"
          :key="renderInput"
          v-model="newProfile.description"
          @change="validateField(newProfile.description, 'description')"
          id="description"
          type="text"
        />
      </div>
      <b-overlay class="overlay-button" :show="isLoading" rounded opacity="0.6" spinner-small>
        <b-button
          v-if="modalTitle !== 'Edit Profile'"
          @click="createProfile()"
          class="profile-modal__button"
          :disabled="!canCreate"
        >
          Create Profile
        </b-button>
        <b-button
          v-else
          @click="verifyUpdate()"
          class="profile-modal__button"
          :disabled="!canUpdate"
        >
          Save Changes
        </b-button>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import { successMessage, errorDynamicMessage, deleteMessage } from "@/utils/alertMessages";

export default {
  name: "CreateProfileModal",
  data() {
    return {
      modalTitle: "",
      isLoading: false,
      checked: false,
      selectedCompany: null,
      selectedDepartment: null,
      canCreate: false,
      canUpdate: false,
      newProfile: {},
      companies: [],
      departments: [],
      profiles: [],
      profileId: null,
      errors: {},
      renderInput: 0,
    };
  },
  watch: {
    newProfile: {
      handler() {
        this.validateForm();
      },
      deep: true,
    },
    checked() {
      this.validateForm();
    },
    selectedDepartment() {
      this.validateForm();
    },
  },
  created() {
    this.cleanColor();
  },
  methods: {
    validateForm() {
      if (this.newProfile.name === "" || this.newProfile.description === "") {
        this.canCreate = false;
        this.canUpdate = false;
      } else if (this.checked && this.selectedDepartment === null) {
        this.canCreate = false;
        this.canUpdate = false;
      } else {
        this.canCreate = true;
        this.canUpdate = true;
      }
    },
    validateField(value, input) {
      this.errors[input] = !value ? "error" : "input-valid";
      this.renderInput += 1;
    },
    cleanColor() {
      this.errors.name = '';
      this.errors.description = '';
    },
    async showModal(option) {
      this.cleanColor();
      const info = await this.$store.dispatch("profile/getAllProfiles");
      this.profiles = info.data;
      this.checked = false;
      this.selectedCompany = null;
      this.selectedDepartment = null;
      if (option) {
        this.profileId = option.id;
        this.modalTitle = "Edit Profile";
        this.newProfile = await this.$store.dispatch("profile/getProfileById", option.id);
        this.validateField(this.newProfile.name, 'name');
        this.validateField(this.newProfile.description, 'description');
      } else {
        this.modalTitle = "Add New Profile";
        this.newProfile = {
          name: "",
          description: "",
          status: "inactive",
          reference_id: null,
        };
      }
      this.$refs["new-profile-modal"].show();
    },
    async getData() {
      if (this.checked) {
        if (this.modalTitle === "Edit Profile") {
          const params = {
            company_id: -2,
            department_id: -2,
            profile_id: this.profileId,
          };
          const profileAssociationData = await this.$store.dispatch(
            "company/getProfilesPerCompany",
            params,
          );
          if (profileAssociationData.data.length !== 0) {
            this.selectedCompany = profileAssociationData.data[0].company_id;
            this.selectedDepartment = profileAssociationData.data[0].department_id;
            const payload = {
              company_id: this.selectedCompany,
              department_id: -2,
            };
            const depData = await this.$store.dispatch("company/getDepartmentsPerCompany", payload);
            this.departments = depData.data;
          }
        }
        const companiesData = await this.$store.dispatch("company/getAllCompanies");
        this.companies = companiesData.data;
      }
    },
    async getdepartments() {
      const params = {
        company_id: this.selectedCompany,
        department_id: -2,
      };
      const depData = await this.$store.dispatch("company/getDepartmentsPerCompany", params);
      this.departments = depData.data;
    },
    async createProfile() {
      this.isLoading = true;
      const response = await this.$store.dispatch("profile/createProfile", this.newProfile);
      this.isLoading = false;
      if (response.status === 200 || response.status === 201) {
        if (this.checked) {
          const associationResponse = await this.updateProfilesAssociation(response, -1);
          if (associationResponse.status !== 200 && associationResponse.status !== 204) {
            errorDynamicMessage(
              "Oops...",
              "The profile has been created but it could not be associated.",
            );
          } else {
            this.$refs["new-profile-modal"].hide();
            successMessage(`The profile has been created.`);
          }
        } else {
          this.$refs["new-profile-modal"].hide();
          successMessage(`The profile has been created.`);
        }
        this.isLoading = false;
        const event = {
          index: 1,
        };
        this.$emit("getData", event);
      }
    },
    async verifyUpdate() {
      const warningResponse = await deleteMessage(
        'Are you sure you want to update the profile?',
        "You won't be able to revert this!",
      );
      if (warningResponse.isConfirmed) {
        this.updateProfile();
      }
    },
    async updateProfile() {
      this.isLoading = true;
      const params = {
        profile_id: this.profileId,
        data: this.newProfile,
      };
      const response = await this.$store.dispatch("profile/updateProfile", params);
      this.isLoading = false;
      if (response.status === 200 || response.status === 204) {
        if (this.checked) {
          const associationResponse = await this.updateProfilesAssociation(
            response,
            this.profileId,
          );
          if (associationResponse.status !== 200 && associationResponse.status !== 204) {
            errorDynamicMessage("Oops...", "The profile could not be associated.");
          } else {
            this.$refs["new-profile-modal"].hide();
            successMessage(`The profile has been updated.`);
          }
        } else {
          this.$refs["new-profile-modal"].hide();
          successMessage(`The profile has been updated.`);
        }
        this.isLoading = false;
        const event = {
          index: 1,
        };
        this.$emit("getData", event);
      }
    },
    async updateProfilesAssociation(response, profileId) {
      const payload = {
        company_id: this.selectedCompany,
        department_id: this.selectedDepartment,
      };
      if (profileId !== -1) {
        payload.data = {
          profile_id: profileId,
        };
      } else {
        payload.data = {
          profile_id: response.data.id,
        };
      }
      const associationResponse = await this.$store.dispatch(
        "company/updateProfilesAssociation",
        payload,
      );
      return associationResponse;
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-modal {
  &__button {
    @include submit-button;
  }
  &__row {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
  }
  &__column {
    display: flex;
    flex-direction: column;
    width: 48%;
    &__text {
      color: $color-input-text;
      margin-left: 0.2rem;
    }
    &__input {
      @include input;
    }
  }
  &__description-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem;
  }
  &__checkbox {
    margin: 0 0.5rem 0 1rem;
    &__text {
      color: $color-input-text;
      margin-top: 0.2rem;
    }
  }
}
.overlay-button {
  display: flex;
  margin: auto;
  justify-content: center;
  padding: 2rem 1rem 1rem;
}
:deep(.p-dropdown-label) {
  padding-top: 0.3rem;
}
.error {
  border-color: $color-input-error;
}
.input-valid {
  border: 1px solid #00b900;
}
</style>
