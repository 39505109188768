import Repository from '../../services/repositories/RepositoryFactory';
import { errorValidationMessage, errorMessage, tokenExpired } from "../../utils/alertMessages";

const ProfileRepository = Repository.service('profiles');

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async getAllProfiles(_, params) {
      try {
        const response = await ProfileRepository.getAllProfiles(params);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
    async getProfileById(_, payload) {
      try {
        const response = await ProfileRepository.getProfileById(payload);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
    async createProfile(_, payload) {
      try {
        const response = await ProfileRepository.createProfile(payload);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          if (error.response.data.detail.match('duplicate key value')) {
            return errorValidationMessage('This profile exists, please check the data and try again.');
          }
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
    async updateProfile(_, payload) {
      try {
        const response = await ProfileRepository.updateProfile(payload);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          if (error.response.data.detail.match('duplicate key value')) {
            return errorValidationMessage('This profile exists, please check the data and try again.');
          }
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
    async deleteProfile(_, payload) {
      try {
        const response = await ProfileRepository.deleteProfile(payload);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
  },
};
