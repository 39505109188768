import api from '../clients/axios';

const resource = '/api/v1';

export default {

  async login(payload) {
    return api.post(`${resource}/login/access-token`, payload);
  },

  async getAllUsers(params) {
    const response = await api.get(`${resource}/companies/${params.company_id}/departments/${params.department_id}/users`, { params });
    return response.data;
  },

  async getAllUsersCompleteInfo(params) {
    const response = await api.get(`${resource}/companies/${params.company_id}/departments/${params.department_id}/users/complete-info`, { params });
    return response.data;
  },

  async getUserById(params) {
    const response = await api.get(`${resource}/companies/${params.company_id}/departments/${params.department_id}/users/${params.user_id}`);
    return response.data;
  },

  async getUserByIdCompleteInfo(params) {
    const response = await api.get(`${resource}/companies/${params.company_id}/departments/${params.department_id}/users/${params.user_id}/complete-info`);
    return response.data;
  },

  async getMyCompleteInfo() {
    const response = await api.get(`${resource}/users/me/complete-info`);
    return response.data;
  },

  createUser(payload) {
    return api.post(`${resource}/companies/${payload.company_id}/departments/${payload.department_id}/users`, payload.info);
  },

  updateUser(payload) {
    return api.patch(`${resource}/companies/${payload.company_id}/departments/${payload.department_id}/users/${payload.user_id}`, payload.info);
  },

  updateMyself(payload) {
    return api.put(`${resource}/${payload.company_id}/departments/${payload.department_id}/users/me`, payload);
  },

  deleteUser(params) {
    return api.delete(`${resource}/companies/${params.company_id}/departments/${params.department_id}/users/${params.user_id}?confirmation_message=${params.confirmation_message}`);
  },
};
