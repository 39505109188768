<template>
  <div class="create-hauls">
    <div class="create-hauls__sidebar">
      <Sidebar />
    </div>
    <div class="create-hauls__container">
      <h1 class="create-hauls__container__tittle">Testing Laboratory</h1>
      <hr class="create-hauls__container__separator" />
      <div
        class="d-flex justify-content-center align-items-center mt-5 spinner-container"
        v-if="isLoading"
      >
        <img src="@/assets/gif/spinner.gif" width="200" height="200" />
      </div>

      <div v-if="!isLoading">
        <div class="input-text">
          <div>
            <label for="trigger">Trigger: </label>
            <b-form-select id="trigger" v-model="trigger" :options="triggerOptions"></b-form-select>
          </div>
          <div>
            <label for="saveHistory">Save history: </label>
            <b-form-select
              id="saveHistory"
              v-model="saveHistory"
              :options="saveHistoryOptions"
            ></b-form-select>
          </div>
        </div>

        <Accordion :activeIndex="0">
          <AccordionTab header="Carriers *">
            <Carrier v-model="carriers" />
          </AccordionTab>
          <AccordionTab header="Message">
            <MessageHaul v-model="messageHaul" />
            <Mode v-model="tmsMode" />
            <div class="input-text">
            <div>
              <label for="shp">Shp: </label>
              <InputText placeholder="Shp" id="shp" v-model="shp" type="text" />
            </div>
            <div>
              <label for="autocomplete">Customer id: </label>
              <InputText placeholder="Customer id" v-model="customerId" type="text" />
            </div>
          </div>
          <div class="checkbox">
            <b-form-checkbox
              v-for="(checkboxName, index) in checkboxes"
              :key="index"
              v-model="checkboxes[index]"
              name="checkbox-requote"
              class="checkbox"
            >
              {{ index.split("_").join(" ") }}
            </b-form-checkbox>
          </div>
          </AccordionTab>
          <AccordionTab header="Pickup, Delivery And Accessorials">
            <div>
          <PickupAndDelivery
            v-if="accessorialsHaul.length > 0"
            :sendAccessorials="accessorialsHaul"
            v-model="haul.pickupAndDelivery"
          />
        </div>
        <AccessorialsGlobal
          v-if="accessorialsHaul.length > 0"
          :sendAccessorials="accessorialsHaul"
          v-model="haul.accessorials"
        />
          </AccordionTab>
          <AccordionTab header="Items Table">
            <ItemsTable v-model="itemsTable" />
        <div class="input-text__density">
          <label class="input-text__density--label" for="shp">Density class: </label>
          <InputText id="shp" v-model="densityClass" type="number" />
        </div>
          </AccordionTab>
        </Accordion>

        <b-overlay
          class="overlay-button"
          :show="isLoadingCreation"
          rounded
          opacity="0.6"
          spinner-small
        >
          <b-button
            class="create-hauls__button"
            :disabled="!fieldsValidation()"
            @click="sendHaul()">Send Haul</b-button>
        </b-overlay>
      </div>
      <b-modal scrollable ref="my-modal" hide-footer title="Using Component Methods">
        <HaulResponse :sendHaulResponse="haulResponse" />
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Sidebar from "@/components/Sidebar.vue";
import MessageHaul from "./Components/MessageHaul.vue";
import Mode from "./Components/Mode.vue";
import PickupAndDelivery from "./Components/PickupAndDelivery/PickupAndDelivery.vue";
import AccessorialsGlobal from "./Components/AccessorialsGlobal.vue";
import Carrier from "./Components/Carrier.vue";
import ItemsTable from "./Components/ItemsTable.vue";
import HaulResponse from "./Components/HaulResponse.vue";

export default {
  name: "TestingLaboratory",
  components: {
    Sidebar,
    Mode,
    PickupAndDelivery,
    AccessorialsGlobal,
    Carrier,
    ItemsTable,
    MessageHaul,
    HaulResponse,
  },
  data() {
    return {
      checkboxes: {
        requoted: false,
        insurance_required: false,
        x1tc: false,
        shipper: false,
        project_cargo: false,
        xenon_quote: false,
      },
      shp: null,
      customerId: null,
      loadId: null,
      tmsMode: null,
      haul: {
        pickupAndDelivery: {},
        accessorials: [],
      },
      itemsTable: null,
      carriers: null,
      messageHaul: null,
      densityClass: 0,
      lineItems: [],
      companyInfo: null,
      checkFields: true,
      isLoading: false,
      missingFields: null,
      profileACtive: null,
      accessorialsHaul: [],
      companyData: null,
      selectedAccessorials: [],
      isLoadingCreation: false,
      trigger: null,
      triggerOptions: [
        { value: null, text: "Select an option" },
        { value: "new_message", text: "new_message" },
        { value: "new_quote", text: "new_quote" },
        { value: "send_to_process", text: "send_to_process" },
        { value: "business_rules", text: "business_rules" },
        { value: "send_email", text: "send_email" },
      ],
      saveHistory: null,
      saveHistoryOptions: [
        { value: null, text: "Select an option" },
        { value: "Save history complete es", text: "Guardar historial" },
        { value: "Save history complete en", text: "Save History" },
        { value: "No save history", text: "No history" },
      ],
      haulResponse: null,
      enableAction: true,
    };
  },
  async created() {
    this.isLoading = true;
    await this.getInfoProfile();
    await this.getAccesorialsByCurrentInfo();
    this.isLoading = false;
  },
  computed: {
    ...mapGetters("company", ["showProfileCompanysDepartment"]),
  },
  watch: {
    showProfileCompanysDepartment() {
      if (this.showProfileCompanysDepartment.data) {
        this.companyInfo = this.showProfileCompanysDepartment;
      }
    },
  },
  methods: {
    async getInfoProfile() {
      this.companyData = JSON.parse(localStorage.getItem("company-data"));
      const payload = {};
      payload.company_id = this.companyData.chosenCompany.id;
      payload.department_id = this.companyData.chosenDepartment.id;
      const response = await this.$store.dispatch("company/getProfileCompanysDepartment", payload);
      this.profileACtive = response.status === 200 ? response.data : null;
    },
    async getAccesorialsByCurrentInfo() {
      const payload = {
        profile_id: this.profileACtive.data[0].profile_id,
        company_id: this.companyData.chosenCompany.id,
        department_id: this.companyData.chosenDepartment.id,
        limit: 0,
      };
      const response = await this.$store.dispatch("accessorial/getAccessorialsByProfile", payload);
      this.accessorialsHaul = response.data;
    },
    async sendHaul() {
      this.isLoadingCreation = true;
      this.buildLineItems();
      this.changeAccessorials();
      const info = {
        body: {},
        params: {},
      };
      info.params = {
        profile_id: this.companyInfo.data[0].profile_id,
        company_id: this.companyInfo.data[0].company_id,
        department_id: this.companyInfo.data[0].department_id,
        trigger: this.trigger,
        save_history: this.saveHistory,
      };
      info.body.zip_from = this.haul.pickupAndDelivery.from.zip_from;
      info.body.zip_to = this.haul.pickupAndDelivery.to.zip_to;
      info.body.city_from = this.haul.pickupAndDelivery.from.city_from;
      info.body.city_to = this.haul.pickupAndDelivery.to.city_to;
      info.body.state_from = this.haul.pickupAndDelivery.from.state_from;
      info.body.state_to = this.haul.pickupAndDelivery.to.state_to;
      info.body.linear_feet = this.itemsTable.linear_feet;
      info.body.commercial_value = this.itemsTable.commercial_value;
      info.body.customer_id = this.customerId;
      info.body.shp = this.shp;
      info.body.tms_mode = this.tmsMode;
      info.body.line_items = this.lineItems;
      info.body.accessorials = this.selectedAccessorials;
      info.body.expected_cost = this.itemsTable.expected_cost;
      info.body.markup = this.itemsTable.markup;
      info.body.markup_percentage = this.itemsTable.markup_percentage;
      info.body.insurance_required = this.checkboxes.insurance_required;
      info.body.requoted = this.checkboxes.requoted;
      info.body.x1tc = this.checkboxes.x1tc;
      info.body.project_cargo = this.checkboxes.project_cargo;
      info.body.xenon_quote = this.checkboxes.xenon_quote;
      info.body.shipper = this.checkboxes.shipper;
      info.body.density_class = this.densityClass;
      info.body.carriers = this.carriers;
      info.body.message = this.messageHaul;
      this.checkFields = this.fieldsValidation();
      if (this.checkFields) {
        const response = await this.$store.dispatch("rules/createHaul", info);
        if (response.status === 200) {
          this.haulResponse = response.data;
          this.$refs['my-modal'].show();
        }
      } else {
        this.$swal.fire({
          icon: "warning",
          title: `Oops..., ${this.missingFields} is a required field`,
          showConfirmButton: false,
          timer: 1500,
        });
      }
      this.isLoadingCreation = false;
    },
    pickupAndDelivery(value) {
      this.haul.pickupAndDelivery = value;
    },
    buildLineItems() {
      this.lineItems = [];
      for (let i = 0; i < this.itemsTable.field_items.length; i += 1) {
        const row = {};
        for (let j = 0; j < this.itemsTable.field_items[i].length; j += 1) {
          Object.entries(this.itemsTable.field_items[i][j]).forEach(([key]) => {
            row[key] = this.itemsTable.field_items[i][j][key];
          });
        }
        this.lineItems.push(row);
        this.lineItems[
          i].line_item_volume_units = this.itemsTable.field_items.line_item_volume_units;
        this.lineItems[i].line_item_volume = this.itemsTable.field_items.line_item_volume;
      }
    },
    changeAccessorials() {
      this.selectedAccessorials = [];
      for (let i = 0; i < this.haul.pickupAndDelivery.from.accessorials.length; i += 1) {
        this.selectedAccessorials.push(this.haul.pickupAndDelivery.from.accessorials[i]);
      }
      for (let i = 0; i < this.haul.pickupAndDelivery.to.accessorials.length; i += 1) {
        this.selectedAccessorials.push(this.haul.pickupAndDelivery.to.accessorials[i]);
      }
      for (let i = 0; i < this.haul.accessorials.length; i += 1) {
        this.selectedAccessorials.push(this.haul.accessorials[i]);
      }
      for (let i = 0; i < this.selectedAccessorials.length; i += 1) {
        this.selectedAccessorials[i] = {
          id: this.selectedAccessorials[i].accessorial_id,
          name: this.selectedAccessorials[i].accessorial_name,
          accessorial_type: this.selectedAccessorials[i].accessorial_type,
          tms_code: {},
          description: this.selectedAccessorials[i].accessorial_description,
        };
      }
    },
    fieldsValidation() {
      let count = 0;
      if (this.tmsMode) {
        if (!this.tmsMode.name && (this.tmsMode.id || this.tmsMode.tms_code)) {
          this.missingFields = "Mode name";
          return false;
        }
        count += 1;
      }
      if (!this.carriers || this.carriers?.length === 0) {
        this.missingFields = "Carrier";
        return false;
      }
      if (this.carriers) {
        for (let index = 0; index < this.carriers.length; index += 1) {
          if (!this.carriers[index].carrier) {
            this.missingFields = "Carrier";
            return false;
          }
          if (!this.carriers[index].scac) {
            this.missingFields = "Carrier Scac";
            return false;
          }
          if (!this.carriers[index].price) {
            this.missingFields = "Carrier price";
            return false;
          }
        }
        count += 1;
      }
      if (count === 3) {
        return true;
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.create-hauls {
  display: flex;
  &__sidebar {
    width: 8%;
  }
  &__container {
    width: 92%;
    margin-top: 2rem;
    padding: 0 2rem 2rem;
    &__tittle {
      font-weight: bold;
    }
    &__separator {
      width: 95%;
      margin: auto;
    }
  }
  &__selects {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
  &__button {
    @include submit-button;
  }
}
.input-text {
  padding-top: 1rem;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  justify-content: space-between;
  &__input {
    width: auto !important;
  }
  &__density {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    &--label {
      margin: 0 10px;
    }
  }
}
.checkbox {
  display: flex;
  justify-content: space-between;
  margin: 10px 5px;
  flex-wrap: wrap;
}
:deep(.p-inputtext) {
  @include haul-input;
}
:deep(.modal-dialog) {
  max-width: 1500px;
}
:deep(.p-accordion) {
  margin: 10px 0;
}
</style>
