const tableFields = [
  { key: "actions", label: "Actions" },
  {
    key: "hu_count",
    label: "hu_count",
  },
  {
    key: "dimensions",
    label: "dimensions",
  },
  {
    key: "weight",
    label: "weight",
  },
  {
    key: "contribution",
    label: "contribution",
  },
  {
    key: "nmfc_class",
    label: "nmfc_class",
  },
  {
    key: "description",
    label: "description",
  },
  {
    key: "linear_feet",
    label: "stackable",
  },
  {
    key: "expected_cost",
    label: "hazmat",
  },
];

export default tableFields;
