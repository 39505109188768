<template>
  <div class="input-container">
    <el-input
      v-if="paramData.fieldType !== 'dict'"
      :key="renderComponent"
      type="textarea"
      :autosize="{ minRows: 1, maxRows: 2 }"
      class="input-container__text-area"
      :style="`border: 1px solid ${fieldColor[paramIndex]}`"
      :placeholder="paramData.label"
      :name="paramData.name + optionNumber"
      :id="paramData.name + optionNumber"
      v-model="inputText[paramIndex]"
      @keyup.enter="
        actionConstructor(inputText[paramIndex], paramData.name, paramData.fieldType, paramIndex)
      "
      @blur="
        actionConstructor(inputText[paramIndex], paramData.name, paramData.fieldType, paramIndex)
      "
      @keydown.tab="
        actionConstructor(inputText[paramIndex], paramData.name, paramData.fieldType, paramIndex)
      "
    >
    </el-input>
    <div v-else>
      <input
        v-for="(data, i) in dictType"
        :key="i"
        class="input-container__input"
        :name="data.name + optionNumber"
        type="text"
        :placeholder="'Data ' + data.label"
        :id="data.name + optionNumber"
        v-model="inputTextDict[i]"
        @keydown.tab="actionConstructor(inputTextDict[i], data.name, paramData.fieldType)"
        @blur="actionConstructor(inputTextDict[i], data.name, paramData.fieldType)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "Input",
  props: {
    optionNumber: {
      type: Number,
      required: false,
    },
    paramData: {
      type: Object,
      required: false,
    },
    paramIndex: {
      type: Number,
      required: false,
    },
    renderComponent: {
      type: Number,
      required: false,
    },
    inputText: {
      type: Array,
    },
    inputTextDict: {
      type: Array,
    },
    fieldColor: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      dictType: [
        { name: "en", label: "English" },
        { name: "es", label: "Spanish" },
      ],
    };
  },
  created() {},
  methods: {
    actionConstructor(text, name, type, index) {
      this.$emit("actionConstructor", text, name, type, index);
    },
  },
};
</script>

<style lang="scss" scoped>
.input-container {
  margin: 0 .5rem;
  &__line {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
  }
  &__input {
    margin: 5px .5rem;
    background-color: #f3f3f3;
    border: solid 1px #d9d9d9;
    padding-left: 5px;
    color: #495057;
    height: 33px;
  }
  &__text-area {
    padding-left: 0;
    border: solid 1px #d9d9d9;
  }
}
</style>
