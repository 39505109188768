<template>
  <div>
    <!-- MODAL -->
    <b-modal
      hide-footer
      class="department-modal"
      ref="new-department-modal"
      size="lg"
      :title="modalTitle"
    >
      <div class="department-modal__row">
        <div class="department-modal__column">
          <label class="department-modal__column__text" for="name">
            Name <b style="color: red">*</b></label>
          <InputText
            class="department-modal__column__input"
            :class="errors.name"
            :key="renderInput"
            v-model="newDepartment.name"
            @change="validateField(newDepartment.name, 'name')"
            id="name"
            type="text"
          />
        </div>
        <div class="department-modal__column">
          <label class="department-modal__column__text" for="description">
            Description <b style="color: red">*</b></label>
          <InputText
            class="department-modal__column__input"
            :class="errors.description"
            :key="renderInput"
            v-model="newDepartment.description"
            @change="validateField(newDepartment.description, 'description')"
            id="description"
            type="text"
          />
        </div>
      </div>
      <div v-if="userInfo.role === 'global_admin'">
        <Checkbox class="department-modal__checkbox" v-model="associateProfile" :binary="true" />
        <label class="department-modal__checkbox__text">Associate profile</label>
      </div>
      <div class="department-modal__row" v-if="userInfo.role === 'global_admin'">
        <div class="department-modal__column">
          <label class="department-modal__column__text">
            Company <b style="color: red">*</b></label>
          <Dropdown
            class="department-modal__column__input"
            v-model="newDepartment.company_id"
            :options="companies"
            optionLabel="name"
            optionValue="id"
            :disabled="!canEdit"
          />
        </div>
        <div v-if="associateProfile" class="department-modal__column">
          <label class="department-modal__column__text">
            Profile <b style="color: red">*</b></label>
          <Dropdown
            class="department-modal__column__input"
            v-model="newDepartment.profile_id"
            :options="profiles"
            optionLabel="name"
            optionValue="id"
          />
        </div>
      </div>
      <b-overlay class="overlay-button" :show="isLoading" rounded opacity="0.6" spinner-small>
        <b-button
          v-if="modalTitle !== 'Edit Department'"
          @click="createDepartment()"
          class="department-modal__button"
          :disabled="!canCreate"
          >Create Department
        </b-button>
        <b-button v-else
        @click="verifyUpdate()" class="department-modal__button" :disabled="!canUpdate">
          Save Changes
        </b-button>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import { successMessage, deleteMessage } from "@/utils/alertMessages";

export default {
  name: "CreateDepartmentModal",
  data() {
    return {
      modalTitle: "",
      associateProfile: false,
      isLoading: false,
      canCreate: false,
      canUpdate: false,
      canEdit: true,
      companies: [],
      profiles: [],
      newDepartment: {
        name: "",
        description: "",
        company_id: null,
        profile_id: null,
      },
      departmentId: null,
      userInfo: null,
      errors: {},
      renderInput: 0,
    };
  },
  async created() {
    this.userInfo = JSON.parse(localStorage.getItem("user-info"));
    if (this.userInfo.role === "global_admin") {
      const companiesData = await this.$store.dispatch("company/getAllCompanies");
      this.companies = companiesData.data;
      const profilesData = await this.$store.dispatch("profile/getAllProfiles");
      this.profiles = profilesData.data;
    } else if (this.userInfo.role === "company_admin") {
      this.newDepartment.company_id = this.userInfo.company_id;
    }
  },
  watch: {
    newDepartment: {
      handler() {
        this.validateForm();
      },
      deep: true,
    },
    associateProfile() {
      this.validateForm();
    },
  },
  methods: {
    validateForm() {
      if (this.newDepartment.name === '' || this.newDepartment.description === '' || this.newDepartment.company_id === null) {
        this.canCreate = false;
        this.canUpdate = false;
      } else if (this.associateProfile && this.newDepartment.profile_id === null) {
        this.canCreate = false;
        this.canUpdate = false;
      } else {
        this.canCreate = true;
        this.canUpdate = true;
      }
    },
    validateField(value, input) {
      this.errors[input] = !value ? "error" : "input-valid";
      this.renderInput += 1;
    },
    cleanColor() {
      this.errors.name = '';
      this.errors.description = '';
    },
    async showModal(option) {
      this.cleanColor();
      this.associateProfile = false;
      if (option) {
        this.departmentId = option.department_id;
        const params = {
          department_id: option.department_id,
          company_id: option.company_id,
        };
        this.modalTitle = "Edit Department";
        this.canEdit = false;
        const info = await this.$store.dispatch("company/getDepartmentsPerCompany", params);
        const department = await this.$store.dispatch("department/getDepartmentById", params.department_id);
        this.newDepartment = {
          name: department.name,
          description: department.description,
          company_id: info.data[0].company_id,
          profile_id: info.data[0].profile_id,
        };
        this.validateField(this.newDepartment.name, 'name');
        this.validateField(this.newDepartment.description, 'description');
      } else {
        this.modalTitle = "Add New Department";
        this.canEdit = true;
        this.newDepartment = {
          name: "",
          description: "",
          company_id: null,
          profile_id: null,
        };
      }
      this.$refs["new-department-modal"].show();
    },
    async createDepartment() {
      this.isLoading = true;
      const payload = {
        name: this.newDepartment.name,
        description: this.newDepartment.description,
        company_id: [this.newDepartment.company_id],
      };
      if (this.associateProfile) {
        payload.profile_id = [this.newDepartment.profile_id];
      }
      const response = await this.$store.dispatch("company/createDepartment", payload);
      this.isLoading = false;
      if (response.status === 200 || response.status === 201) {
        this.$refs["new-department-modal"].hide();
        successMessage(`The department has been created.`);
        this.isLoading = false;
        const event = {
          index: 3,
        };
        this.$emit("getData", event);
      }
    },
    async verifyUpdate() {
      const warningResponse = await deleteMessage(
        'Are you sure you want to update the department?',
        "You won't be able to revert this!",
      );
      if (warningResponse.isConfirmed) {
        this.updateDepartment();
      }
    },
    async updateDepartment() {
      this.isLoading = true;
      const params = {
        department_id: this.departmentId,
        data: this.newDepartment,
      };
      const response = await this.$store.dispatch("department/updateDepartment", params);
      this.isLoading = false;
      if (response.status === 200 || response.status === 204) {
        this.$refs["new-department-modal"].hide();
        successMessage(`The department has been updated.`);
        this.isLoading = false;
        const event = {
          index: 3,
        };
        this.$emit("getData", event);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.department-modal {
  &__button {
    @include submit-button;
  }
  &__row {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
  }
  &__column {
    display: flex;
    flex-direction: column;
    width: 48%;
    &__text {
      color: $color-input-text;
      margin-left: 0.2rem;
    }
    &__input {
      @include input;
    }
  }
  &__checkbox {
    margin: 0 .5rem 0 1rem;
    &__text {
      color: $color-input-text;
      margin-top: 0.2rem;
    }
  }
}
.overlay-button {
  display: flex;
  margin: auto;
  justify-content: center;
  padding: 2rem 1rem 1rem;
}
:deep(.p-dropdown-label) {
  padding-top: 0.3rem;
}
.error {
  border-color: $color-input-error;
}
.input-valid {
  border: 1px solid #00b900;
}
</style>
