<template>
  <div class="accessorials">
    <div class="accessorials__sidebar">
      <Sidebar />
    </div>
    <div class="accessorials__content">
      <h1 class="accessorials__content--tittle">Accessorials</h1>
      <hr class="accessorials__content--separator" />
      <!-- Accessorials TABLE -->
      <Table
        @openModal="openModal"
        type="Accessorial"
        class="mt-5"
        ref="AccessorialsTable"
      />

      <!-- MODAL -->
      <CreateAccessorial
        :rowData="rowData"
        :modalType="modalType"
        @hideModal="hideModal"
        @getAccessorials="getAccessorials"
        ref="createAccessorial"
      />
    </div>
  </div>
</template>

<script>
import Table from "@/components/Table.vue";
import Sidebar from "@/components/Sidebar.vue";
import CreateAccessorial from "./Components/CreateAccessorial.vue";

export default {
  name: "Accessorials",
  components: {
    Table,
    Sidebar,
    CreateAccessorial,
  },
  data() {
    return {
      // Variables que no se estan usando?
      isPre: true,
      isPost: false,
      contractsFile: null,
      accessControl: false,
      rowData: null,
      modalType: null,
    };
  },
  methods: {
    async openModal(dataModal) {
      this.rowData = null;
      const modalInfo = dataModal.rowInfo;
      this.modalType = dataModal.modalType;
      if (modalInfo && !modalInfo.accessorial_type) {
        const params = {
          accessorial_id: modalInfo.accessorial_id || modalInfo.id,
        };
        const response = await this.$store.dispatch("accessorial/getAccessorialById", params);
        this.rowData = response.status === 200 ? response.data : modalInfo;
        setTimeout(() => { this.$refs.createAccessorial.show(); }, 500);
      } else {
        this.rowData = dataModal.rowInfo;
        this.$refs.createAccessorial.show();
      }
    },
    hideModal() {
      this.rowData = null;
    },
    getAccessorials() {
      this.$refs.AccessorialsTable.updateInfoByType();
    },
  },
};
</script>

<style lang="scss" scoped>
.accessorials {
  display: flex;
  &__sidebar {
    width: 9%;
  }
  &__content {
    width: 91%;
    margin-top: 2rem;
    &--tittle {
      font-weight: bold;
    }
    &--separator {
      width: 95%;
      margin: auto;
    }
  }
}
@media (max-width: 900px) {
  .accessorials {
    &__sidebar {
      width: 0%;
    }
    &__content {
      width: 98%;
      margin-top: 7rem;
    }
  }
}
</style>
