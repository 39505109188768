<template>
  <div class="select-multiple-container">
    <MultiSelect
      v-if="paramData.options"
      class="select-multiple-container__select"
      :id="paramData.name + optionNumber"
      v-model="inputText[paramIndex]"
      :options="paramData.options"
      :placeholder="paramData.label"
      :filter="true"
      @change="actionConstructor(inputText[paramIndex], paramData.name, paramData.fieldType)"
    />
    <b-form-tags v-else
      class="select-multiple-container__input"
      :inputId="paramData.name + optionNumber"
      v-model="inputText[paramIndex]"
      :placeholder="paramData.label"
      separator=" "
      @input="actionConstructor(inputText[paramIndex], paramData.name, paramData.fieldType)"
    ></b-form-tags>
  </div>
</template>

<script>
export default {
  name: "SelectMultiple",
  props: {
    optionNumber: {
      type: Number,
      required: false,
    },
    paramData: {
      type: Object,
      required: false,
    },
    paramIndex: {
      type: Number,
      required: false,
    },
    inputText: {
      type: Array,
    },
  },
  data() {
    return {
      selectedAction: "",
    };
  },
  created() {},
  methods: {
    actionConstructor(text, name, type) {
      this.$emit("actionConstructor", text, name, type);
    },
  },
};
</script>

<style lang="scss" scoped>
.select-multiple-container {
  margin: 0 .5rem;
 &__input {
    margin: 5px 0;
    background-color: #f3f3f3;
    border: solid 1px #d9d9d9;
    padding-left: 5px;
    color: #495057;
    height: 33px;
  }
  &__select {
    width: 100%;
    margin: 5px 0;
    background-color: #f3f3f3;
    border: solid 1px #d9d9d9;
    color: #495057;
    height: 33px;
    border-radius: 0;
  }
}
</style>
