<template>
  <div class="multiple-select-container">
    <label :for="inputType.name">{{ inputType[0].category }} - {{ inputType[0].label }}</label>
    <Dropdown
      class="multiple-select-container__input"
      :name="inputType.name"
      v-model="optionSelected"
      :options="options"
      optionLabel="name"
      placeholder="Operator"
      :filter="true"
      @change="operatorChange(true)"
    >
      <template #option="slotProps">
        <div
          class="p-dropdown-car-option"
          v-tooltip="{ value: slotProps.option.description, disabled: false }"
        >
          <span>{{ slotProps.option.name }}</span>
        </div>
      </template>
    </Dropdown>
    <!--<select
      class="multiple-select-container__input"
      :name="inputType.name"
      v-model="optionSelected"
    >
      <option
        v-for="(option, index) in options"
        :key="index"
      >
        {{ option.name }}
      </option>
    </select>-->
    <b-button
      v-if="multiselectOptions?.length === 0"
      v-tooltip="{ value: msgChangeSeparator, disabled: false }"
      pill
      size="sm"
      @click="changeLetterSeparation()"
      >{{ changeSeparator ? "↦A↤" : "↤A↦" }}</b-button
    >
    <MultiSelect
      v-if="multiselectOptions?.length > 0"
      :placeholder="inputType[0].label"
      class="multiple-select-containert__multiselect"
      :class="parametersCompletedSelect"
      :style="multiselectColor"
      v-model="conditionValue"
      :options="multiselectOptions"
      :filter="true"
      @change="conditionConstructor"
      :selectionLimit="selectionLimit"
      :disabled="!isOperatorSelected"
    />
    <b-form-tags
      v-else
      v-model="conditionValue"
      :placeholder="inputType[0].label"
      separator=",;"
      :state="parametersCompletedTags"
      :limit="selectionLimit"
      :disabled="!isOperatorSelected"
      @input="conditionConstructor()"
    ></b-form-tags>
    <!--<Chips
      v-else
      :class="parametersCompleted"
      v-model="conditionValue"
      separator=","
      @add="conditionConstructor"
      @remove="conditionConstructor"
      :max="selectionLimit"
      :disabled="!isOperatorSelected"
    />-->
    <p class="multiple-select-container__help-message">
      {{ message }}
    </p>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

import MultiSelect from "primevue/multiselect";

export default {
  name: "SelectMultiple",
  props: {
    inputType: {
      type: Array,
      default: null,
    },
    conditionEditData: {
      type: Object,
      default: null,
    },
  },
  components: {
    MultiSelect,
  },
  data() {
    return {
      optionSelected: null,
      conditionValue: [],
      options: [],
      typeInput: null,
      selectionLimit: null,
      selectionMinimum: null,
      message: "",
      parametersCompletedSelect: "",
      isOperatorSelected: false,
      parametersCompletedTags: null,
      multiselectColor: "",
      changeSeparator: true,
      msgChangeSeparator: "Unir texto en una etiqueta.",
    };
  },
  created() {
    this.isOperatorSelected = !!this.conditionEditData.operator;
    this.labelConstructor();
    if (this.conditionEditData?.value || this.conditionEditData?.value?.length > 0) {
      this.conditionEditConstructor();
    } else {
      [this.optionSelected] = this.options;
      this.parametersCompletedSelect = "p-invalid";
    }
    this.operatorChange();
  },
  computed: {
    ...mapGetters("rules", ["showRuleCompleteObject"]),
  },
  methods: {
    conditionEditConstructor() {
      // eslint-disable-next-line no-unused-expressions
      if (typeof this.conditionEditData.value === "string") {
        this.conditionValue.push(this.conditionEditData.value);
      } else {
        this.conditionEditData.value.forEach((item) => {
          this.conditionValue.push(item);
        });
      }
      // this.optionSelected = this.conditionEditData.operator;
      [this.optionSelected] = this.options.filter(
        (element) => element.name === this.conditionEditData.operator,
      );
    },
    operatorChange() {
      this.isOperatorSelected = true;
      // this.conditionValue = !ifChange ? this.conditionValue : [];
      this.selectionLimit = this.optionSelected.max_item;
      this.selectionMinimum = this.optionSelected.min_item;
      const amount = this.selectionLimit === this.selectionMinimum ? "exactly" : "at least";
      this.message = `*This operator requires ${amount} ${this.selectionMinimum} parameter(s)`;
      this.conditionConstructor();
    },
    verifyParameters() {
      const isMinimum = this.conditionValue?.length >= this.selectionMinimum;
      if (this.selectionLimit === null) {
        this.parametersCompletedSelect = isMinimum ? "" : "p-invalid";
        this.multiselectColor = isMinimum ? "border: 1px solid #00b900" : "";
        this.parametersCompletedTags = isMinimum;
      } else {
        const maximum = this.conditionValue?.length <= this.selectionLimit;
        this.parametersCompletedSelect = isMinimum && maximum ? "" : "p-invalid";
        this.multiselectColor = isMinimum ? "border: 1px solid #00b900" : "";
        this.parametersCompletedTags = isMinimum && maximum;
      }
    },
    conditionConstructor() {
      this.verifyParameters();
      const infoCondition = {};
      infoCondition.name = this.inputType[0].name;
      infoCondition.operator = this.optionSelected.name;
      // infoCondition.value = this.conditionValue.toString();
      infoCondition.value = this.conditionValue;
      this.$emit("conditionObject", infoCondition);
    },
    async labelConstructor() {
      this.typeInput = this.inputType[0].field_type;
      this.multiselectOptions = this.inputType[0]?.options;
      const ruleData = this.showRuleCompleteObject;
      const variableTypeOperators = ruleData?.variable_type_operators[this.typeInput];
      for (let i = 0; i < variableTypeOperators?.length; i += 1) {
        this.options.push({
          name: variableTypeOperators[i].name,
          description: variableTypeOperators[i].description,
          min_item: variableTypeOperators[i].min_items,
          max_item: variableTypeOperators[i].max_items,
        });
      }
    },
    changeLetterSeparation() {
      const conditionValueCopy = [...this.conditionValue];
      this.changeSeparator = !this.changeSeparator;
      this.conditionValue = [];
      this.conditionValue = this.changeSeparator
        ? this.separateText(conditionValueCopy)
        : this.joinText(conditionValueCopy);
      this.conditionConstructor();
    },
    separateText(conditionArray) {
      this.msgChangeSeparator = "Unir texto en una etiqueta.";
      let stringsArrays = [];
      conditionArray.forEach((string) => {
        let str = string;
        str = str.split(" ");
        stringsArrays = stringsArrays.concat(str);
      });
      return stringsArrays;
    },
    joinText(conditionArray) {
      this.msgChangeSeparator = "Separar texto en etiquetas.";
      const stringsArrays = [];
      stringsArrays.push(conditionArray.join((' ')));
      return stringsArrays;
    },
  },
};
</script>

<style lang="scss" scoped>
.multiple-select-container {
  align-items: center;
  @include rules-input;
  &__multiselect {
    @include rules-multiselect;
  }
  &__help-message {
    color: $color-input-text;
    font-size: 12px;
    padding-left: 0.5rem;
    margin: auto;
  }
}
:deep(.p-multiselect) {
  background-color: #f3f3f3;
  border-color: #d9d9d9;
  height: 28px;
}
:deep(.p-multiselect-label) {
  padding-top: 0 !important;
}
:deep(.p-multiselect-trigger-icon) {
  font-size: 11px;
  font-weight: bold;
}
:deep(.p-multiselect-trigger) {
  width: 1.2rem;
}
:deep(.p-chips-multiple-container) {
  height: 28px;
}
:deep(.p-chips-token) {
  height: 20px;
  font-size: 15px;
}
:deep(.p-chips-token-icon) {
  font-size: 13px;
}
:deep(.p-chips-multiple-container.p-focus) {
  box-shadow: none !important;
}
:deep(.p-dropdown-label) {
  padding-top: 0 !important;
}
:deep(.p-dropdown-trigger-icon) {
  font-size: 11px;
  font-weight: bold;
}
:deep(.p-dropdown-trigger) {
  width: 1.2rem;
}
:deep(.b-form-tags) {
  width: 50%;
}
</style>
