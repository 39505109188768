import Repository from "../../services/repositories/RepositoryFactory";
import { errorMessage, errorValidationMessage, tokenExpired } from '../../utils/alertMessages';

const CompanyRepository = Repository.service("companies");

export default {
  namespaced: true,
  state: {
    profileCompanysDepartment: null,
  },
  getters: {
    showProfileCompanysDepartment(state) {
      return state.profileCompanysDepartment;
    },
  },
  mutations: {
    setProfileCompanysDepartment(state, newProfileCompanysDepartment) {
      state.profileCompanysDepartment = newProfileCompanysDepartment;
    },
  },
  actions: {
    async getAllCompanies(_, params) {
      try {
        const response = await CompanyRepository.getAllCompanies(params);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
    async getCompanyById(_, userId) {
      try {
        const response = await CompanyRepository.getCompanyById(userId);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
    async getInfoCompany(_, payload) {
      try {
        const response = await CompanyRepository.getInfoCompany(payload);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
    async createCompany(_, payload) {
      try {
        const response = await CompanyRepository.createCompany(payload);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          if (error.response.data.detail.match('duplicate key value')) {
            return errorValidationMessage('This company exists, please check the data and try again.');
          }
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
    async updateCompany(_, payload) {
      try {
        const response = await CompanyRepository.updateCompany(payload);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          if (error.response.data.detail.match('duplicate key value')) {
            return errorValidationMessage('This company exists, please check the data and try again.');
          }
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
    async deleteCompany(_, payload) {
      try {
        const response = await CompanyRepository.deleteCompany(payload);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
    async getProfilesPerCompany(_, payload) {
      try {
        const response = await CompanyRepository.getProfilesPerCompany(payload);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
    async getDepartmentsPerCompany(_, payload) {
      try {
        const response = await CompanyRepository.getDepartmentsPerCompany(payload);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
    async createProfilesAssociation(_, payload) {
      try {
        const response = await CompanyRepository.createProfilesAssociation(payload);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
    async updateProfilesAssociation(_, payload) {
      try {
        const response = await CompanyRepository.updateProfilesAssociation(payload);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
    async createDepartment(_, payload) {
      try {
        const response = await CompanyRepository.createDepartment(payload);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          if (error.response.data.detail.match('duplicate key value')) {
            return errorValidationMessage('This department exists, please check the data and try again.');
          }
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
    async deleteAssociation(_, payload) {
      try {
        const response = await CompanyRepository.deleteAssociation(payload);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
    async getProfileCompanysDepartment({ commit }, payload) {
      try {
        const response = await CompanyRepository.getProfileCompanysDepartment(payload);
        commit('setProfileCompanysDepartment', response.data);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
  },
};
