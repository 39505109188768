<template>
  <el-dropdown
    placement="bottom-start" class="dropdown-component" size="small" @command="handleCommand">
    <el-button type="primary" plain class="dropdown-component__button">
      <b-icon :icon="iconButton"></b-icon> {{ titleButton }}
    </el-button>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item v-for="element in itemsList" :key="element.value" :command="element.value">
        {{ element.text }}</el-dropdown-item
      >
    </el-dropdown-menu>
  </el-dropdown>
</template>
<script>
export default {
  name: "DropdownComponent",
  props: {
    itemsList: Array,
    titleButton: String,
    iconButton: String,
    index: Number,
  },
  components: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  methods: {
    handleCommand(command) {
      this.$emit('selectOption', { command, index: this.index });
    },
  },
};
</script>
<style scoped lang="scss">
.dropdown-component__button {
    @include option-rules-button;
    margin: 10px 5px;
    width: auto;
    font-size: 14px;
    font-weight: 700;
    &:active, &:hover, &:focus {
      border: 3px solid $color-primary-button !important;
      color: $color-primary-button !important;
      border-style: dashed dotted !important;
      background: none !important;
      background-color: transparent !important;
      box-shadow: none !important;
    }
  }

.el-dropdown {
  vertical-align: top;
}
.el-dropdown + .el-dropdown {
  margin-left: 15px;
}
.el-icon-arrow-down {
  font-size: 12px;
}
</style>
