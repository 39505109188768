<template>
  <div class="editable-tables">
    <div class="editable-tables__collapse-buttons">
      <b-button @click="visibleQuote = !visibleQuote" class="editable-tables__button"
        ><b-icon
          class="mr-2"
          :icon="visibleQuote ? 'chevron-double-up' : 'chevron-double-down'"
        ></b-icon
        >Quote Information</b-button
      >
      <b-button @click="visibleFreight = !visibleFreight" class="editable-tables__button"
        ><b-icon
          class="mr-2"
          :icon="visibleFreight ? 'chevron-double-up' : 'chevron-double-down'"
        ></b-icon
        >Freight Details</b-button
      >
    </div>
    <b-collapse v-model="visibleQuote">
      <div class="editable-tables__action-table">
        <b-button
          class="editable-tables__add-column"
          @click="addColumn()"
          >Add new column</b-button>
        <b-button
          class="editable-tables__delete-column"
          @click="deleteColumn()"
          >Delete column</b-button
        >
      </div>
      <b-table
        v-if="quoteInformation"
        outlined
        class="editable-tables__horizontal-table"
        responsive
        :items="quoteInformation[0].table_content"
        :fields="quoteInformation[0].fields"
        :key="renderTable"
      >
        <template #thead-top>
          <b-th class="text-center" :colspan="quoteInformation[0].fields.length">{{
            quoteInformation[0].header[0]
          }}</b-th>
        </template>
        <template #head()="row">
          <b-input
            @change="changeFields()"
            v-model="fields[row.field.key]"
          ></b-input>
        </template>
        <template #cell()="row">
          <b-input @change="changeFields()" v-model="row.item[row.field.key]"></b-input>
        </template>
      </b-table>
    </b-collapse>
    <b-collapse v-model="visibleFreight">
      <div class="editable-tables__action-table">
        <b-button class="editable-tables__add-row" @click="addRow()">Add new row</b-button>
        <b-button
          class="editable-tables__delete-column"
          @click="deleteFreightRows()"
          >Delete column</b-button
        >
      </div>
      <b-table
        v-if="freightDetails"
        outlined
        class="editable-tables__vertical-table"
        responsive
        :items="freightDetails[0].table_content"
        :key="renderTable"
      >
        <template #thead-top>
          <b-th class="text-center" colspan="3">{{ freightDetails[0].header[0] }}</b-th>
        </template>
        <template #head()>
          <b-tr></b-tr>
        </template>
        <template #cell(key)="row">
          <b-input @change="changeFields()" v-model="row.item.key"></b-input>
        </template>
        <template #cell(value)="row">
          <b-input @change="changeFields()" v-model="row.item.value"></b-input>
        </template>
        <!-- <template #cell(actions)="row">
          <b-button class="editable-tables__delete-row" @click="deleteFreightRows(row.index)"
            ><b>X</b></b-button
          >
        </template> -->
      </b-table>
    </b-collapse>
    <b-button class="editable-tables__save-table" @click="saveTables()"
      >Save Tables <b-icon v-if="savedTable" icon="check-circle"></b-icon
    ></b-button>
  </div>
</template>

<script>
export default {
  name: "FreightAndQuoteTable",
  props: ["cannedTablesInfo", "typeCrud"],
  data() {
    return {
      freightDetails: null,
      quoteInformation: null,
      fields: {
        mode: "mode",
        equipment: "equipment",
        transit_time: "transit_time",
        rate: "rate",
      },
      disableFields: false,
      index: 1,
      freighAndQuoteInfo: null,
      renderTable: 0,
      visibleQuote: true,
      visibleFreight: true,
      savedTable: false,
      buttonDisabled: false,
    };
  },
  created() {
    this.freightDetails = this.cannedTablesInfo.freightDetails;
    this.quoteInformation = this.cannedTablesInfo.quoteInformation;
    this.changeArrayToObject();
  },
  methods: {
    deleteFreightRows() {
      if (this.freightDetails[0].table_content.length > 1) {
        const lastString = this.freightDetails[0].table_content[
          this.freightDetails[0].table_content.length - 1
        ];
        delete this.fields[lastString];
        this.freightDetails[0].table_content.pop();
      }
    },
    addRow() {
      this.freightDetails[0].table_content.push({
        key: "",
        value: "",
        actions: "",
      });
    },
    addColumn() {
      this.quoteInformation[0].fields.splice(this.quoteInformation[0].fields.length, 0, `new_column_${this.index}`);
      this.index += 1;
    },
    deleteColumn() {
      if (this.quoteInformation[0].fields.length > 2) {
        const lastString = this.quoteInformation[0].fields[
          this.quoteInformation[0].fields.length - 1
        ];
        delete this.fields[lastString];
        delete this.quoteInformation[0].table_content[0][lastString];
        this.quoteInformation[0].fields.pop();
      }
    },
    saveTables() {
      this.updateFieldValues();
      this.rename();
      const arrayFields = Object.values(this.fields);
      this.quoteInformation[0].fields = arrayFields;
      this.freighAndQuoteInfo = {
        freightDetails: this.freightDetails[0],
        quoteInformation: this.quoteInformation[0],
      };
      this.$emit("freighAndQuoteInfo", this.freighAndQuoteInfo);
      this.savedTable = true;
      this.renderTable += 1;
    },
    changeArrayToObject() {
      if (this.typeCrud === "edit") {
        if (this.cannedTablesInfo) {
          const objectFields = {};
          for (let i = 0; i < this.quoteInformation[0].fields.length; i += 1) {
            const obj = new Map([
              [this.quoteInformation[0].fields[i], this.quoteInformation[0].fields[i]],
            ]);
            const changeObject = Object.fromEntries(obj);
            objectFields[this.quoteInformation[0].fields[i]] = changeObject[
              this.quoteInformation[0].fields[i]
            ];
          }
          this.fields = objectFields;
        }
      }
    },
    updateFieldValues() {
      for (let i = 0; i < this.quoteInformation[0].table_content.length; i += 1) {
        Object.entries(this.fields).forEach(([key, value]) => {
          if (key === "") {
            delete this.quoteInformation[0].table_content[i][key];
          }
          // eslint-disable-next-line no-prototype-builtins
          if (!this.quoteInformation[0].table_content[i].hasOwnProperty(value)) {
            this.quoteInformation[0].table_content[i][
              value
            ] = this.quoteInformation[0].table_content[i][key];
            delete this.quoteInformation[0].table_content[i][key];
            // eslint-disable-next-line no-prototype-builtins
            if (!this.fields.hasOwnProperty(value)) {
              this.fields[value] = this.fields[key];
              delete this.fields[key];
            }
          }
        });
      }
    },
    changeFields() {
      this.savedTable = false;
    },
    renameKey(obj, oldKey, newKey) {
      if (oldKey !== newKey) {
        Object.defineProperty(
          obj, newKey, Object.getOwnPropertyDescriptor(obj, oldKey),
        );
        const copyObj = { ...obj };
        delete copyObj[oldKey];
        delete this.quoteInformation[0].table_content[0][oldKey];
        if (this.quoteInformation[0].table_content[0][newKey] === undefined) {
          this.quoteInformation[0].table_content[0][newKey] = "";
        }
      }
    },
    rename() {
      Object.keys(this.fields).forEach((element) => {
        const elementLowerCase = element.toLowerCase();
        this.renameKey(this.quoteInformation[0].table_content[0], element, elementLowerCase);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.editable-tables) {
  &__collapse-buttons {
    display: flex;
    flex-direction: row;
    margin: 3rem 0;
    flex-wrap: wrap;
  }
  &__button {
    margin: 1rem 1rem 0 0;
    @include secondary-button;
  }
  &__action-table {
    display: flex;
  }
  &__add-column, &__add-row {
    @include submit-button;
    display: flex;
    margin: 1rem 0 1rem;
  }
  &__delete-column {
    background: none;
    color: $color-cancel-button;
    border: 1px solid $color-cancel-button;
    margin: 1rem 1rem 1rem;
  }
  &__delete-row {
    background: none;
    color: $color-cancel-button;
    border: none;
  }
  &__horizontal-table,
  &__vertical-table {
    & .table th,
    .table td {
      padding: 0;
      border: none;
      border-radius: 0;
    }
  }
  &__save-table {
    margin-top: 2rem;
    @include submit-button;
  }
}
@media (max-width: 400px) {
  .editable-tables__action-table {
    flex-wrap: wrap;
  }
  .editable-tables__delete-column {
    margin: 0 0 1rem;
  }
}
</style>
