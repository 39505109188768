import Repository from "../../services/repositories/RepositoryFactory";
import { errorValidationMessage, errorMessage, tokenExpired } from "../../utils/alertMessages";

const UserRepository = Repository.service("users");

export default {
  namespaced: true,
  // ----------------------------------------------------------------------------------
  state: {
    user: {
      username: "",
      role: "",
      company_info: [],
    },
    users: [],
    currentCompanyAndDepartment: null,
  },
  // ----------------------------------------------------------------------------------
  getters: {
    getUsername(state) {
      return state.user.username;
    },
    getUserRole(state) {
      return state.user.role;
    },
    getUserCompanies(state) {
      return state.user.company_info;
    },
    showCurrentCompanyDepartment(state) {
      return state.currentCompanyAndDepartment;
    },
  },
  // ----------------------------------------------------------------------------------
  mutations: {
    setUsername(state, username) {
      state.user.username = username;
    },
    setUserRole(state, userRole) {
      state.user.role = userRole;
    },
    setUserCompanies(state, userCompanies) {
      state.user.company_info = userCompanies;
    },
    setCurrentCompanyDepartment(state, newCurrentCompanyAndDepartment) {
      const valueCompanyAndDepartment = {};
      if (!newCurrentCompanyAndDepartment.chosenDepartment) {
        valueCompanyAndDepartment.chosenCompany = newCurrentCompanyAndDepartment.chosenCompany;
        valueCompanyAndDepartment.chosenDepartment = { id: -1 };
        state.currentCompanyAndDepartment = valueCompanyAndDepartment;
      } else {
        state.currentCompanyAndDepartment = newCurrentCompanyAndDepartment;
      }
    },
  },
  // ----------------------------------------------------------------------------------
  actions: {
    async login(_, payload) {
      try {
        const response = await UserRepository.login(payload);
        return response;
      } catch (error) {
        if (error.response.data.detail) {
          errorValidationMessage(error.response.data.detail);
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else {
          errorMessage();
        }
        return error;
      }
    },
    async getUsers(_, params) {
      try {
        const response = await UserRepository.getAllUsers(params);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
    async getUsersCompleteInfo(_, params) {
      try {
        const response = await UserRepository.getAllUsersCompleteInfo(params);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
    async getUserById(_, params) {
      try {
        const response = await UserRepository.getUserById(params);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
    async getUserByIdCompleteInfo(_, params) {
      try {
        const response = await UserRepository.getUserByIdCompleteInfo(params);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
    async getMyCompleteInfo() {
      try {
        const response = await UserRepository.getMyCompleteInfo();
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
    async createUser(_, params) {
      try {
        const response = await UserRepository.createUser(params);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          if (error.response.data.detail.match('duplicate key value')) {
            return errorValidationMessage('This user exists, please check the data and try again.');
          }
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
    async updateUser(_, params) {
      try {
        const response = await UserRepository.updateUser(params);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          if (error.response.data.detail.match('duplicate key value')) {
            return errorValidationMessage('This user exists, please check the data and try again.');
          }
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
    async updateMyself(_, params) {
      try {
        const response = await UserRepository.updateMyself(params);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
    async deleteUser(_, params) {
      try {
        const response = await UserRepository.deleteUser(params);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
  },
};
