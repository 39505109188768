import Repository from '../../services/repositories/RepositoryFactory';
import { errorMessage, errorValidationMessage, tokenExpired } from '../../utils/alertMessages';

const carriers = Repository.service('carriers');

export default {
  namespaced: true,
  state: {
    allCarriersProfiles: null,
  },
  getters: {
    showAllCarriersProfiles(state) {
      return state.allCarriersProfiles;
    },
  },
  mutations: {
    setAllCarriersProfiles(state, newAllCarriersProfiles) {
      state.allCarriersProfiles = newAllCarriersProfiles;
    },
  },
  actions: {
    async getAllCarriers({ commit }, payload) {
      try {
        const response = await carriers.getAllCarriers(payload);
        commit('setAllCarriersProfiles', response.data);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
    async createCarrier(_, payload) {
      try {
        const response = await carriers.createCarrier(payload);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          if (error.response.data.detail.match('duplicate key value')) {
            return errorValidationMessage('This carrier exists, please check the data and try again.');
          }
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
    async getCarrierCountRates(_, payload) {
      try {
        const response = await carriers.getCarrierCountRates(payload);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
    async getCarrierById(_, payload) {
      try {
        const response = await carriers.getCarrierById(payload);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
    async deleteCarrier(_, payload) {
      try {
        const response = await carriers.deleteCarrier(payload);
        return response;
      } catch (error) {
        if (error.response.status === 409) {
          errorValidationMessage("The record to be deleted is present in the business rules. You must first remove the dependency with the rule.");
        } else if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
    async updateCarrier(_, payload) {
      try {
        const response = await carriers.updateCarrier(payload);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
    async getAllCarriersProfiles({ commit }, payload) {
      try {
        const response = await carriers.getAllCarriersProfiles(payload);
        if (!payload?.carrier_id) {
          commit('setAllCarriersProfiles', response.data);
        }
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
    async createCarrierProfile(_, data) {
      try {
        const response = await carriers.createCarrierProfile(data);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          if (error.response.data.detail.match('duplicate key value')) {
            return errorValidationMessage('This carrier is already associated with this department, please try again with another department or company.');
          }
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
    async deleteCarrierProfile(_, data) {
      try {
        const response = await carriers.deleteCarrierProfile(data);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
    async generateSignedUrl(_, payload) {
      try {
        const response = await carriers.generateSignedUrl(payload.body);
        if (response.status === 200) {
          await carriers.saveCarrierLogo(response.data, payload.image);
        }
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
    async uploadFileCarrier(_, payload) {
      try {
        const response = await carriers.uploadFileCarrier(payload);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
  },
};
