<template>
  <div class="responses-editor">
    <div class="responses-editor__row">
      <div class="responses-editor__inputs">
        <div class="responses-editor__container-name">
          <label class="responses-editor__text" for="name">Name</label>
          <InputText
            :key="renderEditor"
            v-model="cannedName"
            class="responses-editor__input"
            :class="validField.name"
            @change="validField.name = changeFieldsColor(cannedName)"
            id="name"
            type="text"
          />
        </div>
        <Dropdown
          class="responses-editor__input"
          :class="validField.language"
          @change="validField.language = changeFieldsColor(selectedResponse.language)"
          v-model="selectedResponse"
          :options="language"
          optionLabel="language"
          placeholder="Language"
        />
        <div class="responses-editor__table-select">
          <Dropdown
            class="responses-editor__input"
            v-model="selectedTables"
            :options="tableOptions"
            optionLabel="tableOption"
            @change="validateTableOption()"
            :disabled="showTableOptions"
          />
          <span
            v-if="selectedTables.tableOption !== 'Select a table'"
            class="responses-editor__table-warning"
            >* Remember to save the table</span
          >
        </div>
      </div>
      <b-overlay class="overlay-button" :show="isLoading" rounded opacity="0.6" spinner-small>
        <b-button
          v-if="typeCrud === 'edit'"
          class="responses-editor__button"
          @click="verifyUpdate()"
          :disabled="!checkFields"
          >Edit</b-button
        >
        <b-button
          v-else
          class="responses-editor__button"
          @click="actionCanned()"
          :disabled="!checkFields"
          >Create</b-button
        >
      </b-overlay>
    </div>
    <AssociateProfForm :entity="'cannedResponse'" @associateProfile="getAssociateProfile" />
    <b-alert
      dismissible
      variant="warning"
      v-model="showDismissibleAlert"
      :show="checkValueKeys"
      class="responses-editor__"
    >
      * Please remember, keys must be put {{ tableKeys }} (paste with ctrl+shift+v)</b-alert
    >
    <Editor v-model="responseValue" editorStyle="height: 320px" :class="validField.responseValue" />
    <FreightAndQuoteTable
      :typeCrud="typeCrud"
      :cannedTablesInfo="cannedTablesInfo"
      v-if="
        selectedTables.tableOption === 'Freight Details and Quote Information' &&
        companyInfo[0].company_id === 1 &&
        companyInfo[0].department_name !== 'LTL'
      "
      @freighAndQuoteInfo="freighAndQuoteInfo"
    />
    <AccessorialsAndRateTable
      :typeCrud="typeCrud"
      :cannedTablesInfo="cannedTablesInfo"
      @accessorialsAndRateInfo="accessorialsAndRateInfo"
      v-if="
        selectedTables.tableOption === 'Accessorial and Rate' &&
        companyInfo[0].company_id === 1 &&
        companyInfo[0].department_name !== 'LTL'
      "
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import editableTableItems from "@/utils/editableTableItems";
import AssociateProfForm from "@/components/AssociateProfForm.vue";
import { successMessage, warningMessage, deleteMessage } from "../../../utils/alertMessages";
import FreightAndQuoteTable from "./FreightAndQuoteTable.vue";
import AccessorialsAndRateTable from "./AccessorialsAndRateTable.vue";

export default {
  name: "ResponsesEditor",
  components: {
    FreightAndQuoteTable,
    AccessorialsAndRateTable,
    AssociateProfForm,
  },
  props: ["typeCrud", "currentCompanyDepartment", "rowDataCanned"],
  data() {
    return {
      responseValue: null,
      selectedResponse: {},
      isLoading: false,
      language: [{ language: "en" }, { language: "es" }],
      cannedName: "",
      profileId: null,
      tableOptions: [
        { tableOption: "Select a table" },
        { tableOption: "Freight Details and Quote Information" },
        { tableOption: "Accessorial and Rate" },
      ],
      selectedTables: null,
      cannedTablesInfo: {
        freightDetails: [],
        quoteInformation: [],
        accessorialTable: [],
        rateTable: [],
      },
      editableTableItems: null,
      validField: {},
      renderEditor: 0,
      checkFields: false,
      countResponseValue: 0,
      checkTableOption: false,
      associateProfile: null,
      companyInfo: null,
      showTableOptions: true,
      checkValueKeys: false,
      tableKeys: "",
      showDismissibleAlert: false,
    };
  },
  computed: {
    ...mapGetters("company", ["showProfileCompanysDepartment"]),
  },
  watch: {
    responseValue() {
      this.countResponseValue += 1;
    },
    countResponseValue() {
      this.validField.responseValue = this.responseValue
        ? "responses-editor__input--input-valid"
        : "responses-editor__input--input-invalid";
      this.fieldsValidation();
    },
    showProfileCompanysDepartment() {
      this.companyInfo = [...this.showProfileCompanysDepartment.data];
      if (
        this.companyInfo[0].company_id === 1
        && this.companyInfo[0]?.department_name.toLowerCase() !== "ltl"
        && this.companyInfo[0]?.department_name.toLowerCase() !== "ss"
        && this.companyInfo[0]?.department_name.toLowerCase() !== "special services"
      ) {
        this.showTableOptions = false;
      } else {
        this.selectedTables = { tableOption: this.tableOptions[0].tableOption };
        this.showTableOptions = true;
        this.checkTableOption = true;
        this.fieldsValidation();
        this.getTableKeys();
      }
      this.profileId = this.showProfileCompanysDepartment.data[0].id;
    },
    selectedTables() {
      this.getTableKeys();
    },
  },
  created() {
    this.companyInfo = [...this.showProfileCompanysDepartment.data];
    this.addInfoTable();
    this.validateTableOption();
    this.profileIdOld = this.showProfileCompanysDepartment.data[0].id;
  },
  methods: {
    async verifyUpdate() {
      const warningResponse = await deleteMessage(
        "Are you sure you want to update the canned response?",
        "You won't be able to revert this!",
      );
      if (warningResponse.isConfirmed) {
        this.actionCanned();
      }
    },
    async actionCanned() {
      this.isLoading = true;
      this.fieldColor();
      if (this.selectedTables.tableOption === "Freight Details and Quote Information") {
        this.updateFieldValues();
      }
      if (this.checkFields) {
        const info = {
          params: {},
          body: {},
        };
        info.params.department_id = this.associateProfile
          ? this.companyInfo[0].department_id
          : this.currentCompanyDepartment.chosenDepartment.id;
        info.params.company_id = this.associateProfile
          ? this.companyInfo[0].company_id
          : this.currentCompanyDepartment.chosenCompany.id;
        info.body.name = this.cannedName;
        info.body.language = this.selectedResponse.language;
        info.body.html = this.responseValue;
        info.body.extras = this.currentInfoTables;
        let cannedResponse;
        let messageResponse;
        if (this.typeCrud === "edit") {
          if (this.selectedTables.tableOption === this.tableOptions[0].tableOption) {
            info.body.extras = {};
          }
          info.params.canned_response_id = this.rowDataCanned.id;
          this.validateKeys(info);
          if (this.checkKeys) {
            cannedResponse = await this.$store.dispatch(
              "cannedResponses/updateCannedResponse",
              info,
            );
            this.checkValueKeys = false;
          } else {
            warningMessage("Oops...", "Please check the field.");
            this.changeAlertMessage();
            this.showDismissibleAlert = true;
          }
          messageResponse = "Your response has been edited";
          this.isLoading = false;
        } else if (this.typeCrud === "create") {
          if (!info.body.extras) {
            info.body.extras = {};
          }
          info.body.department_x_company_x_profile_id = this.associateProfile
            ? this.profileId
            : this.profileIdOld;
          this.validateKeys(info);
          if (this.checkKeys) {
            cannedResponse = await this.$store.dispatch(
              "cannedResponses/createCannedResponse",
              info,
            );
            this.checkValueKeys = false;
          } else {
            warningMessage("Oops...", "Please check the field.");
            this.changeAlertMessage();
            this.showDismissibleAlert = true;
          }
          messageResponse = "Your response has been created";
          this.isLoading = false;
        }
        if (this.checkKeys) {
          if (cannedResponse.status === 201 || cannedResponse.status === 204) {
            successMessage(messageResponse);
            this.$emit("updateCannedResponsesData");
          }
        }
      } else {
        warningMessage("Oops...", "Please check the field.");
        this.isLoading = false;
      }
      this.isLoading = false;
    },
    freighAndQuoteInfo(value) {
      this.currentInfoTables = { ...value };
      this.checkTableOption = true;
      this.fieldsValidation();
    },
    accessorialsAndRateInfo(value) {
      this.currentInfoTables = { ...value };
      this.checkTableOption = true;
      this.fieldsValidation();
    },
    addInfoTable() {
      this.editableTableItems = editableTableItems;
      if (this.typeCrud === "edit" && this.rowDataCanned) {
        this.cannedName = this.rowDataCanned.name;
        this.selectedResponse.language = this.rowDataCanned.language;
        this.responseValue = this.rowDataCanned.html;
        if (Object.entries(this.rowDataCanned.extras).length !== 0) {
          if (Object.keys(this.rowDataCanned.extras)[1] === "quoteInformation") {
            this.selectedTables = { tableOption: this.tableOptions[1].tableOption };
            this.cannedTablesInfo.freightDetails[0] = this.rowDataCanned.extras.freightDetails;
            this.cannedTablesInfo.quoteInformation[0] = this.rowDataCanned.extras.quoteInformation;
            this.cannedTablesInfo.accessorialTable = this.editableTableItems.accessorial_table;
            this.cannedTablesInfo.rateTable = this.editableTableItems.rate_table;
          } else if (Object.keys(this.rowDataCanned.extras)[1] === "accessorialTable") {
            this.selectedTables = { tableOption: this.tableOptions[2].tableOption };
            this.cannedTablesInfo.accessorialTable[0] = this.rowDataCanned.extras.accessorialTable;
            this.cannedTablesInfo.rateTable[0] = this.rowDataCanned.extras.rateTable;
            this.cannedTablesInfo.freightDetails = this.editableTableItems.freight_details;
            this.cannedTablesInfo.quoteInformation = this.editableTableItems.quote_information;
          }
        } else {
          this.selectedTables = { tableOption: this.tableOptions[0].tableOption };
          this.cannedTablesInfo.freightDetails = this.editableTableItems.freight_details;
          this.cannedTablesInfo.quoteInformation = this.editableTableItems.quote_information;
          this.cannedTablesInfo.accessorialTable = this.editableTableItems.accessorial_table;
          this.cannedTablesInfo.rateTable = this.editableTableItems.rate_table;
        }
        this.fieldColor();
      } else if (this.typeCrud === "create") {
        this.selectedTables = { tableOption: this.tableOptions[0].tableOption };
        this.cannedTablesInfo.freightDetails = this.editableTableItems.freight_details;
        this.cannedTablesInfo.quoteInformation = this.editableTableItems.quote_information;
        this.cannedTablesInfo.accessorialTable = this.editableTableItems.accessorial_table;
        this.cannedTablesInfo.rateTable = this.editableTableItems.rate_table;
        this.cleanColor();
      }
    },
    fieldsValidation() {
      if (
        this.cannedName
        && this.selectedResponse.language
        && this.responseValue
        && this.checkTableOption
      ) {
        this.checkFields = true;
      } else {
        this.checkFields = false;
      }
      return this.checkFields;
    },
    cleanColor() {
      this.validField.name = null;
      this.validField.language = null;
      this.validField.responseValue = null;
    },
    fieldColor() {
      this.validField.name = this.changeFieldsColor(this.cannedName);
      this.validField.language = this.changeFieldsColor(this.selectedResponse.language);
      this.validField.responseValue = this.responseValue
        ? "responses-editor__input--input-valid"
        : "responses-editor__input--input-invalid";
      this.fieldsValidation();
    },
    changeFieldsColor(field) {
      this.fieldsValidation();
      if (field) {
        this.renderEditor += 1;
        return "responses-editor__input--input-valid";
      }
      this.renderEditor += 1;
      return "p-invalid";
    },
    validateTableOption() {
      if (this.selectedTables.tableOption !== "Select a table") {
        this.checkTableOption = false;
      } else {
        this.checkTableOption = true;
      }
      this.fieldsValidation();
    },
    getAssociateProfile(value) {
      this.associateProfile = value;
    },
    getTableKeys() {
      if (this.typeCrud === "create") {
        if (this.companyInfo[0]?.department_name.toLowerCase() === "ltl") {
          this.responseValue = "<br>{hauls_info}<br><br>{signature}";
        } else if (
          this.companyInfo[0]?.department_name.toLowerCase() === "ss"
          || this.companyInfo[0]?.department_name.toLowerCase() === "special services"
        ) {
          this.responseValue = "<br>---FREIGHT-DETAILS-HERE---";
        } else if (this.selectedTables.tableOption === "Freight Details and Quote Information") {
          if (
            this.companyInfo[0]?.department_name.toLowerCase() === "tc"
            || this.companyInfo[0]?.department_name.toLowerCase() === "tempcontrol"
          ) {
            this.responseValue = "<br>{freight_details_trip_1}<br><br>{quote_information_trip_1}";
          } else {
            this.responseValue = "<br>{freight_details_trip_1}<br><br>{quote_information_trip_1}<br>--ACCESSORIALS_PER_TRIP--";
          }
        } else if (this.selectedTables.tableOption === "Accessorial and Rate") {
          this.responseValue = "<br>{table_rate_1}<br><br>{table_accessorials}";
        }
      }
    },
    validateKeys(info) {
      if (
        info.body.html.includes("{hauls_info}")
        && info.body.html.includes("{signature}")
        && this.companyInfo[0]?.department_name.toLowerCase() === "ltl"
      ) {
        this.checkKeys = true;
        this.checkValueKeys = false;
      } else if (
        info.body.html.includes("---FREIGHT-DETAILS-HERE---")
        && (this.companyInfo[0]?.department_name.toLowerCase() === "ss"
          || this.companyInfo[0]?.department_name.toLowerCase() === "special services")
      ) {
        this.checkKeys = true;
        this.checkValueKeys = false;
      } else if (
        info.body.html.includes("{freight_details_trip_1}")
        && info.body.html.includes("{quote_information_trip_1}")
        && (this.companyInfo[0]?.department_name.toLowerCase() !== "ltl"
        || this.companyInfo[0]?.department_name.toLowerCase() !== "ss"
        || this.companyInfo[0]?.department_name.toLowerCase() !== "special services")
        && this.selectedTables.tableOption === this.tableOptions[1].tableOption
      ) {
        this.checkKeys = true;
        this.checkValueKeys = false;
      } else if (
        info.body.html.includes("{table_rate_1}")
        && info.body.html.includes("{table_accessorials}")
        && (this.companyInfo[0]?.department_name.toLowerCase() !== "ltl"
        || this.companyInfo[0]?.department_name.toLowerCase() !== "ss"
        || this.companyInfo[0]?.department_name.toLowerCase() !== "special services")
        && this.selectedTables.tableOption === this.tableOptions[2].tableOption
      ) {
        this.checkKeys = true;
        this.checkValueKeys = false;
      } else if (
        this.selectedTables.tableOption === "Select a table"
        && (this.companyInfo[0]?.department_name.toLowerCase() === "ftl"
        || this.companyInfo[0]?.department_name.toLowerCase() === "otr")
      ) {
        this.checkKeys = true;
        this.checkValueKeys = false;
      } else {
        this.checkKeys = false;
        this.checkValueKeys = true;
      }
    },
    changeAlertMessage() {
      if (this.companyInfo[0]?.department_name.toLowerCase() === "ltl") {
        this.tableKeys = "{hauls_info} and {signature}";
      } else if (
        this.companyInfo[0]?.department_name.toLowerCase() === "ss"
        || this.companyInfo[0]?.department_name.toLowerCase() === "special services"
      ) {
        this.tableKeys = "---FREIGHT-DETAILS-HERE---";
      } else if (
        (this.companyInfo[0]?.department_name.toLowerCase() !== "ltl"
        || this.companyInfo[0]?.department_name.toLowerCase() !== "ss"
        || this.companyInfo[0]?.department_name.toLowerCase() !== "special services")
        && this.selectedTables.tableOption === this.tableOptions[1].tableOption
      ) {
        this.tableKeys = "{freight_details_trip_1} and {quote_information_trip_1}";
      } else if (
        (this.companyInfo[0]?.department_name.toLowerCase() !== "ltl"
        || this.companyInfo[0]?.department_name.toLowerCase() !== "ss"
        || this.companyInfo[0]?.department_name.toLowerCase() !== "special services")
        && this.selectedTables.tableOption === this.tableOptions[2].tableOption
      ) {
        this.tableKeys = "{table_rate_1} and {table_accessorials}";
      }
    },
    updateFieldValues() {
      for (let i = 0; i < this.currentInfoTables.quoteInformation.fields.length; i += 1) {
        if (this.currentInfoTables.quoteInformation.fields[i].includes(" ")) {
          this.currentInfoTables.quoteInformation.fields[i] = this.currentInfoTables.quoteInformation.fields[i].replaceAll(" ", "_");
        }
        this.currentInfoTables.quoteInformation.fields[
          i] = this.currentInfoTables.quoteInformation.fields[i].toLowerCase();
      }
      const quoteInformationContent = this.currentInfoTables.quoteInformation.table_content[0];
      Object.entries(quoteInformationContent).forEach(([key]) => {
        let replaceKey;
        if (key.includes(" ")) {
          replaceKey = key.replaceAll(" ", "_");
          quoteInformationContent[replaceKey] = quoteInformationContent[key];
          delete quoteInformationContent[key];
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.responses-editor {
  width: 90%;
  margin: 1rem auto;
  border-radius: 10px;
  box-shadow: $box-shadow;
  padding: 2rem;
  &__row {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1rem;
    justify-content: space-between;
  }
  &__inputs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  &__container-name {
    display: flex;
  }
  &__text {
    display: flex;
    align-items: center;
    color: $color-input-text;
    margin-right: 0.5rem;
  }
  &__input {
    @include input;
    margin: 0.5rem 0.2rem;
    &--input-valid {
      border: 1px solid #00b900;
      border-radius: 4px;
    }
    &--input-invalid {
      border: 1px solid #f44336;
      border-radius: 4px;
    }
  }
  &__table-select {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  &__table-warning {
    color: #ffc107;
  }
  &__button {
    @include submit-button;
  }
  &__button {
    @include submit-button;
    margin: 0.5rem 0;
  }
}
:deep(label) {
  margin-bottom: 0;
}
:deep(.p-dropdown-label) {
  padding-top: 0.3rem;
}
:deep(.associate-prof-form) {
  &__selection-associate {
    display: flex;
    flex-wrap: wrap;
    & hr {
      display: none;
    }
  }
  &__row {
    width: 16rem;
    margin: 0.5rem 0;
    &--label {
      width: auto;
    }
  }
}
:deep(.alert-warning) {
  font-size: 14px;
}
@media (max-width: 600px) {
  .responses-editor__row {
    flex-wrap: wrap;
  }
}
@media (max-width: 500px) {
  .responses-editor {
    &__row {
      flex-wrap: wrap;
    }
    &__container-name {
      flex-direction: column;
    }
    &__input {
      width: 12rem;
    }
  }
}
</style>
