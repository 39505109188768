<template>
  <div class="option-container">
      <!-- <h6 class="option-container__title">{{ typeOfCondition }}</h6> -->
      <div class="option-container-component">
        <Input
          v-if="fieldType === 'string' || fieldType === 'numeric'"
          :inputType="inputType"
          @conditionObject="editCondition"
          :conditionEditData="conditionEditData"
          :blockNumber="blockNumber"
        />
        <Select
          v-if="fieldType === 'boolean' || fieldType === 'select'"
          :inputType="inputType"
          @conditionObject="editCondition"
          :conditionEditData="conditionEditData"
        />
        <SelectMultiple
          v-if="fieldType?.includes('multiple')"
          @conditionObject="editCondition"
          :inputType="inputType"
          :conditionEditData="conditionEditData"
        />
      <div>
        <button class="option-container-component__delete-option" @click="deleteOptionContainer">
          <i class="pi pi-trash"></i>
        </button>
      </div>
      </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import Input from "./ComponentsConditions/Input.vue";
import SelectMultiple from "./ComponentsConditions/SelectMultiple.vue";
import Select from "./ComponentsConditions/Select.vue";

export default {
  components: {
    Input,
    SelectMultiple,
    Select,
  },
  props: {
    conditionEditData: {
      type: Object,
      default: null,
    },
    blockNumber: Number,
    indexOfContainerArray: Number,
  },
  data() {
    return {
      inputType: null,
      fieldType: null,
      objectIRepeat: false,
      editData: null,
      conditionSelected: null,
      variablesRulesEngine: null,
    };
  },
  computed: {
    ...mapGetters("rules", ["showRuleCompleteObject"]),
  },
  created() {
    this.variablesRulesEngine = this.showRuleCompleteObject?.variables;
    this.editConditionData(this.variablesRulesEngine);
  },
  methods: {
    editConditionData() {
      if (this.conditionEditData.name) {
        const ifNameIsFormula = this.conditionEditData.name.includes("formula:") ? "formula:" : "";
        this.conditionSelected = this.variablesRulesEngine?.filter(
          (condition) => condition.name === this.conditionEditData.name
          || condition.name === ifNameIsFormula,
        );
        this.conditionSelectedAssign();
      }
    },
    conditionSelectedAssign() {
      this.inputType = this.conditionSelected;
      this.fieldType = this.conditionSelected[0].field_type;
      if (this.conditionEditData) {
        const conditionEditInfo = { ...this.conditionEditData };
        this.editCondition(conditionEditInfo);
      }
    },
    editCondition(conditionObject) {
      const editElement = {};
      editElement.currentIndex = this.blockNumber;
      editElement.containerIndex = this.indexOfContainerArray;
      editElement.objectCondition = conditionObject;
      this.$emit("editCondition", editElement);
      const conditionSchema = this.$store.getters["rules/showConditionsSchema"];
      const mainNameArray = Object.keys(conditionSchema)[0];
      this.$store.commit('rules/setValueConditions', conditionSchema[mainNameArray]);
    },
    // foundRepeatObjects() {
    //   const conditionSchema = this.$store.getters["rules/showConditionsSchema"].all;
    //   conditionSchema.forEach((element) => {
    //     // eslint-disable-next-line no-prototype-builtins
    //     if (element.hasOwnProperty("any")) {
    //       element.any.forEach((orElement) => {
    //         if (orElement.index === this.blockNumber) {
    //           this.objectIRepeat = true;
    //         }
    //       });
    //     } else if (element.index === this.blockNumber) {
    //       this.objectIRepeat = true;
    //     }
    //   });
    //   this.$store.commit('rules/setValueConditions', conditionSchema);
    // },
    deleteOptionContainer() {
      const deleteElement = {};
      deleteElement.currentIndex = this.blockNumber;
      deleteElement.containerIndex = this.indexOfContainerArray;
      this.$emit("deleteOptionContainer", deleteElement);
    },
  },
};
</script>
<style scoped lang="scss">
.option-container__title {
  color: #495057;
  text-align: center;
  font-weight: 700;
  font-size: 18px;
}
.option-container-component {
  @include each-option-container;
}
</style>
