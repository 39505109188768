import Repository from "../../services/repositories/RepositoryFactory";
import { errorMessage, errorValidationMessage, tokenExpired } from '../../utils/alertMessages';

const AccessorialRepository = Repository.service("accessorials");

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async getAllAccessorials(_, payload) {
      try {
        const response = await AccessorialRepository.getAllAccessorials(payload);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
    async createAccessorial(_, payload) {
      try {
        const response = await AccessorialRepository.createAccessorial(payload);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
    async getAccessorialById(_, payload) {
      try {
        const response = await AccessorialRepository.getAccessorialById(payload);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
    async deleteAccessorial(_, accessorialId) {
      try {
        const response = await AccessorialRepository.deleteAccessorial(accessorialId);
        return response;
      } catch (error) {
        if (error.response.status === 409) {
          errorValidationMessage("The record to be deleted is present in the business rules. You must first remove the dependency with the rule.");
        } else if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
    async updateAccessorial(_, payload) {
      try {
        const response = await AccessorialRepository.updateAccessorial(payload);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },

    async getAccessorialsByProfile(_, payload) {
      try {
        const response = await AccessorialRepository.getAccessorialsByProfile(payload);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
    async createAccessorialByProfile(_, accInformation) {
      try {
        const response = await AccessorialRepository.createAccessorialByProfile(accInformation);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          if (error.response.data.detail.match('duplicate key value')) {
            return errorValidationMessage('This accessorial is already associated with this department, please try again with another department or company.');
          }
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
    async deleteAccessorialByProfile(_, params) {
      try {
        const response = await AccessorialRepository.deleteAccessorialByProfile(params);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
  },
};
