<template>
  <div class="haul-response">
    <div class="haul-response__inputs">
      <div
        v-for="(input, index) in haulResponse"
        :key="index"
        v-show="
          index !== 'accessorials' &&
          index !== 'alerts' &&
          index !== 'carriers' &&
          index !== 'line_items' &&
          index !== 'insurance_required' &&
          index !== 'project_cargo' &&
          index !== 'requoted' &&
          index !== 'shipper' &&
          index !== 'some_rule_was_triggered' &&
          index !== 'x1tc' &&
          index !== 'xenon_quote' &&
          index !== 'tms_mode' &&
          index !== 'message'
        "
      >
        <label class="haul-response__label">{{ filter(index) }}</label>
        <InputText class="haul-response__input" v-model="haulResponse[index]" disabled />
      </div>
    </div>
    <div class="haul-response__checkbox">
      <b-form-checkbox
        v-for="(boolean, index) in booleansInfo"
        :key="index"
        v-model="booleansInfo[index]"
        disabled
        >{{ filter(index) }}</b-form-checkbox
      >
    </div>
    <div v-if="tmsMode && tmsMode?.name">
      <hr />
      <label class="haul-response__label">Tms Mode:</label>
      <div class="haul-response__inputs">
        <div v-for="(input, index) in tmsMode" :key="index">
          <label class="haul-response__label">{{ filter(index) }}</label>
          <InputText class="haul-response__input" v-model="tmsMode[index]" disabled />
        </div>
      </div>
    </div>
    <div v-if="message">
      <hr />
      <label class="haul-response__label">Message:</label>
      <div class="haul-response__inputs">
        <div v-for="(input, index) in message" :key="index">
          <label class="haul-response__label">{{ filter(index) }}</label>
          <Textarea
            class="haul-response__input"
            v-model="message[index]"
            :rows="index === 'content' ? 4 : 1"
            :autoResize="true"
            disabled
          />
        </div>
      </div>
    </div>
    <div v-if="carriers.length > 0">
      <hr />
      <label class="haul-response__label">Carrier:</label>
      <div v-for="(objectCarriers, index) in carriers" :key="index" class="haul-response__carriers">
        <div v-for="(carrier, i) in objectCarriers" :key="i">
          <label
            class="haul-response__label"
            v-if="i !== 'br_accessorials' && i !== 'additional_information'"
            >{{ filter(i) }}</label
          >
          <InputText
            v-if="i !== 'br_accessorials' && i !== 'additional_information'"
            v-model="objectCarriers[i]"
            disabled
          />
        </div>
      </div>
    </div>
    <div v-if="lineItems.length > 0">
      <hr />
      <label class="haul-response__label">Line Items:</label>
      <b-table
        responsive
        show-empty
        hover
        small
        foot-clone
        class="line-items"
        head-variant="light"
        stacked="md"
        fixed
        :items="lineItems"
      >
        <template #head(id)>
          <span></span>
        </template>
        <template #foot()>
          <span></span>
        </template>
      </b-table>
    </div>
    <div v-if="alertHistory?.length > 0">
      <hr />
      <label class="haul-response__label">Alerts:</label>
      <b-table
        responsive
        show-empty
        hover
        small
        foot-clone
        class="line-items"
        head-variant="light"
        stacked="md"
        fixed
        :items="alertHistory"
      >
        <template #cell(actions)="row">
          <span v-if="typeof row.item.actions[0] === 'string'">{{ row.item.actions[0] }}</span>
          <div v-else>
            <div v-for="(action, index) in row.item.actions[0]" :key="index">
              <span v-for="(value, index) in action" :key="index"
                ><hr />
                {{ value }}<br />
                <hr
              /></span>
            </div>
          </div>
        </template>
        <template #foot()>
          <span></span>
        </template>
      </b-table>
    </div>
    <div v-if="accessorials?.length > 0">
      <hr />
      <label class="haul-response__label">Accessorials:</label>
      <b-table
        responsive
        show-empty
        hover
        small
        foot-clone
        class="line-items"
        head-variant="light"
        stacked="md"
        fixed
        :items="accessorials"
      >
        <template #cell(tms_code)>
          <span></span>
        </template>
        <template #foot()>
          <span></span>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
export default {
  name: "HaulResponse",
  props: {
    sendHaulResponse: Object,
  },
  data() {
    return {
      haulResponse: null,
      accessorials: null,
      carriers: null,
      lineItems: null,
      tmsMode: null,
      message: null,
      booleansInfo: null,
      alertHistory: null,
    };
  },
  created() {
    this.haulResponse = this.sendHaulResponse;
    this.accessorials = this.haulResponse.accessorials;
    this.carriers = this.haulResponse.carriers;
    this.lineItems = this.haulResponse.line_items;
    this.tmsMode = this.haulResponse.tms_mode;
    this.message = this.haulResponse.message;
    this.booleansInfo = {
      insurance_required: this.haulResponse.insurance_required,
      project_cargo: this.haulResponse.project_cargo,
      requoted: this.haulResponse.requoted,
      shipper: this.haulResponse.shipper,
      some_rule_was_triggered: this.haulResponse.some_rule_was_triggered,
      x1tc: this.haulResponse.x1tc,
      xenon_quote: this.haulResponse.xenon_quote,
    };
    this.alertHistory = this.haulResponse.alerts.history;
    this.deleteColumn();
  },
  methods: {
    filter(value) {
      const capitalFirstLetter = value.charAt(0).toUpperCase() + value.slice(1);
      return capitalFirstLetter.split("_").join(" ");
    },
    deleteColumn() {
      for (let i = 0; i < this.alertHistory.length; i += 1) {
        delete this.alertHistory[i].rule_was_triggered;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.haul-response {
  &__inputs {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
  }
  &__input {
    width: 100%;
  }
  &__label {
    font-weight: 500;
  }
  &__checkbox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 1rem;
  }
  &__carriers {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
  }
}
:deep(.p-inputtext) {
  height: auto !important;
  overflow-y: scroll !important;
  &::-webkit-scrollbar {
    width: 8px !important;
  }
  &::-webkit-scrollbar-thumb {
    background: #000;
    border-radius: 3px;
  }
}
:deep(.table) {
  width: auto;
  text-align: center;
  & td {
    border: 1px solid #dee2e6;
  }
}
</style>
