import Repository from "../../services/repositories/RepositoryFactory";
import { errorMessage, errorValidationMessage, tokenExpired } from "../../utils/alertMessages";

const cannedResponses = Repository.service("cannedResponses");

export default {
  namespaced: true,
  state: {
    allCannedResponses: null,
  },
  getters: {
    showAllCannedResponses(state) {
      return state.allCannedResponses;
    },
  },
  mutations: {
    setAllCannedResponses(state, newAllCannedResponses) {
      state.allCannedResponses = newAllCannedResponses;
    },
  },
  actions: {
    async getAllCannedResponses({ commit }, params) {
      try {
        const response = await cannedResponses.getAllCannedResponses(params);
        commit('setAllCannedResponses', response.data);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
    async createCannedResponse(_, payload) {
      try {
        const response = await cannedResponses.createCannedResponse(payload);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
    async getCannedResponseById(_, params) {
      try {
        const response = await cannedResponses.getCannedResponseById(params);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
    async deleteCannedResponse(_, params) {
      try {
        const response = await cannedResponses.deleteCannedResponse(params);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
    async updateCannedResponse(_, payload) {
      try {
        const response = await cannedResponses.updateCannedResponse(payload);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
  },
};
