import Vue from 'vue';
import VueRouter from 'vue-router';
import LoginRoute from './modules/login';
import CarriersRoute from './modules/carriers';
import RulesRoute from './modules/rules';
import AccessorialsRoute from './modules/accessorials';
import AdminRoute from './modules/admin';
import CannedResponsesRoute from './modules/cannedResponses';
import createRulesRoute from './modules/CreateRules';
import createLTLRuleRoute from './modules/createLTLRule';
import TestingLaboratoryRoute from './modules/testingLaboratory';

Vue.use(VueRouter);

const AppRoute = [
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/404',
    name: 'PageNotFound',
    component: () => import(/* webpackChunkName: "pagenotfound" */ '../views/PageNotFound.vue'),
  },
  {
    path: "*" /* * El * Siempre al final o regex */,
    redirect: "/404",
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    ...LoginRoute,
    ...CarriersRoute,
    ...RulesRoute,
    ...createRulesRoute,
    ...AccessorialsRoute,
    ...AdminRoute,
    ...CannedResponsesRoute,
    ...createLTLRuleRoute,
    ...TestingLaboratoryRoute,
    ...AppRoute,
  ],
});

export default router;
