import api from "../clients/axios";

const resource = "/api/v1/companies";

export default {
  async getAllAccessorials(params) {
    const response = await api.get(`api/v1/accessorials`, { params });
    return response.data;
  },
  async createAccessorial(payload) {
    const response = await api.post(`api/v1/accessorials`, payload);
    return response;
  },
  async getAccessorialById(params) {
    const response = await api.get(`api/v1/accessorials/${params.accessorial_id}`, { params });
    return response;
  },
  async deleteAccessorial(idAccessorial) {
    const payload = {};
    payload.confirmation_message = "I'm sure I want to delete this";
    const response = await api.delete(`api/v1/accessorials/${idAccessorial}`, { params: payload });
    return response;
  },
  async updateAccessorial(payload) {
    const response = await api.patch(`api/v1/accessorials/${payload.accessorial_id}`, payload.body);
    return response;
  },

  async getAccessorialsByProfile(params) {
    const response = await api.get(
      `${resource}/${params?.company_id}/departments/${params?.department_id}/profiles/${params?.profile_id}/accessorials`, { params },
    );
    return response.data;
  },
  async createAccessorialByProfile(payload) {
    const body = { ...payload.body };
    body.priority = 1;
    const response = await api.post(
      `${resource}/${payload.company_id}/departments/${payload.department_id}/profiles/${payload.profile_id}/accessorials/${payload.accessorial_id}`,
      body,
    );
    return response;
  },
  async deleteAccessorialByProfile(params) {
    const payload = {
      confirmation_message: "I'm sure I want to delete this",
    };
    const response = await api.delete(
      `${resource}/${params.company_id}/departments/${params.department_id}/profiles/${params.profile_id}/accessorials/${params.accessorial_id}`,
      { params: payload },
    );
    return response;
  },
};
