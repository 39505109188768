<template>
  <div class="input-container">
    <label :for="inputType.name">{{ inputType[0].category }} - {{ inputType[0].label }}</label>
    <el-input
      v-if="ifNameIsFormula"
      type="textarea"
      :autosize="{ minRows: 1, maxRows: 2 }"
      class="input-container__text-area"
      :style="`border: 1px solid ${fieldColor}`"
      placeholder="Formula"
      v-tooltip="`*Recuerda no eliminar el prefijo 'formula:''`"
      v-model="nameFormula"
      @change="verifyNameFormula()"
    >
    </el-input>
    <Dropdown
      class="input-container__input"
      :name="inputType.name"
      v-model="optionSelected"
      :options="options"
      optionLabel="name"
      placeholder="Operator"
      :filter="true"
      @change="operatorChange(true)"
    >
      <template #option="slotProps">
        <div class="p-dropdown-car-option"
        v-tooltip="{ value: slotProps.option.description, disabled: false }">
          <span>{{slotProps.option.name}}</span>
        </div>
      </template>
    </Dropdown>
    <!--<span
      class="input"
      :name="inputType[0].name"
      :id="inputType[0].name + blockNumber"
      @keydown.tab="conditionConstructor"
      @keydown.enter="conditionConstructor"
      @blur="conditionConstructor"
      role="textbox" contenteditable>{{inputText}}
    </span>-->
    <b-form-tags
      input-id="tags-validation"
      v-model="inputText"
      :placeholder="inputType[0].label"
      separator=","
      :tag-validator="tagValidator"
      :state="parametersCompleted"
      :limit="selectionLimit"
      :disabled="!isOperatorSelected"
      :disableAddButton="isdisableButton"
      @input="conditionConstructor()"
      @change="conditionConstructor()"
    ></b-form-tags>
    <p class="input-container__help-message">
      {{message}}
    </p>
    <!--<input
      class="input-container__input"
      :name="inputType[0].name"
      :id="inputType[0].name"
      type="text"
      :placeholder="inputType[0].label"
      v-model="inputText"
      @keyup.enter="conditionConstructor"
    />-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { errorValidationMessage } from "@/utils/alertMessages";

export default {
  props: {
    inputType: {
      type: Array,
      default: null,
    },
    conditionEditData: {
      type: Object,
      default: null,
    },
    blockNumber: Number,
  },
  data() {
    return {
      optionSelected: null,
      options: [],
      typeInput: null,
      inputText: [],
      nameFormula: null,
      selectionLimit: null,
      selectionMinimum: null,
      message: '',
      parametersCompleted: null,
      isOperatorSelected: false,
      isdisableButton: false,
      ifNameIsFormula: null,
      fieldColor: null,
    };
  },
  computed: {
    ...mapGetters("rules", ["showRuleCompleteObject"]),
  },
  created() {
    this.ifNameIsFormula = this.inputType[0]?.name?.includes('formula:');
    this.nameFormula = this.ifNameIsFormula ? this.conditionEditData?.name : null;
    // this.verifyNameFormula();
    this.labelConstructor();
    if (this.conditionEditData.value || this.conditionEditData?.value?.length > 0) {
      this.conditionEditConstructor();
    } else {
      [this.optionSelected] = this.options;
    }
    this.operatorChange();
  },
  methods: {
    operatorChange() {
      this.isOperatorSelected = true;
      // this.inputText = !ifChange ? this.inputText : [];
      this.selectionLimit = this.optionSelected.max_item;
      this.selectionMinimum = this.optionSelected.min_item;
      const amount = this.selectionLimit === this.selectionMinimum ? 'exactly' : 'at least';
      this.message = `*This operator requires ${amount} ${this.selectionMinimum} parameter(s)`;
      this.conditionConstructor();
    },
    verifyParameters() {
      const isMinimum = this.inputText.length >= this.selectionMinimum;
      if (this.selectionLimit === null) {
        this.parametersCompleted = isMinimum;
      } else {
        const maximum = this.inputText.length <= this.selectionLimit;
        this.parametersCompleted = isMinimum && maximum;
      }
    },
    tagValidator(tag) {
      if (tag.includes('formula:')) {
        return true;
      }
      if (this.inputType[0].field_type === 'numeric') {
        return !Number.isNaN(parseInt(tag, 10));
      }
      return tag !== "";
    },
    async formulaChecker(formulaToValidate) {
      const formula = formulaToValidate;
      const response = await this.$store.dispatch("rules/checkFormula", formula);
      this.fieldColor = !response?.data.is_valid ? '#f44336' : '#00b900';
      if (response.status !== 200 || !response.data.is_valid) {
        errorValidationMessage('The formula is invalid');
        if (!this.ifNameIsFormula) {
          this.inputText.pop();
        }
      } if (response?.status === 200 && this.ifNameIsFormula) {
        this.$store.commit('rules/setFormulasChecked', { index: this.blockNumber, status: response?.data?.is_valid });
      }
    },
    conditionEditConstructor() {
      // this.optionSelected = this.conditionEditData.operator;
      [this.optionSelected] = this.options.filter(
        (element) => element.name === this.conditionEditData.operator,
      );
      this.inputText = this.conditionEditData.value;
    },
    async conditionConstructor() {
      // const text = document.getElementById(this.inputType[0].name + this.blockNumber).innerText;
      // this.inputText = text;
      const tag = this.inputText[this.inputText.length - 1];
      if (typeof tag !== 'number') {
        if (tag && tag.includes('formula:')) {
          await this.formulaChecker(this.inputText[this.inputText.length - 1]);
        }
      }
      this.verifyParameters();
      const infoCondition = {};
      infoCondition.name = !this.ifNameIsFormula ? this.inputType[0]?.name : this.nameFormula;
      infoCondition.operator = this.optionSelected.name;
      infoCondition.value = this.inputText;
      if (this.inputType[0].field_type === 'numeric') {
        infoCondition.value = this.inputText.map((item) => {
          if (Number.isNaN(parseFloat(item, 10))) {
            return item;
          }
          return parseFloat(item, 10);
        });
      }
      /* if (this.inputText && this.inputText.includes('formula:')) {
        const response = await this.$store.dispatch("rules/checkFormula", this.inputText);
        if (response.status !== 200) {
          this.showErrorInput(this.inputType[0].name);
          infoCondition.value = "";
        } else if (!response.data.is_valid) {
          this.showErrorInput(this.inputType[0].name);
          infoCondition.value = "";
        } else {
          this.showSuccessInput(this.inputType[0].name);
        }
      } else if (this.inputType[0].field_type === 'numeric') {
        if (Number.isNaN(parseInt(this.inputText, 10))) {
          this.showErrorInput(this.inputType[0].name);
          infoCondition.value = "";
        } else {
          this.showSuccessInput(this.inputType[0].name);
          infoCondition.value = parseInt(this.inputText, 10);
        }
      } else if (this.inputText && this.inputText !== "") {
        this.showSuccessInput(this.inputType[0].name);
      } else {
        this.showErrorInput(this.inputType[0].name);
      } */
      this.$emit("conditionObject", infoCondition);
    },
    labelConstructor() {
      this.typeInput = this.inputType[0].field_type;
      const ruleData = this.showRuleCompleteObject;
      const variableTypeOperators = ruleData?.variable_type_operators[this.typeInput];
      for (let i = 0; i < variableTypeOperators.length; i += 1) {
        this.options.push({
          name: variableTypeOperators[i].name,
          description: variableTypeOperators[i].description,
          min_item: variableTypeOperators[i].min_items,
          max_item: variableTypeOperators[i].max_items,
        });
      }
    },
    showSuccessInput(name) {
      const element = document.getElementById(name + this.blockNumber);
      element.classList.remove("error");
      element.classList.add("saved");
    },
    showErrorInput(name) {
      const element = document.getElementById(name + this.blockNumber);
      element.classList.remove("saved");
      element.classList.add("error");
    },
    async verifyNameFormula() {
      if (this.ifNameIsFormula) {
        if (this.nameFormula && this.nameFormula.slice(0, 10) === 'formula:') {
          await this.formulaChecker(this.nameFormula);
          this.$store.commit('rules/setFormulasChecked', { index: this.blockNumber, status: false });
        } else if (this.nameFormula && this.nameFormula.slice(0, 8) === 'formula:') {
          await this.formulaChecker(this.nameFormula);
          this.conditionConstructor();
        } else {
          this.nameFormula = this.conditionEditData?.name;
          this.$store.commit('rules/setFormulasChecked', { index: this.blockNumber, status: false });
          this.conditionConstructor();
        }
      }
    },
  },
};
</script>
<style scoped lang="scss">
.input-container {
  @include rules-input;
  width: 90%;
  &__help-message {
    color: $color-input-text;
    font-size: 12px;
    padding-left: 0.5rem;
    margin: auto;
  }
  &__text-area {
    padding-left: 0;
    width: 28%;
    margin: 0 5px;
    border: solid 1px #d9d9d9;
    background-color: #f3f3f3;
  }
}

input:focus {
  outline: none;
}
select:focus {
  outline: none;
}
.saved {
  border-color: $color-user-active !important;
}
.error {
  border-color: $color-input-error !important;
}
:deep(.p-dropdown-label) {
  padding-top: 0 !important;
}
:deep(.p-dropdown-trigger-icon) {
  font-size: 11px;
  font-weight: bold;
}
:deep(.p-dropdown-trigger) {
  width: 1.2rem;
}
.input {
  display: inline-block;
  text-align: initial;
  margin: 0 5px;
  background-color: #f3f3f3;
  border: solid 1px #d9d9d9;
  padding: 3px 5px;
  color: #495057;
  height: 28px;
  min-width: 204px;
}
span:focus {
  outline: none;
}
:deep(.b-form-tags) {
  width: 50%;
}
:deep(.el-textarea__inner) {
  border: none;
  background-color: #f3f3f3;
  height: 30.99px;
  min-height: 30.99px;
}
</style>
