<template>
  <div class="then-rules">
    <h5 class="then-rules__title">Then...</h5>
    <template>
      <ActionsOptions
        @pushNewAction="pushNewAction"
        @handleDeleteOption="deleteOption"
        @buildActionsArray="buildActionsArray"
        :optionNumber="index"
        v-for="(option, index) in actionsOptionsArray"
        :key="'action' + option"
        ref="actionOptions"
      />
    </template>
  </div>
</template>

<script>
import ActionsOptions from "./ActionsOptions.vue";

export default {
  name: "Actions",
  props: {
    editActions: {
      type: Array,
    },
  },
  components: {
    ActionsOptions,
  },
  data() {
    return {
      actionsOptionsArray: [0],
      completeActionsSelected: [],
      editingRule: true,
      actionSelected: {},
    };
  },
  watch: {
    editActions() {
      if (this.editingRule) {
        this.editingRule = false;
        this.$store.commit('rules/setActions', this.editActions);
        this.completeActionsSelected = [...this.editActions];
        const areActionsComplete = [];
        areActionsComplete.length = this.editActions.length;
        areActionsComplete.fill(true, 0);
        this.$store.commit('rules/setBooleanActions', areActionsComplete);
        this.editActions.forEach((item, index) => {
          this.actionsOptionsArray.push(index + 1);
        });
        setTimeout(() => {
          this.fillAction();
        }, 1000);
      }
    },
  },
  methods: {
    fillAction() {
      this.editActions.forEach((item, index) => {
        this.actionSelected = item;
        this.handleFillActions(index);
      });
    },
    handleFillActions(index) {
      this.$refs.actionOptions[index].fillActionsCards(this.actionSelected);
    },
    pushNewAction() {
      const flagNumber = this.actionsOptionsArray[this.actionsOptionsArray.length - 1];
      this.actionsOptionsArray.push(flagNumber + 1);
    },
    deleteOption(optionId) {
      this.actionsOptionsArray.splice(optionId, 1);
      this.completeActionsSelected.splice(optionId, 1);
      this.$store.commit('rules/setActions', this.completeActionsSelected);
    },
    buildActionsArray(action, index, name, type) {
      if (this.completeActionsSelected[index] && type === 'dict') {
        if (this.completeActionsSelected[index].params.data) {
          this.completeActionsSelected[index].params.data[name] = action.params.data[name];
        } else {
          this.completeActionsSelected[index].params.data = action.params.data;
        }
      } else if (this.completeActionsSelected[index] && name !== 'dict') {
        this.completeActionsSelected[index].params[name] = action.params[name];
      } else {
        this.completeActionsSelected[index] = action;
      }
      this.$store.commit('rules/setActions', this.completeActionsSelected);
      const actionValues = Object.values(this.completeActionsSelected[index].params);
      const actionDictType = this.completeActionsSelected[index].params.data;
      if (actionDictType && Object.keys(actionDictType).length !== 2) {
        this.$refs.actionOptions[index].emptyAction();
      } else if (actionValues.every((element) => (typeof element === 'string' || typeof element === 'number') && element !== '') === true) {
        this.$refs.actionOptions[index].verifyAction(this.completeActionsSelected);
      } else if (actionValues.some((element) => element === '' || !element)) {
        this.$refs.actionOptions[index].emptyAction();
      } else {
        this.$refs.actionOptions[index].verifyAction(this.completeActionsSelected);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.then-rules {
  margin-top: 20px;
  &__title {
    display: flex;
  }
}
</style>
