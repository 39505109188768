<template>
  <div>
    <div class="sidebar">
      <!-- SIDEBAR HEADER -->
      <div class="sidebar__header">
        <div class="sidebar__header__img">
          <img class="img-fluid" src="@/assets/images/glt-logo.png" />
        </div>
      </div>
      <!-- SIDEBAR BODY -->
      <div class="sidebar__body mt-2">
        <ul class="list-group list-group-flush">
          <li class="list-group-item list-group-item-action">
            <a class="the-sidebar-link" @click="changeRoute('/admin')">
              <span class="the-sidebar-link-icon"><i class="pi pi-user-edit"></i></span>
              <span class="the-sidebar-link-title">Admin</span>
            </a>
          </li>
          <li class="list-group-item list-group-item-action">
            <a class="the-sidebar-link" @click="changeRoute('/carriers')">
              <span class="the-sidebar-link-icon"><b-icon icon="truck"></b-icon></span>
              <span class="the-sidebar-link-title">Carriers</span>
            </a>
          </li>
          <li class="list-group-item list-group-item-action">
            <a class="the-sidebar-link" @click="changeRoute('/carrier-rules')">
              <span class="the-sidebar-link-icon"><b-icon icon="list-ul"></b-icon></span>
              <span class="the-sidebar-link-title">Rules</span>
            </a>
          </li>
          <li class="list-group-item list-group-item-action">
            <a class="the-sidebar-link" @click="changeRoute('/accessorials')">
              <span class="the-sidebar-link-icon"><b-icon icon="list-ul"></b-icon></span>
              <span class="the-sidebar-link-title">Accessorials</span>
            </a>
          </li>
          <li class="list-group-item list-group-item-action">
            <a class="the-sidebar-link" @click="changeRoute('/canned-responses')">
              <span class="the-sidebar-link-icon"><i class="pi pi-inbox"></i></span>
              <span class="the-sidebar-link-title">Canned Responses</span>
            </a>
          </li>
          <li class="list-group-item list-group-item-action">
            <a class="the-sidebar-link" @click="changeRoute('/testing-laboratory')">
              <span class="the-sidebar-link-icon"><b-icon icon="plus-circle"></b-icon></span>
              <span class="the-sidebar-link-title">Testing Laboratory</span>
            </a>
          </li>
        </ul>
      </div>
      <CompaniesMenu />
    </div>
    <HeaderMobile />
    <!-- <Button
      icon="pi pi-user"
      class="p-button-rounded sidebar__button"
      @click="toggle"
      aria-haspopup="true"
      aria-controls="overlay_menu"
    />
    <TieredMenu id="overlay_menu" ref="menu" :model="items" :popup="true" />
    <TieredMenu id="overlay_departments" ref="departments" :model="departments" :popup="true" /> -->
  </div>
</template>

<script>
import CompaniesMenu from "./CompaniesButtonMenu.vue";
import HeaderMobile from "./HeaderMobile.vue";

export default {
  name: "Sidebar",
  components: {
    CompaniesMenu,
    HeaderMobile,
  },
  data() {
    return {
      departments: [],
    };
  },
  methods: {
    changeRoute(route) {
      this.$router.push(route).catch((err) => {
        if (
          err.name !== "NavigationDuplicated"
          && !err.message.includes("Avoided redundant navigation to current location")
        ) {
          // logError(err);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  background-color: $color-background-dark;
  color: #fff;
  width: 95px;
  height: 100vh;
  z-index: 1000;
  transition: all 0.3s;
  position: fixed;
  &__header {
    background-color: $color-background-darker;

    &__img {
      display: inline-block;
      margin: 0 auto;
      width: 90%;
    }
  }
  &__body {
    height: 65%;
  }
  .the-sidebar-link {
    color: #eceff1;
    cursor: pointer;
    width: 100%;
    display: inline-block;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    text-align: center;
    text-decoration: none;

    .the-sidebar-link-icon {
      margin-bottom: 4px;
    }

    .the-sidebar-link-title {
      font-size: 0.8em;
    }

    .link-icon {
      width: 1.2rem;
    }

    &:hover {
      background-color: #515d6a;
      color: #5fdd0f;
    }
  }

  .the-sidebar-dropdown-link {
    color: #eceff1;
    cursor: pointer;
    width: 100%;
    display: inline-block;
    padding: 0.3rem;

    &:hover {
      background-color: #515d6a;
    }
  }
}
:deep(.p-tieredmenu-overlay) {
  position: relative;
  top: auto !important;
  bottom: 11.5rem !important;
  left: 2rem !important;
}
.list-group-item,
.list-group-item:hover {
  background-color: $color-background-dark;
  padding: 0;
}
@media (max-width: 900px) {
  .sidebar {
    background: none;
    &__header {
      display: none;
    }
    &__body {
      display: none;
    }
  }
  :deep(.companies-button-menu) {
    position: absolute;
    height: 95vh;
    display: flex;
    align-items: flex-end;
    left: 15px;
    z-index: 1000;
  }
  .list-group-item,
  .list-group-item:hover {
    background-color: $color-background-darker;
    padding: 0;
  }
  :deep(.p-tieredmenu-overlay) {
    position: absolute;
    top: auto !important;
    bottom: 3rem !important;
    left: 0.5rem !important;
  }
}
</style>
