<template>
  <div class="complex-conditions" :key="renderComponent">
    <br />
    <b-button
      class="complex-conditions__main-button"
      @click="showConditions = !showConditions"
      v-b-toggle.collapse-1
      variant="outline-info"
      ><b-icon :icon="showConditions ? 'chevron-double-down' : 'chevron-double-up'"></b-icon>
        Conditions</b-button
    >
    <b-collapse class="complex-conditions__main-collapse" visible id="collapse-1">
      <div class="if-rules-header">
        <DropdownComponent
         v-if="Object.entries(conditionArrayCopy)?.length === 0"
         :itemsList="itemsCreateMainArray"
         titleButton="Main Container"
         iconButton="save2"
         @selectOption="selectedOptionMainContainer"
         />
      </div>
      <Panel
        v-if="conditionArrayCopy.all || conditionArrayCopy.any"
        class="complex-conditions-sub-collapse"
        :header="`${mainTab === 'all' ? 'AND' : 'OR'}`"
        :toggleable="true"
      >
        <template #header>
          <div class="complex-conditions-sub-collapse__header">
            <span>{{ `${mainTab === "all" ? "AND" : "OR"}` }}</span>
            <div class="complex-conditions-sub-collapse__header-options">
              <NewCondition
                @pushNewConditionBlock="pushNewConditionBlock"
                :conditionsData="conditionsData"/>
              <DropdownComponent
                :itemsList="itemsCreateMainArray"
                titleButton="New Container"
                iconButton="save2"
                @selectOption="selectedOptionNewContainer"
                />
              <b-button
                @click="deleteMainArray()"
                size="sm"
                pill
                variant="primary"
                class="complex-conditions-sub-collapse__header-options--button"
              >
                <i class="pi pi-trash"></i>
              </b-button>
            </div>
          </div>
        </template>
        <CollapseComponent
          @renderAll="renderAll"
          class="complex-conditions__tab"
          :conditionSubArray="this.conditionArrayCopy?.all || this.conditionArrayCopy?.any"
          :originArray="this.conditionArrayCopy"
          :conditionsData="conditionsData"
          :closeCollapse="true"
        />
        <div
         v-for="(element, index) in this.conditionArrayCopy?.all || this.conditionArrayCopy?.any"
              :key="index">
          <OptionContainer
              v-if="element.name"
              :blockNumber="index"
              :conditionEditData = element
              @deleteOptionContainer="deleteOptionContainer"
              @editCondition="editCondition"
            />
        </div>
      </Panel>
    </b-collapse>
  </div>
</template>

<script>
import { deleteMessage } from "@/utils/alertMessages";
import Panel from "primevue/panel";
import CollapseComponent from "./ComponentsConditions/CollapseComponent.vue";
import DropdownComponent from "./ComponentsConditions/DropdownComponent.vue";
import OptionContainer from "./OptionContainer.vue";
import NewCondition from "./NewCondition.vue";

export default {
  name: "ComplexConditions",
  props: {
    conditionsData: {
      type: Array,
    },
    editConditions: {
      type: Object,
    },
  },
  components: {
    CollapseComponent,
    Panel,
    DropdownComponent,
    OptionContainer,
    NewCondition,
  },
  data() {
    return {
      showConditions: false,
      conditionArrayCopy: {},
      renderComponent: 0,
      countSimpleConditions: 0,
      itemsCreateMainArray: [
        { value: 'all', text: "[ ] AND Container" },
        { value: 'any', text: "[ ] OR Container" },
      ],
    };
  },
  computed: {},
  watch: {},
  created() {
    if (Object.keys(this.editConditions).length > 0) {
      [this.mainTab] = Object.keys(this.editConditions);
      this.conditionArrayCopy = { ...this.editConditions };
      const conditionsCopy = JSON.parse(JSON.stringify(this.editConditions));
      const mainTab = [this.mainTab];
      this.recursiveFunction(conditionsCopy, mainTab);
    }
  },
  methods: {
    recursiveFunction(conditionsSchema, mainTab) {
      const conditionsCopy = { ...conditionsSchema };
      for (let i = 0; i < conditionsCopy[mainTab]?.length; i += 1) {
        if (conditionsCopy[mainTab][i].name) {
          this.countSimpleConditions += 1;
          conditionsCopy[mainTab][i].identifierNumber = `condition-${this.countSimpleConditions}`;
        } else {
          const lastMainTab = [...mainTab];
          const newMainTab = Object.keys(conditionsCopy[mainTab][i]);
          const newConditionsObject = JSON.parse(JSON.stringify(conditionsCopy[lastMainTab][i]));
          this.recursiveFunction(newConditionsObject, newMainTab);
        }
      }
    },
    async deleteMainArray() {
      const warningResponse = await deleteMessage(
        "Are you sure you want to remove the main container from the conditions?",
        "Remember that you will not be able to revert it and all the conditions it contains will be erased.",
      );
      if (warningResponse.isConfirmed) {
        delete this.conditionArrayCopy[this.mainTab];
        this.renderComponent += 1;
      }
    },
    selectedOptionMainContainer(value) {
      if (Object.entries(this.conditionArrayCopy).length === 0) {
        this.conditionArrayCopy[`${value.command}`] = [];
        [this.mainTab] = Object.keys(this.conditionArrayCopy);
        this.renderComponent += 1;
      }
    },
    selectedOptionNewContainer(value) {
      const addArray = { [`${value.command}`]: [] };
      this.conditionArrayCopy[this.mainTab].push(addArray);
      this.renderComponent += 1;
      this.$store.commit("rules/setConditionSchema", this.conditionArrayCopy);
    },
    pushNewConditionBlock(value) {
      const addArray = {};
      addArray.name = value.selectedCondition[0].name;
      addArray.operator = null;
      addArray.value = null;
      this.conditionArrayCopy[this.mainTab].push(addArray);
      this.renderComponent += 1;
      this.$store.commit("rules/setConditionSchema", this.conditionArrayCopy);
    },
    async deleteOptionContainer(indexElementToBeDeleted) {
      // This function deletes the condition inside the main Array,
      const warningResponse = await deleteMessage(
        "Are you sure you want to remove this condition?",
        "Remember that you will not be able to revert it.",
      );
      if (warningResponse.isConfirmed) {
        this.conditionArrayCopy[this.mainTab].splice(indexElementToBeDeleted.currentIndex, 1);
      }
      this.renderComponent += 1;
      this.$store.commit("rules/setConditionSchema", this.conditionArrayCopy);
    },
    editCondition(editElement) {
      const array = this.conditionArrayCopy[this.mainTab];
      array[editElement.currentIndex] = { ...editElement.objectCondition };
      this.$store.commit("rules/setConditionSchema", this.conditionArrayCopy);
    },
    renderAll() {
      this.renderComponent += 1;
    },
  },
};
</script>

<style scoped lang="scss">
.complex-conditions {
  &__main-button {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    border: 2px solid $color-primary-button;
    color: $color-primary-button;
    &:hover {
      background-color: $color-primary-button;
      color: #fff;
    }
    &:active {
      background-color: rgba($color-primary-button, 0.8) !important;
    }
  }
  &__main-collapse {
    margin-top: 2rem;
  }
  &__tab {
    margin: 0 10px;
  }
}

.complex-conditions-sub-collapse {
  padding: 1rem 0.4rem;
  border: 2px solid rgba(128, 128, 128, 0.167);
  border-radius: 0.5rem;
  box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2),
    0 1px 8px 0 rgba(0, 0, 0, 0.12);
  margin: 10px 0;
  height: 100%;
  width: 100%;
  &__header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    span {
      font-weight: 700;
      color: #495057;
      font-size: 13px;
    }
  }
  &__header-options {
    display: flex;
    justify-content: space-around;
    align-items: center;
    &--button {
      background-color: transparent;
      color: #6c757d;
      padding: 0px;
      width: 26px;
      height: 25px;
      border-radius: 20px;
      border: none;
      &:hover, &:active, &:focus  {
        border-radius: 20px;
        border: none !important;
        background-color: transparent !important;
        color: #6c757d !important;
      }
    }
  }
}

.if-rules-header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: baseline;
  :deep(.dropdown-component__button) {
    font-size: 16px;
    width: 100%;
  }
  :deep(.el-dropdown) {
    width: 100%;
  }
}

:deep(.p-panel .p-panel-content) {
  border: none;
}
:deep(.p-panel .p-panel-header) {
  background: none;
  border-radius: 5px;
}
</style>
