<template>
  <div>
    <Sidebar />
    <div class="rules-modal">
      <div>
        <h1 class="rules-modal__content-tittle">Rules</h1>
        <hr class="rules-modal__content-separator" />
        <Button
          icon="pi pi-arrow-left"
          class="p-button-rounded p-button-outlined rules-modal__button"
          @click="backToCards()"
        />
        <div v-loading="isLoading">
          <a
            v-if="!isLoading"
            class="scrollNavigation top"
            @click="directionNavigation(0, 0)"
            href="#top"
            ><i class="pi pi-arrow-up"></i
          ></a>
          <a
            v-if="!isLoading"
            class="scrollNavigation bottom"
            @click="directionNavigation(0, 50000)"
            href="#bottom"
            ><i class="pi pi-arrow-down"></i
          ></a>
          <AssociateProfForm :entity="'rules'" :ruleEdition="ruleEdition" />
          <hr class="rules-modal__info-separator" />
          <div class="rules-modal__row">
            <div class="rules-modal__column">
              <label class="rules-modal__text" for="name">Name <b style="color: red">*</b></label>
              <InputText
                :key="renderEditor"
                class="rules-modal__input"
                :class="validField.name"
                id="name"
                type="text"
                v-model="rulesName"
                @change="validField.name = changeFieldsColor(rulesName, 'name')"
              />
            </div>
            <div class="rules-modal__column">
              <label class="rules-modal__text" for="description"
                >Description <b style="color: red">*</b></label
              >
              <InputText
                :key="renderEditor"
                class="rules-modal__input"
                :class="validField.description"
                id="description"
                type="text"
                v-model="rulesDescription"
                @change="
                  validField.description = changeFieldsColor(rulesDescription, 'description')
                "
              />
            </div>
            <div class="rules-modal__column">
              <label class="rules-modal__text" for="description"
                >Rule Importance <b style="color: red">*</b></label
              >
              <InputText
                v-tooltip="{ value: ruleImportant, disabled: false }"
                :key="renderEditor"
                class="rules-modal__input"
                :class="validField.weight"
                id="weight"
                type="number"
                v-model="rulesWeight"
                @change="validField.weight = changeFieldsColor(rulesWeight, 'weight')"
                autocomplete="off"
              />
            </div>
            <div class="rules-modal__column">
              <label class="rules-modal__text">Status</label>
              <Dropdown
                class="rules-modal__input"
                v-model="ruleStatus"
                placeholder="Select status"
                :options="statusOptions"
                optionLabel="name"
                optionValue="label"
              />
            </div>
            <div class="rules-modal__line">
              <label class="rules-modal__line__text" for="label"
                >Label <b style="color: red">*</b></label
              >
              <b-form-tags
                v-tooltip="'Recuerda que se debe ingresar como mínimo 1 label.'"
                :key="renderEditor"
                class="rules-modal__line__input"
                :class="validField.label"
                input-id="label"
                v-model="selectedLabel"
                @input="validField.label = changeFieldsColor(selectedLabel, 'label')"
              >
              </b-form-tags>
              <!-- <Chips :key="renderEditor"
            class="rules-modal__chips"
            :class="validField.label"
            id="label" v-model="selectedLabel" separator=","
            @input="validField.label = changeFieldsColor(selectedLabel, 'label')"
          /> -->
            </div>
          </div>
          <FormulasTable />
          <CreateRule ref="createRule" :ruleData="ruleInfo.data" />
          <b-overlay
            class="overlay-button"
            :show="activeSpinner"
            rounded
            opacity="0.6"
            spinner-small
          >
            <div v-tooltip="{ value: sendMsg, disabled: activeSpinner }">
              <b-button
                class="rules-modal__create-button"
                :disabled="(!ruleEdition && !checkFields) || activeSpinner"
                @click="!ruleEdition ? verifyForm() : verifyUpdate()"
              >
                {{ !ruleEdition ? `Create Rule` : "Save Changes" }}
              </b-button>
            </div>
          </b-overlay>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// eslint-disable-next-line import/no-cycle
import { errorDynamicMessage, deleteMessage } from "../../../utils/alertMessages";
// eslint-disable-next-line import/no-cycle
import {
  validateEmptyContainers,
  cleanValidateArray,
  validateAllConditionContents,
  conditionValidationMessages,
} from "../../../mixins/validateRules";
import CreateRule from "./CreateRule.vue";
import FormulasTable from "./FormulasTable.vue";
import AssociateProfForm from "../../../components/AssociateProfForm.vue";
import Sidebar from "../../../components/Sidebar.vue";

export default {
  name: "CreateRuleMain",
  components: {
    FormulasTable,
    AssociateProfForm,
    Sidebar,
    CreateRule,
  },
  data() {
    return {
      isLoading: false,
      activeSpinner: false,
      rulesName: null,
      rulesDescription: null,
      rulesWeight: null,
      companyInfo: null,
      validField: {},
      renderEditor: 0,
      checkFields: false,
      resultsActions: [],
      resultsValueConditions: null,
      errorMessage: "",
      ruleInfo: {},
      ruleEdition: false,
      ruleDataOptions: {},
      ruleStatus: "active",
      statusOptions: [
        { name: "Active", label: "active" },
        { name: "Inactive", label: "inactive" },
      ],
      saveSpinner: false,
      selectedLabel: [],
      ruleImportant:
        "La importancia de la regla indica en que orden se ejecutará, es decir, las reglas con menor valor se ejecutarán primero y las de mayor valor de último. Por ejemplo, si la regla A, B Y C tienen rule important de 1, las reglas D Y E tienen rule important de 2, y la regla F tiene rule important de 3; el orden de ejecución será: A, B, C, D, E, F.",
      sendMsg:
        "Recuerda llenar todos los datos requeridos indicados con un '*'. Las condiciones y las acciones.",
      conditionSchema: null,
      mainNameConditionSchema: null,
      emptyContainers: true,
      validateIfFormula: true,
    };
  },
  async created() {
    this.$store.commit("rules/setResetValues");
    await this.getRulesData();
  },
  computed: {
    ...mapGetters("company", ["showProfileCompanysDepartment"]),
  },
  watch: {
    showProfileCompanysDepartment() {
      this.companyInfo = [...this.showProfileCompanysDepartment.data];
      this.getRulesData();
    },
  },
  methods: {
    async getRulesData() {
      this.isLoading = true;
      if (this.companyInfo) {
        const exportParams = {
          profile_id: this.companyInfo[0]?.profile_id,
        };
        this.ruleDataOptions = await this.$store.dispatch("rules/exportRulesData", exportParams);
        this.isLoading = !!this.ruleDataOptions?.status;
        this.$store.commit("rules/setRuleCompleteObject", this.ruleDataOptions);
      }
      this.getRuleById();
    },
    async getRuleById() {
      const companyData = this.$store.getters["user/showCurrentCompanyDepartment"];
      if (Object.keys(this.$route.params).length > 0 && this.$route.params.ruleId && companyData) {
        this.isLoading = true;
        this.ruleEdition = true;
        const ruleData = this.$route.params;
        const params = {
          company_id: companyData?.chosenCompany.id,
          department_id: companyData?.chosenDepartment.id,
          profile_id: ruleData?.profileId,
          rule_id: ruleData?.ruleId,
        };
        this.ruleInfo = await this.$store.dispatch("rules/getRuleById", params);
        this.isLoading = false;
        this.rulesName = this.ruleInfo.data.name;
        this.selectedLabel = this.ruleInfo.data.label;
        this.rulesDescription = this.ruleInfo.data.description;
        this.rulesWeight = this.ruleInfo.data.rule_weight;
        this.ruleStatus = this.ruleInfo.data.status;
      }
    },
    async verifyUpdate() {
      this.activeSpinner = true;
      const warningResponse = await deleteMessage(
        "Are you sure you want to update the rule?",
        "You won't be able to revert this!",
      );
      if (warningResponse.isConfirmed) {
        this.verifyForm();
      }
      this.activeSpinner = false;
    },
    async verifyForm() {
      this.isLoading = true;
      this.activeSpinner = true;
      await this.validateAllConditionContents();
      await this.validateConditionsSchema();
      await this.validateConditionsSchema();
      if (!this.emptyContainers) {
        const infoRules = {
          params: {},
          body: {},
        };
        infoRules.params.profile_id = this.companyInfo[0].profile_id;
        infoRules.params.company_id = this.companyInfo[0].company_id;
        infoRules.params.department_id = this.companyInfo[0].department_id;
        infoRules.body.name = this.rulesName;
        infoRules.body.description = this.rulesDescription;
        infoRules.body.label = this.selectedLabel;
        infoRules.body.rule_weight = Number(this.rulesWeight);
        infoRules.body.status = this.ruleStatus;
        const valueConditions = this.$store.getters["rules/showValueConditions"];
        const actions = this.$store.getters["rules/showActions"];
        const booleanActions = this.$store.getters["rules/showBooleanActions"];
        if (actions.length === 0 && valueConditions.length === 0) {
          this.errorMessage = "Conditionals and actions";
        } else if (valueConditions.length === 0) {
          this.errorMessage = "Conditionals";
        } else if (actions.length === 0) {
          this.errorMessage = "Actions";
        }
        if (actions.length === 0 || valueConditions.length === 0) {
          errorDynamicMessage("Oops...", `${this.errorMessage} are required`);
        } else if (booleanActions.some((element) => element === false)) {
          errorDynamicMessage("Oops...", "Please fill in all actions' parameters");
        } else if (this.resultsValueConditions) {
          this.validateValue(actions);
          this.validateIfFormula = this.$store.getters["rules/showAllFormulasChecked"];
          if (this.resultsActions.some((element) => element === true)) {
            this.errorMessage = "actions";
            errorDynamicMessage("Oops...", `Please verify the ${this.errorMessage}`);
          } else if (this.validateIfFormula) {
            if (this.ruleEdition) {
              infoRules.params.rule_id = this.$route.params.ruleId;
              this.updateRule(infoRules);
            } else {
              this.createRule(infoRules);
            }
          } else {
            errorDynamicMessage("Oops...", `Please validate the formulas`);
          }
        }
        this.activeSpinner = false;
        this.isLoading = false;
      } else {
        errorDynamicMessage("Oops...", `Please verify that no containers are empty.`);
        this.isLoading = false;
        this.activeSpinner = false;
      }
    },
    async validateConditionsSchema() {
      if (this.conditionSchema[this.mainNameConditionSchema]) {
        this.emptyContainers = await validateEmptyContainers(
          this.conditionSchema[this.mainNameConditionSchema],
        );
        await this.revalidateEmptyContainers();
        this.$store.commit("rules/setConditionSchema", this.conditionSchema);
      }
    },
    async revalidateEmptyContainers() {
      if (this.emptyContainers) {
        await this.validateConditionsSchema();
      }
    },
    async validateAllConditionContents() {
      this.conditionSchema = this.$store.getters["rules/showConditionsSchema"];
      [this.mainNameConditionSchema] = Object.keys(this.conditionSchema);
      if (this.conditionSchema[this.mainNameConditionSchema]) {
        cleanValidateArray();
        const allConditionsValidated = await validateAllConditionContents(
          this.conditionSchema[this.mainNameConditionSchema],
        );
        this.resultsValueConditions = await conditionValidationMessages(allConditionsValidated);
      }
    },
    async createRule(infoRules) {
      this.isLoading = true;
      this.activeSpinner = true;
      const response = await this.$store.dispatch("rules/createRule", infoRules);
      this.isLoading = false;
      this.activeSpinner = false;
      if (response.status === 201) {
        this.$swal.fire({
          icon: "success",
          title: "The rule has been created",
          showConfirmButton: false,
          timer: 1500,
        });
        this.backToCards();
      }
    },
    async updateRule(infoRules) {
      this.isLoading = true;
      this.activeSpinner = true;
      const response = await this.$store.dispatch("rules/updateRule", infoRules);
      this.isLoading = false;
      this.activeSpinner = false;
      if (response.status === 204) {
        this.$swal.fire({
          icon: "success",
          title: "The rule has been updated",
          showConfirmButton: false,
          timer: 1500,
        });
        this.backToCards();
      }
    },
    backToCards() {
      this.$store.commit("rules/setResetValues");
      this.$router.push({ name: "Rules" });
    },
    changeFieldsColor(fields, type) {
      this.fieldsValidation();
      if (type !== "label" && fields) {
        this.renderEditor += 1;
        return "rules-modal__input--input-valid";
      }
      if (type === "label" && fields.length > 0) {
        this.renderEditor += 1;
        return "rules-modal__input--input-valid";
      }
      this.renderEditor += 1;
      return "p-invalid";
    },
    fieldsValidation() {
      if (this.rulesName && this.selectedLabel.length > 0 && this.rulesWeight) {
        this.checkFields = true;
      } else {
        this.checkFields = false;
      }
      return this.checkFields;
    },
    validateValue(actions) {
      actions.forEach((action, index) => {
        const array = Object.values(action.params);
        this.resultsActions[index] = array.some(
          (element) => (typeof element === "number" && Number.isNaN(parseInt(element, 10)))
            || (typeof element !== "number" && !element),
        );
      });
    },
    directionNavigation(number1, number2) {
      window.scrollTo(number1, number2);
    },
  },
};
</script>

<style lang="scss" scoped>
.rules-modal {
  padding: 2rem 2rem 0 6rem;
  &__content-tittle {
    font-weight: bold;
  }
  &__content-separator {
    width: 95%;
    margin: auto;
  }
  &__info-separator {
    width: 95%;
    margin: 1.5rem auto 1rem;
  }
  &__button {
    @include button-outlined;
    margin-top: 1rem;
    float: left;
    left: 2rem;
  }
  &__create-button {
    @include submit-button;
  }
  &__row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 2rem;
  }
  &__column {
    display: flex;
    padding-top: 0.5rem;
  }
  &__text {
    width: 92px;
    align-self: center;
    color: $color-input-text;
    margin: 0 0.5rem 0;
  }
  &__input {
    width: 20rem;
    @include input;
    // height: auto;
    &--input-valid {
      border: 1px solid #00b900;
      border-radius: 8px;
    }
    &--input-invalid {
      border: 1px solid #f44336;
      border-radius: 8px;
    }
  }
  &__chips {
    margin: 2px;
  }
  &__dropdown {
    width: 65%;
    @include input;
  }
  &__line {
    display: flex;
    margin-top: 0.8rem;
    &__text {
      width: 92px;
      color: $color-input-text;
      margin: 0 0.5rem 0;
    }
    &__input {
      width: 20rem;
      @include input;
      // height: auto;
      height: 6rem !important;
      overflow-y: auto;
      &--input-valid {
        border: 1px solid #00b900;
        border-radius: 8px;
      }
      &--input-invalid {
        border: 1px solid #f44336;
        border-radius: 8px;
      }
    }
  }
  &__scroll-container {
    @include button-outlined;
    position: fixed;
    right: 10px;
    top: 50%;
    background-color: #ffffff;
    border: 1px solid #00ff00;
    width: 37px;
    height: 37px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    &:hover {
      color: $color-submit-hover !important;
    }
  }
}
:deep(.associate-prof-form) {
  margin: 4rem 2rem 0;
  &__selection-associate {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    & hr {
      display: none;
    }
  }
  &__row {
    margin: 0;
    padding-top: 0.5rem;
    &--label {
      width: 80px;
      margin: 0 0.5rem 0;
    }
    &--input {
      width: 20rem;
      height: auto;
    }
  }
}
.overlay-button {
  display: flex;
  margin: auto;
  justify-content: center;
  padding: 2rem 1rem 1rem;
}
:deep(.p-multiselect-label) {
  padding-top: 0.3rem;
}
:deep(.p-dropdown-label) {
  padding-top: 0.3rem;
}
:deep(.p-chips-multiple-container) {
  @include input;
  width: 20rem;
  height: auto;
  margin: 0;
}
:deep(.p-chips-multiple-container) {
  overflow: auto;
}
.scrollNavigation {
  @include button-outlined;
  position: fixed;
  right: 10px;
  bottom: 10px;
  background-color: #ffffff;
  border: 1px solid #00ff00;
  width: 37px;
  height: 37px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  text-decoration: none;
  &:hover {
    color: $color-submit-hover !important;
  }
}
.scrollNavigation.top {
  bottom: calc(10px + 40px);
}
:deep(.el-loading-spinner i) {
  color: #a5b1b7;
  scale: 5;
  animation: loading-rotate 1.5s linear infinite;
}
:deep(.el-loading-spinner .path) {
  stroke: #a5b1b7;
}
:deep(.el-loading-spinner .el-loading-text) {
  color: #a5b1b7;
  font-size: 16px;
  font-weight: 500;
}
:deep(.el-loading-spinner) {
  top: 20%;
}
</style>
