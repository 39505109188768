<template>
  <div class="message-haul">
    <div class="message-haul__card">
      <div class="message-haul__container">
        <b-form-input
          v-for="(textInput, index) in textTypeInputs"
          :key="index"
          v-model="textTypeInputs[index]"
          @change="sendMessage()"
          :id="textInput"
          :placeholder="index.split('_').join(' ')"
          size="sm"
          autocomplete="off"
        ></b-form-input>
        <b-form-input
          id="tooltip-target-1"
          v-model="client_email"
          @change="sendMessage()"
          placeholder="client email"
          @update="validateEmail(client_email)"
          size="sm"
        ></b-form-input>
        <!-- <b-tooltip :show="!isEmail" target="tooltip-target-1" triggers="focus">
          Please, enter an email address.
        </b-tooltip> -->
        <span v-if="!isEmails" class="message-haul__red-alert"
          >Please, enter an email address.</span
        >
        <b-form-checkbox
          v-model="validation_alert"
          @change="sendMessage()"
          name="checkbox-requote"
          class="checkbox"
          >validation_alert
        </b-form-checkbox>
      </div>
      <div class="message-haul__container">
        <b-form-tags
          v-for="(tagInput, index) in tagTypeInputs"
          :key="index"
          v-model="tagTypeInputs[index]"
          @keyup.enter="sendMessage()"
          tag-variant="primary"
          tag-pills
          class="message-haul__input-tags"
        ></b-form-tags>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MessageHaul",
  data() {
    return {
      textTypeInputs: {
        subject: "",
        entry_type: "",
        shared_email: "",
        content: "",
      },
      client_email: "",
      validation_alert: true,
      tagTypeInputs: {
        bcc: [],
        cc: [],
        tag_email: [],
      },
      isEmails: true,
    };
  },
  methods: {
    validateEmail(emails) {
      this.isEmails = this.validateEmailText(emails);
    },
    validateEmailText(emails) {
      if (emails !== null && emails !== undefined && emails !== "") {
        if (
          /^.*[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+(?:\.[a-zA-Z0-9-]+)*$/.test(
            emails,
          )
        ) {
          return true;
        }
      }
      return false;
    },
    sendMessage() {
      const message = {
        subject: this.textTypeInputs.subject,
        entry_type: this.textTypeInputs.entry_type,
        shared_email: this.client_email,
        content: this.textTypeInputs.content,
        client_email: this.client_email,
        validation_alert: this.validation_alert,
        bcc: this.tagTypeInputs.bcc,
        cc: this.tagTypeInputs.cc,
        tag_email: this.tagTypeInputs.tag_email,
      };
      return this.$emit("input", message);
    },
  },
};
</script>

<style lang="scss" scoped>
.message-haul {
  width: 100% !important;
  @include card;
  margin-top: 2rem;
  &__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    text-align: start;
    margin-bottom: 10px;
  }
  &__red-alert {
    width: 100% !important;
    color: red;
    font-size: 12px;
    margin-top: 20px / 2;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
  }
  &__input-tags {
    height: 100px !important;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 8px; /* width of the entire scrollbar */
    }
    &::-webkit-scrollbar-thumb {
      background-color: #797979;
      border: 2px solid #f1f2f3;
    }
  }
}
</style>
