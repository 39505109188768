import CreateLTLRule from "../../views/CarriersRules/Components/CreateLTLRule.vue";

const createLTLRuleRoute = [
  {
    path: "/ltl-rule",
    name: "CreateLTLRule",
    component: CreateLTLRule,
  },
];

export default createLTLRuleRoute;
