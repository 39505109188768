import api from '../clients/axios';

const resource = 'api/v1/departments';

export default {
  async getAllDepartments() {
    const response = await api.get(`${resource}`);
    return response.data;
  },

  async getDepartmentById(departmentId) {
    const response = await api.get(`${resource}/${departmentId}`);
    return response.data;
  },

  updateDepartment(payload) {
    return api.patch(`${resource}/${payload.department_id}`, payload.data);
  },

  createDepartment(payload) {
    return api.post(`${resource}`, payload);
  },

  deleteDepartment(params) {
    return api.delete(`${resource}/${params.department_id}?confirmation_message=${params.confirmation_message}`);
  },
};
