import Rules from "../../views/CarriersRules/Index.vue";

const RulesRoute = [
  {
    path: "/carrier-rules",
    name: "Rules",
    component: Rules,
  },
];

export default RulesRoute;
