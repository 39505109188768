export function userInformation(userInfo) {
  const user = {};
  if (userInfo.role === "global_admin") {
    user.company_id = -2;
    user.department_id = -2;
  } else if (userInfo.role === "company_admin") {
    user.company_id = userInfo.company_id;
    user.department_id = -2;
  } else if (userInfo.role === "department_admin") {
    user.company_id = userInfo.company_id;
    user.department_id = userInfo.company_info[0].departments;
  }

  return user;
}
export function rolPermissions(rol, type) {
  const rolesPermissions = {};
  if (rol.role === "global_admin" || type === 'User' || type === 'Rule') {
    rolesPermissions.noActions = false;
    rolesPermissions.isAllowedCreate = true;
    rolesPermissions.isAllowedDelete = true;
    rolesPermissions.isAllowedEdit = true;
  } else {
    rolesPermissions.noActions = true;
    rolesPermissions.isAllowedCreate = false;
    rolesPermissions.isAllowedDelete = false;
    rolesPermissions.isAllowedEdit = false;
  }
  return rolesPermissions;
}
