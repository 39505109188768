var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"carrier"},[_c('div',{staticClass:"carrier__card"},[_c('h3',[_vm._v("Carrier")]),_c('b-alert',{attrs:{"show":"","variant":"warning"}},[_c('b',{staticStyle:{"color":"red"}},[_vm._v("* ")]),_vm._v(" Please, fill out at least one carrier")]),_c('div',{staticClass:"carrier__action-carriers"},[_c('b-button',{staticClass:"carrier__button",attrs:{"variant":"info"},on:{"click":function($event){return _vm.addRow()}}},[_vm._v("Add new carrier")]),_c('b-button',{staticClass:"carrier__button",attrs:{"disabled":_vm.carriers.length === 1,"variant":"info"},on:{"click":function($event){return _vm.deleteRow()}}},[_vm._v("Delete Carrier")])],1),_vm._l((_vm.carriers),function(carrierObject,i){return _c('div',{key:i},[_c('div',{staticClass:"carrier__container-input"},[_c('Dropdown',{staticClass:"carrier__input",class:_vm.carriers[i].carrier ? 'carrier__input--valid' : 'p-invalid',attrs:{"options":_vm.allCarriersData,"optionLabel":"carrier_name","dataKey":"id","filter":true,"placeholder":"carrier *"},on:{"change":function($event){return _vm.setCarrierOption(i, _vm.carrierSelected[i])}},model:{value:(_vm.carrierSelected[i]),callback:function ($$v) {_vm.$set(_vm.carrierSelected, i, $$v)},expression:"carrierSelected[i]"}}),_c('Dropdown',{staticClass:"carrier__input",class:_vm.carriers[i].scac ? 'carrier__input--valid' : 'p-invalid',attrs:{"options":_vm.allCarriersData,"optionLabel":"carrier_scac","filter":true,"placeholder":"scac *"},on:{"change":function($event){return _vm.setScacOption(i, _vm.scacSelected[i])}},model:{value:(_vm.scacSelected[i]),callback:function ($$v) {_vm.$set(_vm.scacSelected, i, $$v)},expression:"scacSelected[i]"}}),_vm._l((carrierObject),function(value,j){return _c('InputText',{directives:[{name:"show",rawName:"v-show",value:(
            j !== 'documents_required_for_dispatch' &&
            j !== 'automatic_dispatch' &&
            j !== 'notify_glt_ops' &&
            j !== 'connection' &&
            j !== 'scac' &&
            j !== 'carrier'
          ),expression:"\n            j !== 'documents_required_for_dispatch' &&\n            j !== 'automatic_dispatch' &&\n            j !== 'notify_glt_ops' &&\n            j !== 'connection' &&\n            j !== 'scac' &&\n            j !== 'carrier'\n          "}],key:j,staticClass:"carrier__input",class:_vm.validateColorField(j, _vm.carriers[i][j]),attrs:{"placeholder":j.split('_').join(' ') + _vm.validateField(j),"type":j == 'third_party_id' ||
            j == 'used_liability' ||
            j == 'transit_time' ||
            j == 'price' ||
            j == 'new_liability' ||
            j == 'pickup_performance' ||
            j == 'delivery_performance'
              ? 'number'
              : 'text'},on:{"change":function($event){return _vm.sendCarrier()}},model:{value:(_vm.carriers[i][j]),callback:function ($$v) {_vm.$set(_vm.carriers[i], j, $$v)},expression:"carriers[i][j]"}})}),_c('b-form-select',{staticClass:"carrier__input",attrs:{"options":_vm.connection},on:{"change":function($event){return _vm.sendCarrier()}},model:{value:(_vm.carriers[i].connection),callback:function ($$v) {_vm.$set(_vm.carriers[i], "connection", $$v)},expression:"carriers[i].connection"}})],2),_c('div',{staticClass:"carrier__container-checkbox"},_vm._l((carrierObject),function(value,j){return _c('b-form-checkbox',{directives:[{name:"show",rawName:"v-show",value:(
            j === 'documents_required_for_dispatch' ||
            j === 'automatic_dispatch' ||
            j === 'notify_glt_ops'
          ),expression:"\n            j === 'documents_required_for_dispatch' ||\n            j === 'automatic_dispatch' ||\n            j === 'notify_glt_ops'\n          "}],key:j,staticClass:"carrier__checkbox",on:{"change":function($event){return _vm.sendCarrier()}},model:{value:(_vm.carriers[i][j]),callback:function ($$v) {_vm.$set(_vm.carriers[i], j, $$v)},expression:"carriers[i][j]"}},[_vm._v(" "+_vm._s(j.split("_").join(" "))+" ")])}),1),_c('hr')])})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }