import api from "../clients/axios";

const resource = `/api/v1/companies`;

export default {
  async createHaul(params) {
    let response = null;
    if (params.params.trigger && params.params.save_history) {
      response = await api.post(`${resource}/${params.params.company_id}/departments/${params.params.department_id}/profiles/${params.params.profile_id}/rules/laboratory/checker?trigger=${params.params.trigger}&save_history=${params.params.save_history}`, params.body);
    } else if (params.params.trigger) {
      response = await api.post(`${resource}/${params.params.company_id}/departments/${params.params.department_id}/profiles/${params.params.profile_id}/rules/laboratory/checker?trigger=${params.params.trigger}`, params.body);
    } else if (params.params.save_history) {
      response = await api.post(`${resource}/${params.params.company_id}/departments/${params.params.department_id}/profiles/${params.params.profile_id}/rules/laboratory/checker?save_history=${params.params.save_history}`, params.body);
    } else {
      response = await api.post(`${resource}/${params.params.company_id}/departments/${params.params.department_id}/profiles/${params.params.profile_id}/rules/laboratory/checker`, params.body);
    }
    return response;
  },
  async getAllRules(params) {
    const response = await api.get(`${resource}/${params.company_id}/departments/${params.department_id}/profiles/${params.profile_id}/rules`, { params });
    return response.data;
  },
  async createRule(payload) {
    const response = await api.post(`${resource}/${payload.params.company_id}/departments/${payload.params.department_id}/profiles/${payload.params.profile_id}/rules`, payload.payload);
    return response;
  },
  async getRuleById(params) {
    const response = await api.get(`${resource}/${params.company_id}/departments/${params.department_id}/profiles/${params.profile_id}/rules/${params.rule_id}`);
    return response;
  },
  async deleteRule(params) {
    const payload = {};
    payload.confirmation_message = "I'm sure I want to delete this";
    const response = await api.delete(`${resource}/${params.company_id}/departments/${params.department_id}/profiles/${params.profile_id}/rules/${params.rule_id}`, { params: payload });
    return response;
  },
  async updateRule(payload) {
    const response = await api.patch(`${resource}/${payload.params.company_id}/departments/${payload.params.department_id}/profiles/${payload.params.profile_id}/rules/${payload.params.rule_id}`, payload.payload);
    return response;
  },
  checkFormula(payload) {
    const formula = encodeURIComponent(payload);
    return api.post(`api/v1/rules-engine/formula/checker?formula=${formula}`);
  },
  exportRulesData(params) {
    return api.get(`api/v1/rules-engine/export-rules`, { params });
  },
  async createAccessorialsRule(payload) {
    const response = await api.patch(`${resource}/${payload.params.company_id}/departments/${payload.params.department_id}/profiles/${payload.params.profile_id}/accessorials`, payload.payload);
    return response;
  },
};
