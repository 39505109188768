import Accessorials from "../../views/Accessorials/Index.vue";

const AccessorialsRoute = [
  {
    path: "/accessorials",
    name: "Accessorials",
    component: Accessorials,
  },
];

export default AccessorialsRoute;
