<template>
  <div class="select-container">
    <label :for="inputType.name">{{ inputType[0].category }} - {{ inputType[0].label }}</label>
    <Dropdown
      class="select-container__input"
      :class="stateValidation.optionSelected"
      :name="inputType.name"
      v-model="optionSelected"
      :options="options"
      optionLabel="name"
      optionValue="name"
      placeholder="Operator"
      @change="conditionConstructor"
    >
      <template #option="slotProps">
        <div class="p-dropdown-car-option"
        v-tooltip="{ value: slotProps.option.description, disabled: false }">
          <span>{{slotProps.option.name}}</span>
        </div>
      </template>
    </Dropdown>
    <div v-if="inputType[0].field_type !== 'boolean'">
      <select class="select-container__input" :name="inputType.name"
        :class="stateValidation.conditionValue"
        v-if="inputType[0].options.length > 0"
        v-model="conditionValue"
        @change="conditionConstructor">
        <option v-for="(option, index) in inputType.options" :key="index">
          {{ option }}
        </option>
      </select>
      <b-form-tags v-else
        :class="stateValidation.conditionValue"
        v-model="conditionValue"
        :placeholder="inputType[0].label"
        separator=","
        :limit="1"
        @input="conditionConstructor()"
      ></b-form-tags>
      <p class="select-container__help-message">
        *This operator requires only 1 parameter
      </p>
      <!--<Chips v-else
        v-model="conditionValue"
        separator=","
        :max="1"
        @add="conditionConstructor"
        @remove="conditionConstructor"
      />-->
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  props: {
    inputType: {
      type: Array,
      default: null,
    },
    conditionEditData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      optionSelected: null,
      options: [],
      typeInput: null,
      conditionValue: "",
      stateValidation: {},
    };
  },
  computed: {
    ...mapGetters("rules", ["showRuleCompleteObject"]),
  },
  created() {
    this.labelConstructor();
    this.conditionEditConstructor();
    this.statesValidation();
    if (!this.conditionEditData?.value) {
      this.conditionConstructor();
    }
  },
  methods: {
    conditionEditConstructor() {
      this.conditionValue = this.conditionEditData?.value
        ? this.conditionEditData?.value : this.inputType[0].options[0];
      this.optionSelected = this.conditionEditData?.operator
        ? this.conditionEditData?.operator : this.options[0].name;
    },
    conditionConstructor() {
      this.statesValidation();
      const valueBool = this.optionSelected === "is_true" ? [true] : [false];
      const infoCondition = {};
      infoCondition.name = this.inputType[0].name;
      infoCondition.operator = this.optionSelected;
      infoCondition.value = this.inputType[0].field_type !== 'boolean' ? this.conditionValue : valueBool;
      this.$emit("conditionObject", infoCondition);
    },
    labelConstructor() {
      this.typeInput = this.inputType[0].field_type;
      const ruleData = this.showRuleCompleteObject;
      const variableTypeOperators = ruleData?.variable_type_operators[this.typeInput];
      for (let i = 0; i < variableTypeOperators.length; i += 1) {
        this.options.push({
          name: variableTypeOperators[i].name,
          description: variableTypeOperators[i].description,
        });
      }
    },
    statesValidation() {
      this.stateValidation.optionSelected = this.changeFieldColors(this.optionSelected);
      this.stateValidation.conditionValue = this.changeFieldColors(this.conditionValue);
    },
    changeFieldColors(field) {
      if (field) {
        return "select-container__input--input-valid";
      }
      return "p-invalid";
    },
  },
};
</script>
<style scoped lang="scss">
.select-container {
  align-items: center;
  @include rules-input;
  &__help-message {
    color: $color-input-text;
    font-size: 12px;
    padding-left: 0.5rem;
    margin: auto;
  }
  &__input {
    &--input-valid {
      border: 1px solid #00b900;
    }
  }
}

select:focus-visible {
  outline: none !important;
}
:deep( .p-chips-multiple-container ) {
  height: 28px;
}
:deep( .p-chips-token ) {
  height: 20px;
  font-size: 15px;
}
:deep( .p-chips-token-icon ) {
  font-size: 13px;
}
:deep(.p-chips-multiple-container.p-focus) {
  box-shadow: none !important;
}
:deep(.p-dropdown-label) {
  padding-top: 0 !important;
}
:deep(.p-dropdown-trigger-icon) {
  font-size: 11px;
  font-weight: bold;
}
:deep(.p-dropdown-trigger) {
  width: 1.2rem;
}
</style>
